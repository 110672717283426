import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill } from "react-icons/pi";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import Alerts from "../../components/Alerts.jsx";
import * as mutations from "../../graphql/mutations";
import { API } from "aws-amplify";

export default function EFromUploadS1({ user, signOut }) {
  const [alert, setAlert] = useState("");
  const [wait, setWait] = useState(true);
  const [masterJson, setmasterJson] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateDisplayName, setTemplateDisplayName] = useState("");
  const [tempCompanyNames, setTempCompanyNames] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [templateId, setTemplateId] = useState("");

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handleTemplateTypeChange = (event) => {
    setTemplateType(event.target.value);
  };
  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);

    if(masterJson.length) {
      masterJson.forEach(item => {
        if(item.template_id === event.target.value) {
          setTemplateDisplayName(item.template_display_name);
          setCompanyId(item.company_id);
          setTemplateId(item.template_id);
        }
      });
    }
  };

  let numberStylesOn = { padding: "2px", color: "#0000A3", fontSize: "30px" };
  let numberStylesOut = { padding: "2px", color: "#AEB3B7", fontSize: "30px" };

  useEffect(() => {
    async function getDropDownData() {
      const response = await API.graphql({
        query: mutations.integrateTemplateEformFilter,
        variables: {
          userid: user,
        },
      });
      setmasterJson(response.data.IntegrateTemplateEformFilter);
      setWait(false);
    }

    async function getCreateTemplateCompanyNames() {
      const response = await API.graphql({
        query: mutations.CompanyListUseridFilter,
        variables: {
          userid: user,
        },
      });
      setTempCompanyNames(
        response.data.CompanyListUseridFilter,
      );
      setWait(false);
    }

    getDropDownData().catch(error => {
      setWait(false);
      console.error(error);
    });

    getCreateTemplateCompanyNames().catch(error => {
      setWait(false);
      console.error(error);
    });

  }, []);

  useEffect(() => {
    localStorage.setItem("eformTemplateName", templateName);
    localStorage.setItem("eformTemplateType", templateType);
    localStorage.setItem("eformCompanyName", companyName);
    localStorage.setItem("eformTemplateDisplayName", templateDisplayName);
    localStorage.setItem("eformTemplateId", templateId);
    localStorage.setItem("eformCompanyId", companyId);
  });

  const companyNameList = tempCompanyNames.reduce((companyNames, companyItem) => {

    const { company_id, company_name } = companyItem;

    if(company_id && company_name) {
      companyNames.push({
        template_id: company_id,
        template_display_name: company_name,
      });
    }

    return companyNames;

  }, []);

  const templateTypeList = [];
  const templateNameList = [];

  masterJson.forEach(templateItem => {

    const { template_id, template_type_id, template_display_name, template_type_display_name, company_id } = templateItem;

    if(company_id === companyName) {
      if(template_type_id && template_type_display_name) {
        templateTypeList.push({
          template_id: template_type_id,
          template_display_name: template_type_display_name
        });
      }

      if(template_id && template_display_name) {
        templateNameList.push({
          template_id,
          template_display_name
        });
      }
    }
  });

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "40px" };

  let navigate = useNavigate();

  const handleSave = async (e) => {
    if (companyName < 1 || templateName < 1 || templateType < 1) {
      e.preventDefault();
      setAlert("error");
    } else {
      e.preventDefault();
      setAlert("success");
      navigate("/integrate/eformuploads2");
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
            input[type="checkbox"]{accent-color:#0000A3;}
          `}</style>
      {/* <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar /> */}
        <div className="2xl:ml-[15rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/upload-select">
              Select Upload Type
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/uploadtemplateS2">
              E-Forms
            </Link>
          </div>
          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <MdOutlineAutoFixHigh style={iconStyles} />
              <p className="text-3xl mx-1">E-Form</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Successfully, Proceed to upload the file in next page"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Please fill all the fields" />
            ) : (
              ""
            )}
          </div>
          {/* form*/}
          <div className="flex mt-5 ">
            <div className="ml-3">
              <div className="flex items-center space-x-2">
                <PiNumberCircleOneFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 1</p>
                  <p className="font-bold text-md pb-1 ">
                    Select Template Type
                  </p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiNumberCircleTwoFill style={numberStylesOut} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 2</p>
                  <p className=" text-md pb-1 ">Submit E-Form</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>{" "}
              </div>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={wait}
            >
              <CircularProgress sx={{ color: "#DB2748" }} />{" "}
            </Backdrop>

            <div className="w-[615px] ml-16 mt-4">
              <Paper elevation={2}>
                <div className="px-10 pt-10">
                  <div className="flex justify-center">
                    <div className="font-medium text-2xl">Template Details</div>
                  </div>
                  <form className="ml-5 grid grid-cols-2 gap-8 mt-16">
                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS22">
                        Company Name
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS23"
                        id="UploadTemplateS24"
                        value={companyName}
                        label="Company Name"
                        onChange={handleCompanyNameChange}
                      >
                        {companyNameList.map((option, key) => {
                          return <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        })}
                      </Select>
                    </FormControl>

                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS26">
                        Template Type
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS27"
                        id="UploadTemplateS28"
                        value={templateType}
                        label="Template Type"
                        onChange={handleTemplateTypeChange}
                      >
                        {templateTypeList.map((option, key) => {
                          return <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        })}
                      </Select>
                    </FormControl>

                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS29">
                        Template Name
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS30"
                        id="UploadTemplateS31"
                        value={templateName}
                        label="Template Name"
                        onChange={handleTemplateNameChange}
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: 250 } },
                        }}
                      >
                        {templateNameList.map((option, key) => {
                          return <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                         })}
                      </Select>
                    </FormControl>

                    <div className="pt-16 pb-12 col-start-1 col-end-3 w-full flex justify-end items-center">
                      <button
                        type="submit"
                        className="ml-5 bg-[#DB2748] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-7  text-white"
                        onClick={(e) => {
                          handleSave(e);
                        }}
                      >
                        {" "}
                        Next{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}