import { React, useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import Button from "@mui/material/Button";
import { BiSolidUserCircle } from "react-icons/bi";
import { ButtonBase, Typography } from "@mui/material";
import imgAC from "../assets/imgAC.png";

export default function Navbar({ signOut }) {
  let user = localStorage.getItem("loggedInUser");

  let listStyles = {
    padding: "2px",
    color: "#ffffff",
    fontSize: "40px",
  };

  const [action, setAction] = useState(false);

  let actionRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!actionRef.current.contains(e.target)) {
        setAction(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // MuiTouchRipple-root
  return (
    <>
      <style>{`.css-8je8zh-MuiTouchRipple-root{border-radius: 5px;}`}</style>
      <div className="2xl:px-[4rem] flex justify-between w-full py-3 bg-[#0000A3] items-center shadow-black shadow-sm" style={{ height: "5%" }}>
        <div className="flex items-center">
          <img src={imgAC} alt="AA" className="h-[40px] mx-4" />
          <nav className="flex justify-center items-center space-x-3 ml-16">
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-md text-white uppercase  "
                  : // <Button className="text-white">Company</Button>  border border-white rounded-full bg-[#4f4fc4] active:border-gray-700 active:shadow-none shadow-lg bg-gradient-to-tr from-gray-200 to-gray-400

                    "text-md text-white uppercase"
              }
              exact="true"
              to="/company"
            >
              <ButtonBase component="div" className="rounded-full">
                <Typography className="px-3 py-2 rounded-full">
                  Company
                </Typography>
              </ButtonBase>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-md text-white uppercase  "
                  : "text-md text-white uppercase"
              }
              to="/design"
            >
              <ButtonBase component="div" className="rounded-full">
                <Typography className="px-3 py-2 rounded-full">
                  Design
                </Typography>
              </ButtonBase>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-md  text-white  uppercase  "
                  : "text-md text-white uppercase"
              }
              to="/integrate"
            >
              <ButtonBase component="div" className="rounded-full">
                <Typography className="px-3 py-2 rounded-full">
                  Integrate
                </Typography>
              </ButtonBase>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-md text-white uppercase  "
                  : "text-md text-white uppercase"
              }
              to="/report"
            >
              <ButtonBase component="div" className="rounded-full">
                <Typography className="px-3 py-2 rounded-full">
                  Report
                </Typography>
              </ButtonBase>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-md  text-white  uppercase  "
                  : "text-md text-white uppercase"
              }
              to="/dashboard"
            >
              <ButtonBase component="div" className="rounded-full">
                <Typography className="px-3 py-2 rounded-full">
                  Dashboard
                </Typography>
              </ButtonBase>
            </NavLink>
          </nav>
        </div>
        <div className="w-full flex justify-end mr-2" ref={actionRef}>
          <BiSolidUserCircle
            onClick={() => setAction((prev) => !prev)}
            style={listStyles}
          />
          {action && (
            <div className="hover:cursor-pointer mt-10 absolute space-y-1 flex z-[1] flex-col justify-center w-[98.4667px] h-fit bg-gray-100 shadow-lg  ">
              <p className="h-full hover:bg-gray-200 capitalize text-md font-medium text-center py-4">
                {user}
              </p>
              <p
                onClick={signOut}
                className="h-full hover:bg-gray-200 capitalize text-md font-medium text-center py-4"
              >
                Sign Out
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

// <hr className="mx-4 mt-1 h-[2px] bg-[#767474]" />
// className="text-md px-4 active: text-white  py-2 text-white"
// <style>
// {`
//     .activeRoute{
//         color: black;
//         font-weight: bold;
//         border-bottom:2px solid #DB2748;
//     }
//     `}
// </style>
//
// <div className="flex justify-end mx-4 my-4 items-center">
//   <AiOutlineUser className="mx-2" style={{ color: "#6b7280" }} />
//   <p className="text-sm text-gray-500 capitalize mr-1">
//     {user} | company name
//   </p>{" "}
//   <p className="text-sm text-gray-500">|</p>
//   <button className="mx-1 text-sm text-gray-500" onClick={signOut}>
//     Sign out
//   </button>
// </div>
//
// <NavLink
//   className={({ isActive }) =>
//     isActive
//       ? "text-md px-4  text-white  py-2"
//       : "text-md px-4 text-white py-2"
//   }
//   to="/in-development"
// >
//   Communicate
// </NavLink>
// //border-b-2border-[#DB2748]
//   border-b-2border-[#DB2748]
//   border-b-2border-[#DB2748]
//   border-b-2border-[#DB2748]
//   border-b-2border-[#DB2748]
//   border-b-2border-[#DB2748]
//   border-b-2border-[#DB2748]
