import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { PiPlus } from "react-icons/pi";
import * as mutations from "../../src/graphql/mutations.js";
import { API } from "aws-amplify";
import Alert from "@mui/material/Alert";



export default function ChildDependentEligibility({
  filteredList,
  getChildDependentEligibilityMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };
  const [masterJson, setMasterJson] = useState([]);
  const [wait, setWait] = useState(true);
  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);

  const getBenefitPlansAndPrograms = async (benefitProgram, benefitCounty) => {
    const filteredValues = await API.graphql({
      query: mutations.ChildDependentEligibilityfilterBenefitPlanAndCountry,
      variables: {
        benefit_id: benefitProgram,
        country: benefitCounty,
      },
    });
    setMasterJson(
      filteredValues.data.ChildDependentEligibilityfilterBenefitPlanAndCountry,
    );
    setWait(false);
  };

  useEffect(() => {
    const benefitProgram = JSON.parse(
      localStorage.getItem("benefitProgramFromBenefitPage1"),
    );
    const benefitCounty = JSON.parse(
      localStorage.getItem("contryFromBenefitPage1"),
    );

    getBenefitPlansAndPrograms(benefitProgram, benefitCounty);
  }, []);

  let test = [];
  for (let r of masterJson) {
    test.push(r.attribute_key_name_parent);
  }

  let uniqueArray = [];
  const test1 = new Map();
  for (const obj of test) {
    const objString = JSON.stringify(obj);
    if (!test1.has(objString)) {
      test1.set(objString, true);
      uniqueArray.push(obj);
    }
  }

    // console.log(masterJson)

  const [date, setDate] = useState("");
  const [operator0, setOperator0] = useState("");
  const [operator1, setOperator1] = useState("");
  const [operator2, setOperator2] = useState("");
  const [operator3, setOperator3] = useState("");
  const [value0, setValue0] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState([]);

  const handleOperator0Change = (event) => {
    setOperator0(event.target.value);
  };
  const handleOperator1Change = (event) => {
    setOperator1(event.target.value);
  };
  const handleOperator2Change = (event) => {
    setOperator2(event.target.value);
  };
  const handleOperator3Change = (event) => {
    setOperator3(event.target.value);
  };
  const handleValue0Change = (event) => {
    setValue0(event.target.value);
  };
  const handleValue1Change = (event) => {
    setValue1(event.target.value);
  };
  const handleValue2Change = (event) => {
    setValue2(event.target.value);
  };
  const handleValue3Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue3(typeof value === "string" ? value.split(",") : value);
  };

  let eligibilityRules = [];
  let integerList = [];
  let varcharList = [];
  let genderList = [];

  // for (let rules of filteredList) {
  //   if (rules.attribute_key_name_parent === "child_dependent_eligibility") {
  //     let dict1 = {
  //       listValueKey: "",
  //       listValueDisplayName: "",
  //       listValueSortOrder: "",
  //     };
  //
  //     dict1.listValueKey = rules.attribute_list_valid_value_parent;
  //     dict1.listValueDisplayName =
  //       rules.attribute_list_value_display_name_parent;
  //     dict1.listValueSortOrder = rules.attribute_list_value_sort_order_parent;
  //     eligibilityRules.push(dict1);
  //   }
  // }

  for (let integer of filteredList) {
    if (integer.attribute_key_name_parent === "interger_eligibility_operator") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = integer.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        integer.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = integer.attribute_list_value_sort_order_child;
      integerList.push(dict1);
    }
  }

  for (let varchar of filteredList) {
    if (varchar.attribute_key_name_parent === "varchar_eligibility_operator") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = varchar.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        varchar.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = varchar.attribute_list_value_sort_order_child;
      varcharList.push(dict1);
    }
  }
  //
  // for (let dualCover of filteredList) {
  //   if (dualCover.attribute_key_name_parent === "dual_cover") {
  //     let dict1 = {
  //       listValueKey: "",
  //       listValueDisplayName: "",
  //       listValueSortOrder: "",
  //     };
  //     dict1.listValueKey = dualCover.attribute_list_valid_value_child;
  //     dict1.listValueDisplayName =
  //       dualCover.attribute_list_value_display_name_child;
  //     dict1.listValueSortOrder =
  //       dualCover.attribute_list_value_sort_order_child;
  //     dualCoverList.push(dict1);
  //   }
  // }

  if (uniqueArray.includes("target_gender")) {
  for (let gender of masterJson) {
    if (gender.attribute_key_name_parent === "target_gender") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = gender.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        gender.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = gender.attribute_list_value_sort_order_child;
      genderList.push(dict1);
    }
    }
    }
  // console.log(dualCoverList)

  // eligibilityRules.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  integerList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  varcharList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  // dualCoverList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  genderList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  // console.log(filteredList);
  // console.log("ade", eligibilityRules);
  // console.log("iade", integerList);
  // console.log("oade", varcharList);
  // console.log("oade", dualCoverList);
  // console.log("oade", genderList);

  const finalList = [
    ["maximum_age", operator0, value0],
    ["maximum_age_student", operator1, value1],
    ["minimum_age", operator2, value2],
    ["target_gender", operator3, value3.join("+")],
  ];

  let finalDict = {"date": `${date.$y}-${(date.$M + 1) > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${(date.$D) > 9 ? date.$D : `0${date.$D}`}`,};

  for (var er of finalList) {
    finalDict[er[0]] = er[1] + er[2];
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getChildDependentEligibilityMappedValues(finalDict, `${date.$y}-${(date.$M + 1) > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${(date.$D) > 9 ? date.$D : `0${date.$D}`}`);
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Child Dependent Eligibility</Typography>
      </AccordionSummary>

      <AccordionDetails className="mx-5 mb-3 flex justify-between">
          {alert === "success" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="success">
              Success! The details provided have been stored
            </Alert>
          ) : alert === "error" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="error">
              Please, Fill all the fields
            </Alert>
          ) : (
            ""
          )}
      </AccordionDetails>

      <AccordionDetails className="grid grid-cols-3 gap-8 mx-5 mb-4">
        <FormControl fullWidth>
          <DatePicker
            label="As Of Date"
            name="date"
            value={date}
            onChange={(date) => setDate(date)}
            format="YYYY-DD-MM"
          />
        </FormControl>

        <FormControl fullWidth></FormControl>
        <FormControl fullWidth></FormControl>

        {uniqueArray.includes("maximum_age") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Max Age"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Max Age"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator0}
                label="Operator"
                onChange={handleOperator0Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value0}
                label="Value"
                onChange={handleValue0Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("maximum_age_student") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Max Age (Student)"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Max Age (Student)"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator1}
                label="Operator"
                onChange={handleOperator1Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value1}
                label="Value"
                onChange={handleValue1Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("minimum_age") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Min Age"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Min Age"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator2}
                label="Operator"
                onChange={handleOperator2Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value2}
                label="Value"
                onChange={handleValue2Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("target_gender") && (
          <>
            {" "}
            <FormControl name="fullWidth14" fullWidth>
              <Tooltip title={"Gender"}>
                <TextField
                  label="Eligibility Rule"
                  id="E5"
                  value={"Gender"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth15" fullWidth>
              <InputLabel id="Eligibility12">Operator</InputLabel>
              <Select
                labelId="Eligibility13"
                id="Eligibility14"
                value={operator3}
                label="Operator"
                onChange={handleOperator3Change}
              >
                {varcharList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth16" fullWidth>
              <InputLabel id="Eligibility15">Value</InputLabel>
              <Select
                labelId="Eligibility16"
                id="Eligibility17"
                value={value3}
                label="Value"
                multiple
                onChange={handleValue3Change}
              >
                {genderList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        <FormControl fullWidth></FormControl>
        <FormControl fullWidth></FormControl>
        <div className="mt-5 w-full flex justify-end items-center">
          <button
            type="submit"
            className="ml-5 bg-[#0000A3] py-[6px]   shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg   px-6  text-white"
            onClick={(e) => {
              handleSave(e);
            }}
          >
            Save
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
        // <div className="pt-5">
        //   {" "}
        //   {finalList.map((item, key) => (
        //     <div key={key}>
        //       {item[0]} : {item[1]}, {item[2]}
        //     </div>
        //   ))}
        // </div>
