import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { PiPlus } from "react-icons/pi";
import * as mutations from "../../src/graphql/mutations.js";
import { API } from "aws-amplify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

export default function BenefitPlanCoverageDetails({
  filteredList,
  getBenefitPlanCoverageDetailsMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };

  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);
  const [masterJson, setMasterJson] = useState([]);
  const [wait, setWait] = useState(true);
  const [date, setDate] = useState("");
  const [value0, setValue0] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState([]);
  const [value6, setValue6] = useState("");
  const [value7, setValue7] = useState("");
  const [value8, setValue8] = useState("");
  const [value9, setValue9] = useState("");
  const [value10, setValue10] = useState("");
  const [value11, setValue11] = useState("");
  const [value12, setValue12] = useState("");
  const [value13, setValue13] = useState("");
  const [value14, setValue14] = useState("");
  const [value15, setValue15] = useState([]);
  const [value16, setValue16] = useState("");
  const [value17, setValue17] = useState("");
  const [value18, setValue18] = useState("");
  const [value19, setValue19] = useState("");
  const [value20, setValue20] = useState("");
  const [value21, setValue21] = useState("");
  const [value22, setValue22] = useState("");
  const [value23, setValue23] = useState("");
  const [value24, setValue24] = useState("");
  const [value25, setValue25] = useState("");
  const [value26, setValue26] = useState("");
  const [value27, setValue27] = useState("");
  const [value28, setValue28] = useState("");
  const [value29, setValue29] = useState("");
  const [value30, setValue30] = useState("");
  const [value31, setValue31] = useState("");
  const [value32, setValue32] = useState("");
  const [value33, setValue33] = useState("");
  const [value34, setValue34] = useState("");
  const [value35, setValue35] = useState([]);
  const [value36, setValue36] = useState("");
  const [value37, setValue37] = useState("");
  const [value38, setValue38] = useState("");
  const [value39, setValue39] = useState("");
  const [value40, setValue40] = useState("");
  const [value41, setValue41] = useState("");
  const [value42, setValue42] = useState("");
  const [value43, setValue43] = useState("");
  const [value44, setValue44] = useState("");
  const [value45, setValue45] = useState("");
  const [value46, setValue46] = useState("");
  const [value47, setValue47] = useState("");
  const [value48, setValue48] = useState("");
  const [value49, setValue49] = useState("");
  const [value50, setValue50] = useState("");
  const [value51, setValue51] = useState("");
  const [value52, setValue52] = useState("");
  const [value53, setValue53] = useState("");
  const [value54, setValue54] = useState("");
  const [value55, setValue55] = useState("");
  const [value56, setValue56] = useState("");
  const [value57, setValue57] = useState("");
  const [value58, setValue58] = useState("");
  const [value59, setValue59] = useState("");
  const [value60, setValue60] = useState("");
  const [value61, setValue61] = useState("");
  const [value62, setValue62] = useState("");
  const [value63, setValue63] = useState("");
  const [value64, setValue64] = useState("");
  const [value65, setValue65] = useState("");
  const [value66, setValue66] = useState("");
  const [value67, setValue67] = useState([]);
  const [value68, setValue68] = useState("");
  const [value69, setValue69] = useState("");
  const [value70, setValue70] = useState("");
  const [value71, setValue71] = useState("");
  const [value72, setValue72] = useState("");
  const [value73, setValue73] = useState("");
  const [value74, setValue74] = useState("");
  const [value75, setValue75] = useState("");
  const [value76, setValue76] = useState("");
  const [value77, setValue77] = useState([]);
  const [value78, setValue78] = useState("");
  const [value79, setValue79] = useState("");
  const [value80, setValue80] = useState("");
  const [value81, setValue81] = useState([]);
  const [value82, setValue82] = useState("");
  const [value83, setValue83] = useState("");
  const [value84, setValue84] = useState("");
  const [value85, setValue85] = useState("");
  const [value86, setValue86] = useState("");
  const [value87, setValue87] = useState("");
  const [value88, setValue88] = useState([]);
  const [value89, setValue89] = useState("");
  const [value90, setValue90] = useState("");
  const [value91, setValue91] = useState("");
  const [value92, setValue92] = useState("");
  const [value93, setValue93] = useState("");
  const [value94, setValue94] = useState("");
  const [value95, setValue95] = useState("");
  const [value96, setValue96] = useState("");
  const [value97, setValue97] = useState("");
  const [value98, setValue98] = useState("");
  const [value99, setValue99] = useState("");
  const [value100, setValue100] = useState("");
  const [value101, setValue101] = useState([]);
  const [value102, setValue102] = useState("");
  const [value103, setValue103] = useState("");
  const [value104, setValue104] = useState("");
  const [value105, setValue105] = useState("");
  const [value106, setValue106] = useState("");
  const [value107, setValue107] = useState("");
  const [value108, setValue108] = useState("");
  const [value109, setValue109] = useState("");
  const [value110, setValue110] = useState("");
  const [value111, setValue111] = useState("");
  const [value112, setValue112] = useState("");
  const [value113, setValue113] = useState("");
  const [value114, setValue114] = useState("");
  const [value115, setValue115] = useState("");
  const [value116, setValue116] = useState("");
  const [value117, setValue117] = useState("");
  const [value118, setValue118] = useState("");
  const [value119, setValue119] = useState("");
  const [value120, setValue120] = useState("");
  const [value121, setValue121] = useState("");
  const [value122, setValue122] = useState("");
  const [value123, setValue123] = useState("");
  const [value124, setValue124] = useState("");

  const getBenefitPlansAndPrograms = async (benefitProgram, benefitCounty) => {
    const filteredValues = await API.graphql({
      query: mutations.BenefitPlanCoverageAmountfilterBenefitPlanAndCountry,
      variables: {
        benefit_id: benefitProgram,
        country: benefitCounty,
      },
    });
    setMasterJson(
      filteredValues.data.BenefitPlanCoverageAmountfilterBenefitPlanAndCountry,
    );
    setWait(false);
  };

  useEffect(() => {
    const benefitProgram = JSON.parse(
      localStorage.getItem("benefitProgramFromBenefitPage1"),
    );
    const benefitCounty = JSON.parse(
      localStorage.getItem("contryFromBenefitPage1"),
    );

    getBenefitPlansAndPrograms(benefitProgram, benefitCounty);
  }, []);

  const handleValue0Change = (event) => {
    setValue0(event.target.value);
  };
  const handleValue1Change = (event) => {
    setValue1(event.target.value);
  };
  const handleValue2Change = (event) => {
    setValue2(event.target.value);
  };
  const handleValue3Change = (event) => {
    setValue3(event.target.value);
  };
  const handleValue4Change = (event) => {
    setValue4(event.target.value);
  };
  const handleValue5Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue5(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue6Change = (event) => {
    setValue6(event.target.value);
  };
  const handleValue7Change = (event) => {
    setValue7(event.target.value);
  };
  const handleValue8Change = (event) => {
    setValue8(event.target.value);
  };
  const handleValue9Change = (event) => {
    setValue9(event.target.value);
  };
  const handleValue10Change = (event) => {
    setValue10(event.target.value);
  };
  const handleValue11Change = (event) => {
    setValue11(event.target.value);
  };
  const handleValue12Change = (event) => {
    setValue12(event.target.value);
  };
  const handleValue13Change = (event) => {
    setValue13(event.target.value);
  };
  const handleValue14Change = (event) => {
    setValue14(event.target.value);
  };
  const handleValue15Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue15(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue16Change = (event) => {
    setValue16(event.target.value);
  };
  const handleValue17Change = (event) => {
    setValue17(event.target.value);
  };
  const handleValue18Change = (event) => {
    setValue18(event.target.value);
  };
  const handleValue19Change = (event) => {
    setValue19(event.target.value);
  };
  const handleValue20Change = (event) => {
    setValue20(event.target.value);
  };
  const handleValue21Change = (event) => {
    setValue21(event.target.value);
  };
  const handleValue22Change = (event) => {
    setValue22(event.target.value);
  };
  const handleValue23Change = (event) => {
    setValue23(event.target.value);
  };
  const handleValue24Change = (event) => {
    setValue24(event.target.value);
  };
  const handleValue25Change = (event) => {
    setValue25(event.target.value);
  };
  const handleValue26Change = (event) => {
    setValue26(event.target.value);
  };
  const handleValue27Change = (event) => {
    setValue27(event.target.value);
  };
  const handleValue28Change = (event) => {
    setValue28(event.target.value);
  };
  const handleValue29Change = (event) => {
    setValue29(event.target.value);
  };
  const handleValue30Change = (event) => {
    setValue30(event.target.value);
  };
  const handleValue31Change = (event) => {
    setValue31(event.target.value);
  };
  const handleValue32Change = (event) => {
    setValue32(event.target.value);
  };
  const handleValue33Change = (event) => {
    setValue33(event.target.value);
  };
  const handleValue34Change = (event) => {
    setValue34(event.target.value);
  };
  const handleValue35Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue35(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue36Change = (event) => {
    setValue36(event.target.value);
  };
  const handleValue37Change = (event) => {
    setValue37(event.target.value);
  };
  const handleValue38Change = (event) => {
    setValue38(event.target.value);
  };
  const handleValue39Change = (event) => {
    setValue39(event.target.value);
  };
  const handleValue40Change = (event) => {
    setValue40(event.target.value);
  };
  const handleValue41Change = (event) => {
    setValue41(event.target.value);
  };
  const handleValue42Change = (event) => {
    setValue42(event.target.value);
  };
  const handleValue43Change = (event) => {
    setValue43(event.target.value);
  };
  const handleValue44Change = (event) => {
    setValue44(event.target.value);
  };
  const handleValue45Change = (event) => {
    setValue45(event.target.value);
  };
  const handleValue46Change = (event) => {
    setValue46(event.target.value);
  };
  const handleValue47Change = (event) => {
    setValue47(event.target.value);
  };
  const handleValue48Change = (event) => {
    setValue48(event.target.value);
  };
  const handleValue49Change = (event) => {
    setValue49(event.target.value);
  };
  const handleValue50Change = (event) => {
    setValue50(event.target.value);
  };
  const handleValue51Change = (event) => {
    setValue51(event.target.value);
  };
  const handleValue52Change = (event) => {
    setValue52(event.target.value);
  };
  const handleValue53Change = (event) => {
    setValue53(event.target.value);
  };
  const handleValue54Change = (event) => {
    setValue54(event.target.value);
  };
  const handleValue55Change = (event) => {
    setValue55(event.target.value);
  };
  const handleValue56Change = (event) => {
    setValue56(event.target.value);
  };
  const handleValue57Change = (event) => {
    setValue57(event.target.value);
  };
  const handleValue58Change = (event) => {
    setValue58(event.target.value);
  };
  const handleValue59Change = (event) => {
    setValue59(event.target.value);
  };
  const handleValue60Change = (event) => {
    setValue60(event.target.value);
  };
  const handleValue61Change = (event) => {
    setValue61(event.target.value);
  };
  const handleValue62Change = (event) => {
    setValue62(event.target.value);
  };
  const handleValue63Change = (event) => {
    setValue63(event.target.value);
  };
  const handleValue64Change = (event) => {
    setValue64(event.target.value);
  };
  const handleValue65Change = (event) => {
    setValue65(event.target.value);
  };
  const handleValue66Change = (event) => {
    setValue66(event.target.value);
  };
  const handleValue67Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue67(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue68Change = (event) => {
    setValue68(event.target.value);
  };
  const handleValue69Change = (event) => {
    setValue69(event.target.value);
  };
  const handleValue70Change = (event) => {
    setValue70(event.target.value);
  };
  const handleValue71Change = (event) => {
    setValue71(event.target.value);
  };
  const handleValue72Change = (event) => {
    setValue72(event.target.value);
  };
  const handleValue73Change = (event) => {
    setValue73(event.target.value);
  };
  const handleValue74Change = (event) => {
    setValue74(event.target.value);
  };
  const handleValue75Change = (event) => {
    setValue75(event.target.value);
  };
  const handleValue76Change = (event) => {
    setValue76(event.target.value);
  };
  const handleValue77Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue77(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue78Change = (event) => {
    setValue78(event.target.value);
  };
  const handleValue79Change = (event) => {
    setValue79(event.target.value);
  };
  const handleValue80Change = (event) => {
    setValue80(event.target.value);
  };
  const handleValue81Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue81(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue82Change = (event) => {
    setValue82(event.target.value);
  };
  const handleValue83Change = (event) => {
    setValue83(event.target.value);
  };
  const handleValue84Change = (event) => {
    setValue84(event.target.value);
  };
  const handleValue85Change = (event) => {
    setValue85(event.target.value);
  };
  const handleValue86Change = (event) => {
    setValue86(event.target.value);
  };
  const handleValue87Change = (event) => {
    setValue87(event.target.value);
  };
  const handleValue88Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue88(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue89Change = (event) => {
    setValue89(event.target.value);
  };
  const handleValue90Change = (event) => {
    setValue90(event.target.value);
  };
  const handleValue91Change = (event) => {
    setValue91(event.target.value);
  };
  const handleValue92Change = (event) => {
    setValue92(event.target.value);
  };
  const handleValue93Change = (event) => {
    setValue93(event.target.value);
  };
  const handleValue94Change = (event) => {
    setValue94(event.target.value);
  };
  const handleValue95Change = (event) => {
    setValue95(event.target.value);
  };
  const handleValue96Change = (event) => {
    setValue96(event.target.value);
  };
  const handleValue97Change = (event) => {
    setValue97(event.target.value);
  };
  const handleValue98Change = (event) => {
    setValue98(event.target.value);
  };
  const handleValue99Change = (event) => {
    setValue99(event.target.value);
  };
  const handleValue100Change = (event) => {
    setValue100(event.target.value);
  };
  const handleValue101Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue101(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue102Change = (event) => {
    setValue102(event.target.value);
  };
  const handleValue103Change = (event) => {
    setValue103(event.target.value);
  };
  const handleValue104Change = (event) => {
    setValue104(event.target.value);
  };
  const handleValue105Change = (event) => {
    setValue105(event.target.value);
  };
  const handleValue106Change = (event) => {
    setValue106(event.target.value);
  };
  const handleValue107Change = (event) => {
    setValue107(event.target.value);
  };
  const handleValue108Change = (event) => {
    setValue108(event.target.value);
  };
  const handleValue109Change = (event) => {
    setValue109(event.target.value);
  };
  const handleValue110Change = (event) => {
    setValue110(event.target.value);
  };
  const handleValue111Change = (event) => {
    setValue111(event.target.value);
  };
  const handleValue112Change = (event) => {
    setValue112(event.target.value);
  };
  const handleValue113Change = (event) => {
    setValue113(event.target.value);
  };
  const handleValue114Change = (event) => {
    setValue114(event.target.value);
  };
  const handleValue115Change = (event) => {
    setValue115(event.target.value);
  };
  const handleValue116Change = (event) => {
    setValue116(event.target.value);
  };
  const handleValue117Change = (event) => {
    setValue117(event.target.value);
  };
  const handleValue118Change = (event) => {
    setValue118(event.target.value);
  };
  const handleValue119Change = (event) => {
    setValue119(event.target.value);
  };
  const handleValue120Change = (event) => {
    setValue120(event.target.value);
  };
  const handleValue121Change = (event) => {
    setValue121(event.target.value);
  };
  const handleValue122Change = (event) => {
    setValue122(event.target.value);
  };
  const handleValue123Change = (event) => {
    setValue123(event.target.value);
  };
  const handleValue124Change = (event) => {
    setValue124(event.target.value);
  };

  // let duplicateEligibilityRules = [];
  // let eligibilityRules = [];
  let fertilityExpenseCapType = [];
  let paramedicalTypes = [];
  let paramedicalCapTypes = [];
  let annualFlightAllowanceProvision = [];
  let annualFlightAllowanceRoundTrip = [];
  let bikeBenefitType = [];
  let canteenOffered = [];
  let coverageAmountSalaryMultiple = [];
  let employeeContributionBasis = [];
  let employeeCTRBEligibleCompensation = [];
  let employerMatchTierTypes = [];
  let employerMatchVestSchedule = [];
  let employerNonMatchVestSchedule = [];
  let gymBenefitType = [];
  let hasAnnualFlightAllowanceChildrenBool = [];
  let hasBackUpFamilyCareChildBool = [];
  let hasBackUpFamilyCareSubsidyChildBool = [];
  let hasBackUpFamilyCareSubsidyParentBool = [];
  let hasCompanyShuttleBool = [];
  let hasRampBack = [];
  let incomeInsuranceCompensation = [];
  let loanType = [];
  let ltdDisabilityEliminationPeriod = [];
  let matchCompensation = [];
  let maternityLeaveDuration = [];
  let maternityPayIncome = [];
  let mobilePhoneProvision = [];
  let paternityLeaveDuration = [];
  let permanentDisabilityPaymentProvision = [];
  let retirementContributionType = [];
  let stdDisabilityEliminationPeriod = [];
  let studentLoanSupportType = [];
  let tuitionExpensesCovered = [];
  let waiverOfPremium = [];

  let test = [];
  for (let r of masterJson) {
    test.push(r.attribute_key_name_parent);
  }

  let uniqueArray = [];
  const test1 = new Map();
  for (const obj of test) {
    const objString = JSON.stringify(obj);
    if (!test1.has(objString)) {
      test1.set(objString, true);
      uniqueArray.push(obj);
    }
  }
  // console.log(uniqueArray);

  if (uniqueArray.includes("annual_flight_allowance_provision")) {
    for (let fect of masterJson) {
      if (
        fect.attribute_key_name_parent === "annual_flight_allowance_provision"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = fect.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          fect.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = fect.attribute_list_value_sort_order_child;
        fertilityExpenseCapType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("annual_flight_allowance_round_trip")) {
    for (let fect of masterJson) {
      if (
        fect.attribute_key_name_parent === "annual_flight_allowance_round_trip"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = fect.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          fect.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = fect.attribute_list_value_sort_order_child;
        fertilityExpenseCapType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("fertility_expense_cap_type")) {
    for (let fect of masterJson) {
      if (
        fect.attribute_key_name_parent === "fertility_expense_cap_type" &&
        fect.attribute_list_value_display_name_child !== null
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = fect.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          fect.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = fect.attribute_list_value_sort_order_child;
        fertilityExpenseCapType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("paramedical_types")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "paramedical_types") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        paramedicalTypes.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("paramedical_cap_type")) {
    for (let pct of masterJson) {
      if (pct.attribute_key_name_parent === "paramedical_cap_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pct.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          pct.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pct.attribute_list_value_sort_order_child;
        paramedicalCapTypes.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("bike_benefit_type")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "bike_benefit_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        bikeBenefitType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("canteen_offered")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "canteen_offered") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        canteenOffered.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("coverage_amt_salary_multiple")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "coverage_amt_salary_multiple") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        coverageAmountSalaryMultiple.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("employee_contribution_basis")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "employee_contribution_basis") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        employeeContributionBasis.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("employee_ctrb_eligible_compensation")) {
    for (let pt of masterJson) {
      if (
        pt.attribute_key_name_parent === "employee_ctrb_eligible_compensation"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        employeeCTRBEligibleCompensation.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("employer_match_vest_schedule")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "employer_match_vest_schedule") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        employerMatchVestSchedule.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("employer_match_tier_type")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "employer_match_tier_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        employerMatchTierTypes.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("employer_nonmatch_vest_schedule")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "employer_nonmatch_vest_schedule") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        employerNonMatchVestSchedule.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("fertility_expense_cap_type")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "fertility_expense_cap_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        fertilityExpenseCapType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("gym_benefit_type")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "gym_benefit_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        gymBenefitType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("has_annual_flight_allowance_children_bool")) {
    for (let pt of masterJson) {
      if (
        pt.attribute_key_name_parent ===
        "has_annual_flight_allowance_children_bool"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        hasAnnualFlightAllowanceChildrenBool.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("has_back_up_family_care_child_bool")) {
    for (let pt of masterJson) {
      if (
        pt.attribute_key_name_parent === "has_back_up_family_care_child_bool"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        hasBackUpFamilyCareChildBool.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("has_back_up_family_care_subsidy_child_bool")) {
    for (let pt of masterJson) {
      if (
        pt.attribute_key_name_parent ===
        "has_back_up_family_care_subsidy_child_bool"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        hasBackUpFamilyCareSubsidyChildBool.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("has_back_up_family_care_subsidy_parent_bool")) {
    for (let pt of masterJson) {
      if (
        pt.attribute_key_name_parent ===
        "has_back_up_family_care_subsidy_parent_bool"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        hasBackUpFamilyCareSubsidyParentBool.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("has_company_shuttle_bool")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "has_company_shuttle_bool") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        hasCompanyShuttleBool.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("has_ramp_back")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "has_ramp_back") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        hasRampBack.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("income_insurance_compensation")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "income_insurance_compensation") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        incomeInsuranceCompensation.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("loan_type")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "loan_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        loanType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("ltd_disability_elimination_period")) {
    for (let pt of masterJson) {
      if (
        pt.attribute_key_name_parent === "ltd_disability_elimination_period"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        ltdDisabilityEliminationPeriod.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("match_compensation")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "match_compensation") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        matchCompensation.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("maternity_leave_duration")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "maternity_leave_duration") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        maternityLeaveDuration.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("maternity_pay_income")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "maternity_pay_income") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        maternityPayIncome.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("mobile_phone_provision")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "mobile_phone_provision") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        mobilePhoneProvision.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("paternity_leave_duration")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "paternity_leave_duration") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        paternityLeaveDuration.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("permanent_disability_payment_provision")) {
    for (let pt of masterJson) {
      if (
        pt.attribute_key_name_parent ===
        "permanent_disability_payment_provision"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        permanentDisabilityPaymentProvision.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("retirement_contribution_type")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "retirement_contribution_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        retirementContributionType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("student_loan_support_type")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "student_loan_support_type") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        studentLoanSupportType.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("std_disability_elimination_period")) {
    for (let pt of masterJson) {
      if (
        pt.attribute_key_name_parent === "std_disability_elimination_period"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        stdDisabilityEliminationPeriod.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("tuition_expense_covered")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "tuition_expense_covered") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        tuitionExpensesCovered.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("waiver_of_premium")) {
    for (let pt of masterJson) {
      if (pt.attribute_key_name_parent === "waiver_of_premium") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pt.attribute_list_valid_value_child;
        dict1.listValueDisplayName = pt.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pt.attribute_list_value_sort_order_child;
        waiverOfPremium.push(dict1);
      }
    }
  }

  fertilityExpenseCapType.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  paramedicalTypes.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  paramedicalCapTypes.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  annualFlightAllowanceProvision.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  annualFlightAllowanceRoundTrip.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  bikeBenefitType.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  canteenOffered.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  coverageAmountSalaryMultiple.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  employeeContributionBasis.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  employeeCTRBEligibleCompensation.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  employerMatchTierTypes.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  employerMatchVestSchedule.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  employerNonMatchVestSchedule.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  gymBenefitType.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  hasAnnualFlightAllowanceChildrenBool.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  hasBackUpFamilyCareChildBool.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  hasBackUpFamilyCareSubsidyChildBool.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  hasBackUpFamilyCareSubsidyParentBool.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  hasCompanyShuttleBool.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  hasRampBack.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  incomeInsuranceCompensation.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  loanType.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  ltdDisabilityEliminationPeriod.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  matchCompensation.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  maternityLeaveDuration.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  maternityPayIncome.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  mobilePhoneProvision.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  paternityLeaveDuration.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  permanentDisabilityPaymentProvision.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  retirementContributionType.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  stdDisabilityEliminationPeriod.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  studentLoanSupportType.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  tuitionExpensesCovered.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  waiverOfPremium.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);

  // console.log(fertilityExpenseCapType);
  // console.log(paramedicalTypes);
  // console.log(paramedicalCapTypes);

  let duplicateFinalList = [
    ["fertility_expense_cap_type", value0],
    ["fertility_expense_cost_cap", value1],
    ["fertility_medical_expense_cost_cap", value2],
    ["fertility_rx_expense_cost_cap", value3],
    ["fertility_expense_treatment_cap", value4],
    ["paramedical_types", value5.join("+")],
    ["paramedical_cap_type", value6],
    ["paramedical_annual_service_cap", value7],
    ["allowance_amount", value9],
    ["annual_flight_allowance_provision", value10],
    ["annual_flight_allowance_round_trip", value11],
    ["back_up_care_amount", value12],
    ["back_up_care_days", value13],
    ["benefit_amt", value14],
    ["bike_benefit_type", value15.join("+")],
    ["canteen_offered", value16],
    ["carry_over_cap", value17],
    ["cash_in_lieu_monthly_amt", value18],
    ["copayment_amount", value19],
    ["coverage_amt_salary_multiple", value20],
    ["dependent_education_coverage_amount", value21],
    ["disability_payment_cap", value22],
    ["eap_annual_service_cap_number", value23],
    ["eap_service_mental_counselling_number", value24],
    ["eap_service_worklife_counselling_number", value25],
    ["early_retirmement_age", value26],
    ["employee_contribution_basis", value27],
    ["employee_contribution_level_1", value28],
    ["employee_contribution_level_2", value29],
    ["employee_contribution_level_3", value30],
    ["employee_contribution_level_4", value31],
    ["employee_contribution_level_5", value32],
    ["employee_contribution_maximimum_amt", value33],
    ["employee_contribution_maximimum_pct", value34],
    ["employee_ctrb_eligible_compensation", value35.join("+")],
    ["employee_match_months_of_service_1", value36],
    ["employee_match_months_of_service_2", value37],
    ["employee_match_months_of_service_3", value38],
    ["employee_match_months_of_service_4", value39],
    ["employee_match_months_of_service_5", value40],
    ["employee_non_match_months_of_service_1", value41],
    ["employee_non_match_months_of_service_2", value42],
    ["employee_non_match_months_of_service_3", value43],
    ["employee_non_match_months_of_service_4", value44],
    ["employee_non_match_months_of_service_5", value45],
    ["employer_match_contribution_cap", value46],
    ["employer_match_contribution_pct_1", value47],
    ["employer_match_contribution_pct_2", value48],
    ["employer_match_contribution_pct_3", value49],
    ["employer_match_contribution_pct_4", value50],
    ["employer_match_contribution_pct_5", value51],
    ["employer_match_tier_type", value52],
    ["employer_match_vest_schedule", value53],
    ["employer_non_match_contribution_cap", value54],
    ["employer_non_match_contribution_pct_1", value55],
    ["employer_non_match_contribution_pct_2", value56],
    ["employer_non_match_contribution_pct_3", value57],
    ["employer_non_match_contribution_pct_4", value58],
    ["employer_non_match_contribution_pct_5", value59],
    ["employer_nonmatch_vest_schedule", value60],
    ["free_cover_limit", value65],
    ["grocery_voucher_subsidy_pct", value66],
    ["gym_benefit_type", value67.join("+")],
    ["gym_reimbursement_amount", value68],
    ["has_annual_flight_allowance_children_bool", value69],
    ["has_back_up_family_care_child_bool", value70],
    ["has_back_up_family_care_subsidy_child_bool", value71],
    ["has_back_up_family_care_subsidy_parent_bool", value72],
    ["has_company_shuttle_bool", value73],
    ["has_ramp_back", value74],
    ["housing_advance_max_months", value75],
    ["housing_advance_min_months", value76],
    ["income_insurance_compensation", value77.join("+")],
    ["income_replacement_rate", value78],
    ["life_coverage_amt", value79],
    ["loan_number", value80],
    ["loan_type", value81.join("+")],
    ["ltd_disability_elimination_period", value82],
    ["ltd_disability_income_replacement_pct", value83],
    ["match_compensation", value84],
    ["maternity_leave_duration", value85],
    ["maternity_pay_income", value86],
    ["meal_voucher_value", value87],
    ["mobile_phone_provision", value88.join("+")],
    ["monthly_benefit_tier_1", value89],
    ["monthly_benefit_tier_2", value90],
    ["monthly_benefit_tier_3", value91],
    ["monthly_benefit_tier_4", value92],
    ["monthly_benefit_tier_5", value93],
    ["number_of_days", value94],
    ["number_of_days_core_family", value95],
    ["number_of_weeks", value96],
    ["off_site_parking_subsidy_amt", value97],
    ["paramedical_annual_cost_cap", value98],
    ["paternity_leave_duration", value99],
    ["permanent_disability_payment_provision", value100],
    ["retirement_contribution_type", value101.join("+")],
    ["service_tier_1", value102],
    ["service_tier_2", value103],
    ["service_tier_3", value104],
    ["service_tier_4", value105],
    ["service_tier_5", value106],
    ["service_tier_1_cap_month_duration", value107],
    ["service_tier_2_cap_month_duration", value108],
    ["service_tier_3_cap_month_duration", value109],
    ["service_tier_4_cap_month_duration", value110],
    ["service_tier_5_cap_month_duration", value111],
    ["std_disability_elimination_period", value112],
    ["std_disability_income_replacement_pct", value113],
    ["student_loan_annual_support_amount", value114],
    ["student_loan_support_type", value115],
    ["subsidy_pct", value116],
    ["time_off_monthly_days_tier_1", value117],
    ["time_off_monthly_days_tier_2", value118],
    ["time_off_monthly_days_tier_2", value119],
    ["time_off_monthly_days_tier_4", value120],
    ["time_off_monthly_days_tier_5", value121],
    ["tuition_expense_covered", value122],
    ["tuition_reimbursement_pct", value123],
    ["waiver_of_premium", value124],
  ];

  let finalList = [];

  for (var i of duplicateFinalList) {
    if (i[1].length > 0) {
      finalList.push(i);
    }
  }

  let finalDict = {
    date: `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
      date.$D > 9 ? date.$D : `0${date.$D}`
    }`,
  };

  for (var er of finalList) {
    finalDict[er[0]] = er[1];
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getBenefitPlanCoverageDetailsMappedValues(
        finalDict,
        `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
          date.$D > 9 ? date.$D : `0${date.$D}`
        }`,
      );
    }
  };

  return (
    <>
      {wait ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={wait}
        >
          <CircularProgress sx={{ color: "#DB2748" }} />{" "}
        </Backdrop>
      ) : (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Benefit Plan Coverage Details</Typography>
          </AccordionSummary>

          <AccordionDetails className="mx-5 mb-3 flex justify-between">
            <div>
              {alert === "success" && alertStatus ? (
                <Alert onClose={() => setAlertStatus(false)} severity="success">
                  Success! The details provided have been stored
                </Alert>
              ) : alert === "error" && alertStatus ? (
                <Alert onClose={() => setAlertStatus(false)} severity="error">
                  Please, Fill all the fields
                </Alert>
              ) : (
                ""
              )}
            </div>
          </AccordionDetails>

          <AccordionDetails className="grid grid-cols-2 gap-8 mx-5 mb-4">
            <FormControl fullWidth>
              <DatePicker
                label="As Of Date"
                name="date"
                value={date}
                onChange={(date) => setDate(date)}
                format="YYYY-DD-MM"
              />
            </FormControl>

            <FormControl fullWidth></FormControl>

            {uniqueArray.includes("fertility_expense_cap_type") && (
              <>
                {" "}
                <FormControl fullWidth>
                  <Tooltip title={"Fertility Expense Cap Type"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Fertility Expense Cap Type"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD12346">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD123458"
                    id="BPCD12346"
                    value={value0}
                    label={"Value"}
                    onChange={handleValue0Change}
                  >
                    {fertilityExpenseCapType.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("fertility_expense_treatment_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Fertility Service Cycles Covered"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Fertility Service Cycles Covered"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123418"
                    value={value4}
                    label={"Number"}
                    onChange={handleValue4Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("paramedical_cap_type") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Paramedical Cap Type"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Paramedical Cap Type"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234145">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234157"
                    id="BPCD1234169"
                    value={value6}
                    label={"Value"}
                    onChange={handleValue6Change}
                  >
                    {paramedicalCapTypes.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("paramedical_annual_service_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Paramedical Annual Service Cap"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Paramedical Annual Service Cap"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD1234181"
                    value={value7}
                    label={"Value"}
                    onChange={handleValue7Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("allowance_amount") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Meal Allowance Amount"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Meal Allowance Amount"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123433"
                    value={value9}
                    label={"Amount"}
                    onChange={handleValue9Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("annual_flight_allowance_provision") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Flight Allowance Provision Method"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Flight Allowance Provision Method"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234151">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234163"
                    id="BPCD1234175"
                    value={value10}
                    label={"Value"}
                    onChange={handleValue10Change}
                  >
                    {annualFlightAllowanceProvision.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("annual_flight_allowance_round_trip") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Flight Allowance - Fare Type"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Flight Allowance - Fare Type"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234154">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234166"
                    id="BPCD1234178"
                    value={value11}
                    label={"Value"}
                    onChange={handleValue11Change}
                  >
                    {annualFlightAllowanceRoundTrip.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("back_up_care_amount") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Back-Up Care Amount"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Back-Up Care Amount"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123440"
                    value={value12}
                    label={"Amount"}
                    onChange={handleValue12Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("back_up_care_days") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Back-Up Care Days"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Back-Up Care Days"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123441"
                    value={value13}
                    label={"Value"}
                    onChange={handleValue13Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("benefit_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Benefit Amount"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Benefit Amount"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123442"
                    value={value14}
                    label={"Value"}
                    onChange={handleValue14Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("bike_benefit_type") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Benefit Type"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Benefit Type"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234160">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234172"
                    id="BPCD1234184"
                    value={value15}
                    label={"Value"}
                    multiple
                    onChange={handleValue15Change}
                  >
                    {bikeBenefitType.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("canteen_offered") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"On-Site Canteen"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"On-Site Canteen"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234163">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234175"
                    id="BPCD1234187"
                    value={value16}
                    label={"Value"}
                    onChange={handleValue16Change}
                  >
                    {canteenOffered.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("carry_over_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Carry Over Cap"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Carry Over Cap"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123449"
                    value={value17}
                    label={"Number"}
                    onChange={handleValue17Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("cash_in_lieu_monthly_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Cash In Lieu of Benefit Amount"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Cash In Lieu of Benefit Amount"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123450"
                    value={value18}
                    label={"Amount"}
                    onChange={handleValue18Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("copayment_amount") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123451"
                    value={value19}
                    label={"Amount"}
                    onChange={handleValue19Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("coverage_amt_salary_multiple") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Covered Amount - Multiple of Salary"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Covered Amount - Multiple of Salary"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234169">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234181"
                    id="BPCD1234193"
                    value={value20}
                    label={"Value"}
                    onChange={handleValue20Change}
                  >
                    {coverageAmountSalaryMultiple.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dependent_education_coverage_amount") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Annual Child Education Amount"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Annual Child Education Amount"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123455"
                    value={value21}
                    label={"Amount"}
                    onChange={handleValue21Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("disability_payment_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Maximium Monthly Benefit"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Maximium Monthly Benefit"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123456"
                    value={value22}
                    label={"Amount"}
                    onChange={handleValue22Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("eap_annual_service_cap_number") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Annual Sessions Max"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Annual Sessions Max"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123457"
                    value={value23}
                    label={"Number"}
                    onChange={handleValue23Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("eap_service_mental_counselling_number") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Mental Health - Sessions"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Mental Health - Sessions"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123458"
                    value={value24}
                    label={"Number"}
                    onChange={handleValue24Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "eap_service_worklife_counselling_number",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Work-Life Sessions"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Work-Life Sessions"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123459"
                    value={value25}
                    label={"Number"}
                    onChange={handleValue25Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("early_retirmement_age") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Early Retirement Age"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Early Retirement Age"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123460"
                    value={value26}
                    label={"Number"}
                    onChange={handleValue26Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_contribution_basis") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employee contribution units"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employee contribution units"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234178">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234190"
                    id="BPCD1234202"
                    value={value27}
                    label={"Value"}
                    onChange={handleValue27Change}
                  >
                    {employeeContributionBasis.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_contribution_level_1") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"EE Contribution Cap Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"EE Contribution Cap Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123464"
                    value={value28}
                    label={"Percent"}
                    onChange={handleValue28Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_contribution_level_2") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"EE Contribution Cap Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"EE Contribution Cap Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123465"
                    value={value29}
                    label={"Percent"}
                    onChange={handleValue29Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_contribution_level_3") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"EE Contribution Cap Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"EE Contribution Cap Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123466"
                    value={value30}
                    label={"Percent"}
                    onChange={handleValue30Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_contribution_level_4") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"EE Contribution Cap Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"EE Contribution Cap Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123467"
                    value={value31}
                    label={"Percent"}
                    onChange={handleValue31Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_contribution_level_5") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"EE Contribution Cap Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"EE Contribution Cap Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123468"
                    value={value32}
                    label={"Number"}
                    onChange={handleValue32Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_contribution_maximimum_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employee Min Contribution"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employee Min Contribution"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123469"
                    value={value33}
                    label={"Amount"}
                    onChange={handleValue33Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_contribution_maximimum_pct") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employee Max Contribution"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employee Max Contribution"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123470"
                    value={value34}
                    label={"Number"}
                    onChange={handleValue34Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_ctrb_eligible_compensation") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Eligible compensation"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Eligible compensation"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234188">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234200"
                    id="BPCD1234212"
                    value={value35}
                    multiple
                    label={"Value"}
                    onChange={handleValue35Change}
                  >
                    {employeeCTRBEligibleCompensation.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_match_months_of_service_1") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123474"
                    value={value36}
                    label={"Number"}
                    onChange={handleValue36Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_match_months_of_service_2") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123475"
                    value={value37}
                    label={"Number"}
                    onChange={handleValue37Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}
            {uniqueArray.includes("employee_match_months_of_service_3") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123476"
                    value={value38}
                    label={"Number"}
                    onChange={handleValue38Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}
            {uniqueArray.includes("employee_match_months_of_service_4") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123477"
                    value={value39}
                    label={"Number"}
                    onChange={handleValue39Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}
            {uniqueArray.includes("employee_match_months_of_service_5") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123518"
                    value={value40}
                    label={"Number"}
                    onChange={handleValue40Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_non_match_months_of_service_1") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Non Match Ctrb Months of Service Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Non Match Ctrb Months of Service Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123479"
                    value={value41}
                    label={"Number"}
                    onChange={handleValue41Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_non_match_months_of_service_2") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Non Match Ctrb Months of Service Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Non Match Ctrb Months of Service Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123480"
                    value={value42}
                    label={"Number"}
                    onChange={handleValue42Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_non_match_months_of_service_3") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Non Match Ctrb Months of Service Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Non Match Ctrb Months of Service Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123481"
                    value={value43}
                    label={"Number"}
                    onChange={handleValue43Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_non_match_months_of_service_4") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Non Match Ctrb Months of Service Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Non Match Ctrb Months of Service Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123482"
                    value={value44}
                    label={"Number"}
                    onChange={handleValue44Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employee_non_match_months_of_service_5") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Non Match Ctrb Months of Service Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Non Match Ctrb Months of Service Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123523"
                    value={value45}
                    label={"Number"}
                    onChange={handleValue45Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_match_contribution_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer match contribution cap"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer match contribution cap"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123524"
                    value={value46}
                    label={"Amount"}
                    onChange={handleValue46Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_match_contribution_pct_1") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Contribution Cap Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Contribution Cap Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123485"
                    value={value47}
                    label={"Percent"}
                    onChange={handleValue47Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_match_contribution_pct_2") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Contribution Cap Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Contribution Cap Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123486"
                    value={value48}
                    label={"Percent"}
                    onChange={handleValue48Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_match_contribution_pct_3") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Contribution Cap Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Contribution Cap Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123487"
                    value={value49}
                    label={"Percent"}
                    onChange={handleValue49Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_match_contribution_pct_4") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Contribution Cap Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Contribution Cap Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123488"
                    value={value50}
                    label={"Percent"}
                    onChange={handleValue50Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_match_contribution_pct_5") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Contribution Cap Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Contribution Cap Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123529"
                    value={value51}
                    label={"Percent"}
                    onChange={handleValue51Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_match_tier_type") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer match scheme"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer match scheme"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234207">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234219"
                    id="BPCD1234231"
                    value={value52}
                    label={"Value"}
                    onChange={handleValue52Change}
                  >
                    {employerMatchTierTypes.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_match_vest_schedule") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Matching contributions - vest schedule"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Matching contributions - vest schedule"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234210">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234222"
                    id="BPCD1234234"
                    value={value53}
                    label={"Value"}
                    onChange={handleValue53Change}
                  >
                    {employerMatchVestSchedule.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_non_match_contribution_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Non-Match Contribution cap"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Non-Match Contribution cap"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123536"
                    value={value54}
                    label={"Amount"}
                    onChange={handleValue54Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_non_match_contribution_pct_1") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Non-Match Contribution Cap Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Non-Match Contribution Cap Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123497"
                    value={value55}
                    label={"Percent"}
                    onChange={handleValue55Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_non_match_contribution_pct_2") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Non-Match Contribution Cap Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Non-Match Contribution Cap Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123498"
                    value={value56}
                    label={"Percent"}
                    onChange={handleValue56Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_non_match_contribution_pct_3") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Non-Match Contribution Cap Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Non-Match Contribution Cap Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123499"
                    value={value57}
                    label={"Percent"}
                    onChange={handleValue57Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_non_match_contribution_pct_4") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Non-Match Contribution Cap Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Non-Match Contribution Cap Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123500"
                    value={value58}
                    label={"Percent"}
                    onChange={handleValue58Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_non_match_contribution_pct_5") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Employer Non-Match Contribution Cap Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Employer Non-Match Contribution Cap Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123541"
                    value={value59}
                    label={"Percent"}
                    onChange={handleValue59Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("employer_nonmatch_vest_schedule") && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={
                      "Employer non-matching contributions - vest schedule"
                    }
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={
                        "Employer non-matching contributions - vest schedule"
                      }
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234219">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234231"
                    id="BPCD1234243"
                    value={value60}
                    label={"Value"}
                    onChange={handleValue60Change}
                  >
                    {employerNonMatchVestSchedule.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("free_cover_limit") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Free Cover Limit Amt"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Free Cover Limit Amt"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123551"
                    value={value65}
                    label={"Amount"}
                    onChange={handleValue65Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("grocery_voucher_subsidy_pct") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Percent of grocery-voucher subsidized?"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Percent of grocery-voucher subsidized?"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123552"
                    value={value66}
                    label="Percent"
                    onChange={handleValue66Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("gym_benefit_type") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Gym Benefit Type"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Gym Benefit Type"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234230">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234242"
                    id="BPCD1234254"
                    value={value67}
                    label={"Value"}
                    multiple
                    onChange={handleValue67Change}
                  >
                    {gymBenefitType.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("gym_reimbursement_amount") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Gym Reimbursement Amount"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Gym Reimbursement Amount"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123556"
                    value={value68}
                    label="Amount"
                    onChange={handleValue68Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "has_annual_flight_allowance_children_bool",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Children flight expense included?"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Children flight expense included?"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234234">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234246"
                    id="BPCD1234258"
                    value={value69}
                    label={"Value"}
                    onChange={handleValue69Change}
                  >
                    {hasAnnualFlightAllowanceChildrenBool.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("has_back_up_family_care_child_bool") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Family care support for child?"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Family care support for child?"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234237">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234249"
                    id="BPCD1234261"
                    value={value70}
                    label={"Value"}
                    onChange={handleValue70Change}
                  >
                    {hasBackUpFamilyCareChildBool.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "has_back_up_family_care_subsidy_child_bool",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={
                      "Offer subsidies for back-up family care for children?"
                    }
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={
                        "Offer subsidies for back-up family care for children?"
                      }
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234240">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234252"
                    id="BPCD1234264"
                    value={value71}
                    label={"Value"}
                    onChange={handleValue71Change}
                  >
                    {hasBackUpFamilyCareSubsidyChildBool.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "has_back_up_family_care_subsidy_parent_bool",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={
                      "Offer subsidies for back-up family care for elderly parents?"
                    }
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={
                        "Offer subsidies for back-up family care for elderly parents?"
                      }
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234243">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234255"
                    id="BPCD1234267"
                    value={value72}
                    label={"Value"}
                    onChange={handleValue72Change}
                  >
                    {hasBackUpFamilyCareSubsidyParentBool.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            {uniqueArray.includes("has_company_shuttle_bool") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Company shuttle/bus"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Company shuttle/bus"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234246">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234258"
                    id="BPCD1234270"
                    value={value73}
                    label={"Value"}
                    onChange={handleValue73Change}
                  >
                    {hasCompanyShuttleBool.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("has_ramp_back") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Has Ramp Back"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Has Ramp Back"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234249">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234261"
                    id="BPCD1234273"
                    value={value74}
                    label={"Value"}
                    onChange={handleValue74Change}
                  >
                    {hasRampBack.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("housing_advance_max_months") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Housing Advance Maximum Months"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Housing Advance Maximum Months"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123575"
                    value={value75}
                    label="Number"
                    onChange={handleValue75Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("housing_advance_min_months") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Housing Advance Minimum Months"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Housing Advance Minimum Months"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123576"
                    value={value76}
                    label="Number"
                    onChange={handleValue76Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("income_insurance_compensation") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Eligible compensation"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Eligible compensation"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234254">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234266"
                    id="BPCD1234278"
                    value={value77}
                    multiple
                    label={"Value"}
                    onChange={handleValue77Change}
                  >
                    {incomeInsuranceCompensation.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("income_replacement_rate") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Income Replacement Rate"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Income Replacement Rate"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123580"
                    value={value78}
                    label="Percent"
                    onChange={handleValue78Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("life_coverage_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Life Insurance - Fixed Cover Amt"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Life Insurance - Fixed Cover Amt"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123581"
                    value={value79}
                    label="Amount"
                    onChange={handleValue79Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("loan_number") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Number of Loans"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Number of Loans"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123582"
                    value={value80}
                    label="Number"
                    onChange={handleValue80Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("loan_type") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Loan Types"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Loan Types"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234260">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234272"
                    id="BPCD1234284"
                    value={value81}
                    label={"Value"}
                    multiple
                    onChange={handleValue81Change}
                  >
                    {loanType.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("ltd_disability_elimination_period") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"LTD Waiting Period"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"LTD Waiting Period"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234263">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234275"
                    id="BPCD1234287"
                    value={value82}
                    label={"Number"}
                    onChange={handleValue82Change}
                  >
                    {ltdDisabilityEliminationPeriod.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("ltd_disability_income_replacement_pct") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"LTD Income Replacement Pct"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"LTD Income Replacement Pct"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123589"
                    value={value83}
                    label="Percent"
                    onChange={handleValue83Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("match_compensation") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Eligible compensation"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Eligible compensation"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234267">{"Amount"}</InputLabel>
                  <Select
                    labelId="BPCD1234279"
                    id="BPCD1234291"
                    value={value84}
                    label={"Amount"}
                    onChange={handleValue84Change}
                  >
                    {matchCompensation.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("maternity_leave_duration") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Maternity leave duration"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Maternity leave duration"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234270">{"Number"}</InputLabel>
                  <Select
                    labelId="BPCD1234282"
                    id="BPCD1234294"
                    value={value85}
                    label={"Number"}
                    onChange={handleValue85Change}
                  >
                    {maternityLeaveDuration.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("maternity_pay_income") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Maternity Pay - Income Replacement Pct"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Maternity Pay - Income Replacement Pct"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234273">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234285"
                    id="BPCD1234297"
                    value={value86}
                    label={"Number"}
                    onChange={handleValue86Change}
                  >
                    {maternityPayIncome.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("meal_voucher_value") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Meal Voucher Value"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Meal Voucher Value"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123599"
                    value={value87}
                    label="Amount"
                    onChange={handleValue87Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("mobile_phone_provision") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Mobile Phone Provision"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Mobile Phone Provision"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234277">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234289"
                    id="BPCD1234301"
                    value={value88}
                    multiple
                    label={"Number"}
                    onChange={handleValue88Change}
                  >
                    {mobilePhoneProvision.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("monthly_benefit_tier_1") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Benefit Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Benefit Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123563"
                    value={value89}
                    label={"Number"}
                    onChange={handleValue89Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("monthly_benefit_tier_2") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Benefit Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Benefit Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123564"
                    value={value90}
                    label={"Number"}
                    onChange={handleValue90Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("monthly_benefit_tier_3") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Benefit Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Benefit Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123565"
                    value={value91}
                    label={"Number"}
                    onChange={handleValue91Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("monthly_benefit_tier_4") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Benefit Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Benefit Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123566"
                    value={value92}
                    label={"Number"}
                    onChange={handleValue92Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("monthly_benefit_tier_5") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Benefit Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Benefit Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123607"
                    value={value93}
                    label={"Number"}
                    onChange={handleValue93Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("number_of_days") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Number of Days"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Number of Days"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123608"
                    value={value94}
                    label={"Number"}
                    onChange={handleValue94Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("number_of_days_core_family") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Number of Days - Core Family"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Number of Days - Core Family"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123609"
                    value={value95}
                    label={"Number"}
                    onChange={handleValue95Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("number_of_weeks") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Number of Weeks"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Number of Weeks"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123610"
                    value={value96}
                    label={"Number"}
                    onChange={handleValue96Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("off_site_parking_subsidy_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Off-site parking subsidy amount"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Off-site parking subsidy amount"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123611"
                    value={value97}
                    label={"Number"}
                    onChange={handleValue97Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("paramedical_annual_cost_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Paramedical Annual Cost Cap"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Paramedical Annual Cost Cap"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123612"
                    value={value98}
                    label={"Number"}
                    onChange={handleValue98Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("paternity_leave_duration") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Paternity leave duration"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Paternity leave duration"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234290">{"Number"}</InputLabel>
                  <Select
                    labelId="BPCD1234302"
                    id="BPCD1234314"
                    value={value99}
                    label={"Number"}
                    onChange={handleValue99Change}
                  >
                    {paternityLeaveDuration.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("permanent_disability_payment_provision") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Payment Provision"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Payment Provision"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234293">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234305"
                    id="BPCD1234317"
                    value={value100}
                    label={"Number"}
                    onChange={handleValue100Change}
                  >
                    {permanentDisabilityPaymentProvision.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("retirement_contribution_type") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Retirement Savings Plan Type"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Retirement Savings Plan Type"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234296">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234308"
                    id="BPCD1234320"
                    value={value101}
                    multiple
                    label={"Value"}
                    onChange={handleValue101Change}
                  >
                    {retirementContributionType.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_1") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123582"
                    value={value102}
                    label={"Number"}
                    onChange={handleValue102Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_2") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123583"
                    value={value103}
                    label={"Number"}
                    onChange={handleValue103Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_3") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123584"
                    value={value104}
                    label={"Number"}
                    onChange={handleValue104Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_4") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123585"
                    value={value105}
                    label={"Number"}
                    onChange={handleValue105Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_5") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Cap Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Cap Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123626"
                    value={value106}
                    label={"Number"}
                    onChange={handleValue106Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_1_cap_month_duration") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123587"
                    value={value107}
                    label={"Number"}
                    onChange={handleValue107Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_2_cap_month_duration") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123588"
                    value={value108}
                    label={"Number"}
                    onChange={handleValue108Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_3_cap_month_duration") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123589"
                    value={value109}
                    label={"Number"}
                    onChange={handleValue109Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_4_cap_month_duration") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123590"
                    value={value110}
                    label={"Number"}
                    onChange={handleValue110Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("service_tier_5_cap_month_duration") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Months of Service Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Months of Service Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123631"
                    value={value111}
                    label={"Number"}
                    onChange={handleValue111Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("std_disability_elimination_period") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"STD Waiting Period"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"STD Waiting Period"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234309">{"Number"}</InputLabel>
                  <Select
                    labelId="BPCD1234321"
                    id="BPCD1234333"
                    value={value112}
                    label={"Number"}
                    onChange={handleValue112Change}
                  >
                    {stdDisabilityEliminationPeriod.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("std_disability_income_replacement_pct") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"STD Income Replacement Pct"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"STD Income Replacement Pct"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123635"
                    value={value113}
                    label={"Percent"}
                    onChange={handleValue113Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("student_loan_annual_support_amount") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Annual Student Loan Contrbution Amt"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Annual Student Loan Contrbution Amt"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123636"
                    value={value114}
                    label={"Amount"}
                    onChange={handleValue114Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("student_loan_support_type") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Student Loan Support"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Student Loan Support"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234314">{"Number"}</InputLabel>
                  <Select
                    labelId="BPCD1234326"
                    id="BPCD1234338"
                    value={value115}
                    label={"Number"}
                    onChange={handleValue115Change}
                  >
                    {studentLoanSupportType.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("subsidy_pct") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Subsidy Percent"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Subsidy Percent"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123640"
                    value={value116}
                    label={"Percent"}
                    onChange={handleValue116Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("time_off_monthly_days_tier_1") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Hours Per Month - Tier 1"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Hours Per Month - Tier 1"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123601"
                    value={value117}
                    label={"Number"}
                    onChange={handleValue117Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("time_off_monthly_days_tier_2") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Hours Per Month - Tier 2"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Hours Per Month - Tier 2"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123602"
                    value={value118}
                    label={"Number"}
                    onChange={handleValue118Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("time_off_monthly_days_tier_3") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Hours Per Month - Tier 3"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Hours Per Month - Tier 3"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123603"
                    value={value119}
                    label={"Number"}
                    onChange={handleValue119Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("time_off_monthly_days_tier_4") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Hours Per Month - Tier 4"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Hours Per Month - Tier 4"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123604"
                    value={value120}
                    label={"Number"}
                    onChange={handleValue120Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("time_off_monthly_days_tier_5") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Monthly Hours Per Month - Tier 5"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Monthly Hours Per Month - Tier 5"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123645"
                    value={value121}
                    label={"Number"}
                    onChange={handleValue121Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("tuition_expense_covered") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Tuition Expenses Covered"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Tuition Expenses Covered"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234323">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234335"
                    id="BPCD1234347"
                    value={value122}
                    label={"Value"}
                    onChange={handleValue122Change}
                  >
                    {tuitionExpensesCovered.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("tuition_reimbursement_pct") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Tuition Reimbursement Pct"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Tuition Reimbursement Pct"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="BPCD123649"
                    value={value123}
                    label={"Percent"}
                    onChange={handleValue123Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("waiver_of_premium") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Waiver of Premium"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Waiver of Premium"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="BPCD1234327">{"Value"}</InputLabel>
                  <Select
                    labelId="BPCD1234339"
                    id="BPCD1234351"
                    value={value124}
                    label={"Value"}
                    onChange={handleValue124Change}
                  >
                    {waiverOfPremium.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            <FormControl fullWidth></FormControl>
            <div className="mt-5 w-full flex justify-end items-center">
              <button
                type="submit"
                className="ml-5 bg-[#0000A3] py-[6px]    shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg   px-6  text-white"
                onClick={(e) => {
                  handleSave(e);
                }}
              >
                Save
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

          // <div className="pt-5">
          //   {" "}
          //   {finalList.map((item, key) => (
          //     <div key={key}>
          //       {item[0]} : {item[1]}
          //     </div>
          //   ))}
          // </div>
// {JSON.stringify(
//               [
//                 ["as_of_date", date.$y, date.$M + 1, date.$D],
//                 ["fertility_expense_cap_type", value0],
//                 ["fertility_expense_cost_cap", value1],
//                 ["fertility_medical_expense_cost_cap", value2],
//                 ["fertility_rx_expense_cost_cap", value3],
//                 ["fertility_expense_treatment_cap", value4],
//                 ["paramedical_types", value5],
//                 ["paramedical_cap_type", value6],
//                 ["paramedical_annual_service_cap", value7],
//                 ["allowance_amount", value9],
//                 ["annual_flight_allowance_provision", value10],
//                 ["annual_flight_allowance_round_trip", value11],
//                 ["back_up_care_amount", value12],
//                 ["back_up_care_days", value13],
//                 ["benefit_amt", value14],
//                 ["bike_benefit_type", value15],
//                 ["canteen_offered", value16],
//                 ["carry_over_cap", value17],
//                 ["cash_in_lieu_monthly_amt", value18],
//                 ["copayment_amount", value19],
//                 ["coverage_amt_salary_multiple", value20],
//                 ["dependent_education_coverage_amount", value21],
//                 ["disability_payment_cap", value22],
//                 ["eap_annual_service_cap_number", value23],
//                 ["eap_service_mental_counselling_number", value24],
//                 ["eap_service_worklife_counselling_number", value25],
//                 ["early_retirmement_age", value26],
//                 ["employee_contribution_basis", value27],
//                 ["employee_contribution_level_1", value28],
//                 ["employee_contribution_level_2", value29],
//                 ["employee_contribution_level_3", value30],
//                 ["employee_contribution_level_4", value31],
//                 ["employee_contribution_level_5", value32],
//                 ["employee_contribution_maximimum_amt", value33],
//                 ["employee_contribution_maximimum_pct", value34],
//                 ["employee_ctrb_eligible_compensation", value35],
//                 ["employee_match_months_of_service_1", value36],
//                 ["employee_match_months_of_service_2", value37],
//                 ["employee_match_months_of_service_3", value38],
//                 ["employee_match_months_of_service_4", value39],
//                 ["employee_match_months_of_service_5", value40],
//                 ["employee_non_match_months_of_service_1", value41],
//                 ["employee_non_match_months_of_service_2", value42],
//                 ["employee_non_match_months_of_service_3", value43],
//                 ["employee_non_match_months_of_service_4", value44],
//                 ["employee_non_match_months_of_service_5", value45],
//                 ["employer_match_contribution_cap", value46],
//                 ["employer_match_contribution_pct_1", value47],
//                 ["employer_match_contribution_pct_2", value48],
//                 ["employer_match_contribution_pct_3", value49],
//                 ["employer_match_contribution_pct_4", value50],
//                 ["employer_match_contribution_pct_5", value51],
//                 ["employer_match_tier_type", value52],
//                 ["employer_match_vest_schedule", value53],
//                 ["employer_non_match_contribution_cap", value54],
//                 ["employer_non_match_contribution_pct_1", value55],
//                 ["employer_non_match_contribution_pct_2", value56],
//                 ["employer_non_match_contribution_pct_3", value57],
//                 ["employer_non_match_contribution_pct_4", value58],
//                 ["employer_non_match_contribution_pct_5", value59],
//                 ["employer_nonmatch_vest_schedule", value60],
//                 ["free_cover_limit", value65],
//                 ["grocery_voucher_subsidy_pct", value66],
//                 ["gym_benefit_type", value67],
//                 ["gym_reimbursement_amount", value68],
//                 ["has_annual_flight_allowance_children_bool", value69],
//                 ["has_back_up_family_care_child_bool", value70],
//                 ["has_back_up_family_care_subsidy_child_bool", value71],
//                 ["has_back_up_family_care_subsidy_parent_bool", value72],
//                 ["has_company_shuttle_bool", value73],
//                 ["has_ramp_back", value74],
//                 ["housing_advance_max_months", value75],
//                 ["housing_advance_min_months", value76],
//                 ["income_insurance_compensation", value77],
//                 ["income_replacement_rate", value78],
//                 ["life_coverage_amt", value79],
//                 ["loan_number", value80],
//                 ["loan_type", value81],
//                 ["ltd_disability_elimination_period", value82],
//                 ["ltd_disability_income_replacement_pct", value83],
//                 ["match_compensation", value84],
//                 ["maternity_leave_duration", value85],
//                 ["maternity_pay_income", value86],
//                 ["meal_voucher_value", value87],
//                 ["mobile_phone_provision", value88],
//                 ["monthly_benefit_tier_1", value89],
//                 ["monthly_benefit_tier_2", value90],
//                 ["monthly_benefit_tier_3", value91],
//                 ["monthly_benefit_tier_4", value92],
//                 ["monthly_benefit_tier_5", value93],
//                 ["number_of_days", value94],
//                 ["number_of_days_core_family", value95],
//                 ["number_of_weeks", value96],
//                 ["off_site_parking_subsidy_amt", value97],
//                 ["paramedical_annual_cost_cap", value98],
//                 ["paternity_leave_duration", value99],
//                 ["permanent_disability_payment_provision", value100],
//                 ["retirement_contribution_type", value101],
//                 ["service_tier_1", value102],
//                 ["service_tier_2", value103],
//                 ["service_tier_3", value104],
//                 ["service_tier_4", value105],
//                 ["service_tier_5", value106],
//                 ["service_tier_1_cap_month_duration", value107],
//                 ["service_tier_2_cap_month_duration", value108],
//                 ["service_tier_3_cap_month_duration", value109],
//                 ["service_tier_4_cap_month_duration", value110],
//                 ["service_tier_5_cap_month_duration", value111],
//                 ["std_disability_elimination_period", value112],
//                 ["std_disability_income_replacement_pct", value113],
//                 ["student_loan_annual_support_amount", value114],
//                 ["student_loan_support_type", value115],
//                 ["subsidy_pct", value116],
//                 ["time_off_monthly_days_tier_1", value117],
//                 ["time_off_monthly_days_tier_2", value118],
//                 ["time_off_monthly_days_tier_2", value119],
//                 ["time_off_monthly_days_tier_4", value120],
//                 ["time_off_monthly_days_tier_5", value121],
//                 ["tuition_expense_covered", value122],
//                 ["tuition_reimbursement_pct", value123],
//                 ["waiver_of_premium", value124],
//               ],
//               null,
//               "    "
//             )}

// const finalList = [
//   [date.$y, date.$M + 1, date.$D],
//   [eligibilityRules[0].listValueKey, value0],
//   [eligibilityRules[1].listValueKey, value1],
//   [eligibilityRules[2].listValueKey, value2],
//   [eligibilityRules[3].listValueKey, value3],
//   [eligibilityRules[4].listValueKey, value4],
//   [eligibilityRules[5].listValueKey, value5],
//   [eligibilityRules[6].listValueKey, value6],
//   [eligibilityRules[7].listValueKey, value7],
//   [eligibilityRules[8].listValueKey, value8],
// ];
// const handleSave = (e) => {
//   e.preventDefault();
//   getBPCD1431MappedValues(finalList);
// };
// for (let rules of masterJson) {
//   if (rules.child_operator_flag === "child") {
//     let dict1 = {
//       listValueKey: "",
//       listValueDisplayName: "",
//       listValueSortOrder: "",
//       listValueRightDisplayValue: "",
//     };
//
//     dict1.listValueKey = rules.attribute_key_name_parent;
//     dict1.listValueDisplayName = rules.attribute_key_display_name_parent;
//     dict1.listValueSortOrder = rules.attribute_list_value_sort_order_parent;
//     dict1.listValueRightDisplayValue = rules.attribute_key_display_name_child;
//     duplicateEligibilityRules.push(dict1);
//   }
// }
//
// const uniqueMapCid = new Map();
// for (const obj of duplicateEligibilityRules) {
//   const objString = JSON.stringify(obj);
//   if (!uniqueMapCid.has(objString)) {
//     uniqueMapCid.set(objString, true);
//     eligibilityRules.push(obj);
//   }
// }

// let duplicateColMapEligibilityRules = eligibilityRules.map(
//   (item, key) => item.listValueDisplayName
// );
// let colMapEligibilityRules = [];
// let start = 0;
// for (let s of duplicateColMapEligibilityRules) {
//   colMapEligibilityRules[s] = start;
//   start++;
// }

//
// ["fertility_expense_cap_type", value61],
// ["fertility_expense_cost_cap", value62],
// ["fertility_medical_expense_cost_cap", value63],
// ["fertility_rx_expense_cost_cap", value64],
//
//             {uniqueArray.includes("fertility_expense_cap_type") && (
//               <>
//                 <FormControl fullWidth>
//                   <Tooltip title={"Fertility Expense Cap Type"}>
//                     <TextField
//                       label="Coverage Rule"
//                       id="outlined-basic"
//                       value={"Fertility Expense Cap Type"}
//                       variant="outlined"
//                       inputProps={{ readOnly: true }}
//                     />
//                   </Tooltip>
//                 </FormControl>
//                 <FormControl fullWidth>
//                   <InputLabel id="BPCD1234222">
//                     {"Value"}
//                   </InputLabel>
//                   <Select
//                     labelId="BPCD1234234"
//                     id="BPCD1234246"
//                     value={value61}
//                     label={"Value"}
//                     onChange={handleValue61Change}
//                   >
//                     {fertilityExpenseCapType.map((item, key) => (
//                       <MenuItem value={item.listValueKey} key={key}>
//                         {item.listValueDisplayName}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </>
//             )}
//
//             {uniqueArray.includes("fertility_expense_cost_cap") && (
//               <>
//                 <FormControl fullWidth>
//                   <Tooltip title={"Life Time Fertility Cost Cap - Total"}>
//                     <TextField
//                       label="Coverage Rule"
//                       id="outlined-basic"
//                       value={"Life Time Fertility Cost Cap - Total"}
//                       variant="outlined"
//                       inputProps={{ readOnly: true }}
//                     />
//                   </Tooltip>
//                 </FormControl>
//                 <FormControl fullWidth>
//                   <TextField
//                     id="BPCD123548"
//                     value={value62}
//                     label={"Amount"}
//                     onChange={handleValue62Change}
//                     variant="outlined"
//                   />
//                 </FormControl>
//               </>
//             )}
//
//             {uniqueArray.includes("fertility_medical_expense_cost_cap") && (
//               <>
//                 <FormControl fullWidth>
//                   <Tooltip title={"Lifetime Fertility Cost Cap (Exclude Rx)"}>
//                     <TextField
//                       label="Coverage Rule"
//                       id="outlined-basic"
//                       value={"Lifetime Fertility Cost Cap (Exclude Rx)"}
//                       variant="outlined"
//                       inputProps={{ readOnly: true }}
//                     />
//                   </Tooltip>
//                 </FormControl>
//                 <FormControl fullWidth>
//                   <TextField
//                     id="BPCD123549"
//                     value={value63}
//                     label={"Amount"}
//                     onChange={handleValue63Change}
//                     variant="outlined"
//                   />
//                 </FormControl>
//               </>
//             )}
//
//             {uniqueArray.includes("fertility_rx_expense_cost_cap") && (
//               <>
//                 <FormControl fullWidth>
//                   <Tooltip title={"Lifetime Fertility Cost Cap - Rx Only"}>
//                     <TextField
//                       label="Coverage Rule"
//                       id="outlined-basic"
//                       value={"Lifetime Fertility Cost Cap - Rx Only"}
//                       variant="outlined"
//                       inputProps={{ readOnly: true }}
//                     />
//                   </Tooltip>
//                 </FormControl>
//                 <FormControl fullWidth>
//                   <TextField
//                     id="BPCD123550"
//                     value={value64}
//                     label={"Amount"}
//                     onChange={handleValue64Change}
//                     variant="outlined"
//                   />
//                 </FormControl>
//               </>
//             )}
