export function convertMUIInputDateToGlobalDateString(dateInputValue) {
    if(dateInputValue) {
        const date = new Date(dateInputValue);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        return `${year}-${day}-${month}`;
    }

    return '';
}