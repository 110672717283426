import React from "react";
import {BsMagic,BsCardChecklist,BsCloudUpload, BsBell, BsBoxArrowInRight, BsBoxes, BsEject } from "react-icons/bs";
import {MdOutlineStorage} from "react-icons/md";
import { Link } from "react-router-dom";
import LinkCard from "../../components/LinkCard";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Paper from "@mui/material/Paper";


export default function IntegrateSelection({user, signOut}) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "60px" };

  return (
    <>
      {/* <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar /> */}
        <div className=" h-fit xl:mx-40 xl:mr-[25rem] 2xl:mr-[40rem] 2xl:ml-[25rem] ">
          <Paper className="mt-6 pb-14 pl-1" elevation={2}>
          <div className="w-[584px] h-[300px] grid grid-cols-2 justify-center items-center">
            <Link to="/integrate/createtemplates1">
              <LinkCard
                icon={<BsMagic style={iconStyles} />}
                linkText="Create Integration Template"
              />
            </Link>
            <Link to="/integrate/uploadtemplates2">
              <LinkCard
                icon={<BsCardChecklist style={iconStyles} />}
                linkText="Create E-Form Template"
              />
            </Link>
          </div>
          </Paper>
        </div>
      {/* </div> */}
    </>
  );
}
