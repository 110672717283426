import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { PiPlus } from "react-icons/pi";
import * as mutations from "../../src/graphql/mutations.js";
import { API } from "aws-amplify";
import Alert from "@mui/material/Alert";


export default function EmployeeEligibility({
  filteredList,
  getEmployeeEligibilityMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };
  const [masterJson, setMasterJson] = useState([]);
  const [wait, setWait] = useState(true);
  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);

  const getBenefitPlansAndPrograms = async (benefitProgram, benefitCounty) => {
    const filteredValues = await API.graphql({
      query: mutations.EmployeeEligibilityfilterBenefitPlanAndCountry,
      variables: {
        benefit_id: benefitProgram,
        country: benefitCounty,
      },
    });
    setMasterJson(
      filteredValues.data.EmployeeEligibilityfilterBenefitPlanAndCountry,
    );
    setWait(false);
  };

  useEffect(() => {
    const benefitProgram = JSON.parse(
      localStorage.getItem("benefitProgramFromBenefitPage1"),
    );
    const benefitCounty = JSON.parse(
      localStorage.getItem("contryFromBenefitPage1"),
    );

    getBenefitPlansAndPrograms(benefitProgram, benefitCounty);
  }, []);

  let test = [];
  for (let r of masterJson) {
    test.push(r.attribute_key_name_parent);
  }

  let uniqueArray = [];
  const test1 = new Map();
  for (const obj of test) {
    const objString = JSON.stringify(obj);
    if (!test1.has(objString)) {
      test1.set(objString, true);
      uniqueArray.push(obj);
    }
  }

  // console.log("ee", uniqueArray);
  const [date, setDate] = useState("");
  const [operator0, setOperator0] = useState("");
  const [operator1, setOperator1] = useState("");
  const [operator2, setOperator2] = useState("");
  const [operator3, setOperator3] = useState("");
  const [operator4, setOperator4] = useState("");
  const [operator5, setOperator5] = useState("");
  const [operator6, setOperator6] = useState("");
  const [operator7, setOperator7] = useState("");
  const [operator8, setOperator8] = useState("");
  const [operator9, setOperator9] = useState("");
  const [operator10, setOperator10] = useState("");
  const [operator11, setOperator11] = useState("");
  const [operator12, setOperator12] = useState("");
  const [value0, setValue0] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState("");
  const [value7, setValue7] = useState([]);
  const [value8, setValue8] = useState([]);
  const [value9, setValue9] = useState([]);
  const [value10, setValue10] = useState([]);
  const [value11, setValue11] = useState("");
  const [value12, setValue12] = useState("");

  const handleOperator0Change = (event) => {
    setOperator0(event.target.value);
  };
  const handleOperator1Change = (event) => {
    setOperator1(event.target.value);
  };
  const handleOperator2Change = (event) => {
    setOperator2(event.target.value);
  };
  const handleOperator3Change = (event) => {
    setOperator3(event.target.value);
  };
  const handleOperator4Change = (event) => {
    setOperator4(event.target.value);
  };
  const handleOperator5Change = (event) => {
    setOperator5(event.target.value);
  };
  const handleOperator6Change = (event) => {
    setOperator6(event.target.value);
  };
  const handleOperator7Change = (event) => {
    setOperator7(event.target.value);
  };
  const handleOperator8Change = (event) => {
    setOperator8(event.target.value);
  };
  const handleOperator9Change = (event) => {
    setOperator9(event.target.value);
  };
  const handleOperator10Change = (event) => {
    setOperator10(event.target.value);
  };
  const handleOperator11Change = (event) => {
    setOperator11(event.target.value);
  };
  const handleOperator12Change = (event) => {
    setOperator12(event.target.value);
  };
  const handleValue0Change = (event) => {
    setValue0(event.target.value);
  };
  const handleValue1Change = (event) => {
    setValue1(event.target.value);
  };
  const handleValue2Change = (event) => {
    setValue2(event.target.value);
  };
  const handleValue3Change = (event) => {
    setValue3(event.target.value);
  };
  const handleValue4Change = (event) => {
    setValue4(event.target.value);
  };
  const handleValue5Change = (event) => {
    setValue5(event.target.value);
  };
  const handleValue6Change = (event) => {
    setValue6(event.target.value);
  };
  const handleValue7Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue7(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue8Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue8(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue9Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue9(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue10Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue10(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue11Change = (event) => {
    setValue11(event.target.value);
  };
  const handleValue12Change = (event) => {
    setValue12(event.target.value);
  };

  let eligibilityRules = [];
  let integerList = [];
  let varcharList = [];
  let employeeTypeList = [];
  let genderList = [];
  let assignmentIndicatorList = [];
  let payFrequencyList = [];

  // for (let rules of filteredList) {
  //   if (rules.attribute_key_name_parent === "employee_eligibility") {
  //     let dict1 = {
  //       listValueKey: "",
  //       listValueDisplayName: "",
  //       listValueSortOrder: "",
  //     };
  //
  //     dict1.listValueKey = rules.attribute_list_valid_value_parent;
  //     dict1.listValueDisplayName =
  //       rules.attribute_list_value_display_name_parent;
  //     dict1.listValueSortOrder = rules.attribute_list_value_sort_order_parent;
  //     eligibilityRules.push(dict1);
  //   }
  // }

  for (let integer of filteredList) {
    if (integer.attribute_key_name_parent === "interger_eligibility_operator") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = integer.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        integer.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = integer.attribute_list_value_sort_order_child;
      integerList.push(dict1);
    }
  }

  for (let varchar of filteredList) {
    if (varchar.attribute_key_name_parent === "varchar_eligibility_operator") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = varchar.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        varchar.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = varchar.attribute_list_value_sort_order_child;
      varcharList.push(dict1);
    }
  }

  if (uniqueArray.includes("target_employee_class")) {
    for (let employeeType of filteredList) {
      if (employeeType.attribute_key_name_parent === "target_employee_class") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };
        dict1.listValueKey = employeeType.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          employeeType.attribute_list_value_display_name_child;
        dict1.listValueSortOrder =
          employeeType.attribute_list_value_sort_order_child;
        employeeTypeList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("target_gender")) {
    for (let gender of filteredList) {
      if (gender.attribute_key_name_parent === "target_gender") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };
        dict1.listValueKey = gender.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          gender.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = gender.attribute_list_value_sort_order_child;
        genderList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("target_assignment_indicator")) {
    for (let assignmentIndicator of filteredList) {
      if (
        assignmentIndicator.attribute_key_name_parent ===
        "target_assignment_indicator"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };
        dict1.listValueKey =
          assignmentIndicator.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          assignmentIndicator.attribute_list_value_display_name_child;
        dict1.listValueSortOrder =
          assignmentIndicator.attribute_list_value_sort_order_child;
        assignmentIndicatorList.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("target_pay_frequency")) {
    for (let payFrequency of filteredList) {
      if (payFrequency.attribute_key_name_parent === "target_pay_frequency") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };
        dict1.listValueKey = payFrequency.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          payFrequency.attribute_list_value_display_name_child;
        dict1.listValueSortOrder =
          payFrequency.attribute_list_value_sort_order_child;
        payFrequencyList.push(dict1);
      }
    }
  }

  eligibilityRules.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  integerList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  varcharList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  employeeTypeList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  genderList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  assignmentIndicatorList.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  payFrequencyList.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);

  // console.log(filteredList);
  // console.log("ade", eligibilityRules);
  // console.log("iade", integerList);
  // console.log("oade", varcharList);
  // console.log("oade", dualCoverList);
  // console.log("oade", genderList);

  const finalList = [
    ["citizenship_country", operator0, value0],
    ["days_from_hire_date", operator1, value1],
    ["department_id", operator2, value2],
    ["entity", operator3, value3],
    ["job_level", operator4, value4],
    ["maximum_age", operator5, value5],
    ["minimum_age", operator6, value6],

    ["target_assignment_indicator", operator7, value7.join("+")],

    ["target_employee_class", operator8, value8.join("+")],
    ["target_gender", operator9, value9.join("+")],
    ["target_pay_frequency", operator10, value10.join("+")],
    ["union_code", operator11, value11],
    ["work_building", operator12, value12],
  ];

  let finalDict = {
    date: `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
      date.$D > 9 ? date.$D : `0${date.$D}`
    }`,
  };

  for (var er of finalList) {
    finalDict[er[0]] = er[1] + er[2];
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getEmployeeEligibilityMappedValues(
        finalDict,
        `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
          date.$D > 9 ? date.$D : `0${date.$D}`
        }`,
      );
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Employee Eligibility</Typography>
      </AccordionSummary>

      <AccordionDetails className="mx-5 mb-3 flex justify-between">
        <div>
          {alert === "success" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="success">
              Success! The details provided have been stored
            </Alert>
          ) : alert === "error" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="error">
              Please, Fill all the fields
            </Alert>
          ) : (
            ""
          )}
        </div>
      </AccordionDetails>

      <AccordionDetails className="grid grid-cols-3 gap-8 mx-5 mb-4">
        <FormControl name="fullWidth2" fullWidth>
          <DatePicker
            label="As Of Date"
            name="date"
            value={date}
            onChange={(date) => setDate(date)}
            format="YYYY-DD-MM"
          />
        </FormControl>

        <FormControl name="fullWidth3" fullWidth></FormControl>
        <FormControl name="fullWidth4" fullWidth></FormControl>

        {uniqueArray.includes("citizenship_country") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Citizenship Country"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Citizenship Country"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator0}
                label="Operator"
                onChange={handleOperator0Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value0}
                label="Value"
                onChange={handleValue0Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("days_from_hire_date") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Days from Hire"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Days from Hire"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator1}
                label="Operator"
                onChange={handleOperator1Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value1}
                label="Value"
                onChange={handleValue1Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("department_id") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Department ID"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Department ID"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator2}
                label="Operator"
                onChange={handleOperator2Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value2}
                label="Value"
                onChange={handleValue2Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("entity") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Entity"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Entity"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator3}
                label="Operator"
                onChange={handleOperator3Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value3}
                label="Value"
                onChange={handleValue3Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("job_level") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Job Level"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Job Level"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator4}
                label="Operator"
                onChange={handleOperator4Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value4}
                label="Value"
                onChange={handleValue4Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("maximum_age") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Max Age"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Max Age"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator5}
                label="Operator"
                onChange={handleOperator5Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value5}
                label="Value"
                onChange={handleValue5Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("minimum_age") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Min Age"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Min Age"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator6}
                label="Operator"
                onChange={handleOperator6Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value6}
                label="Value"
                onChange={handleValue6Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("target_assignment_indicator") && (
          <>
            {" "}
            <FormControl name="fullWidth14" fullWidth>
              <Tooltip title={"Assignment Type"}>
                <TextField
                  label="Eligibility Rule"
                  id="E5"
                  value={"Assignment Type"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth15" fullWidth>
              <InputLabel id="Eligibility12">Operator</InputLabel>
              <Select
                labelId="Eligibility13"
                id="Eligibility14"
                value={operator7}
                label="Operator"
                onChange={handleOperator7Change}
              >
                {varcharList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth16" fullWidth>
              <InputLabel id="Eligibility15">Value</InputLabel>
              <Select
                labelId="Eligibility16"
                id="Eligibility17"
                value={value7}
                label="Value"
                multiple
                onChange={handleValue7Change}
              >
                {assignmentIndicatorList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        {uniqueArray.includes("target_employee_class") && (
          <>
            {" "}
            <FormControl name="fullWidth14" fullWidth>
              <Tooltip title={"Employee Class"}>
                <TextField
                  label="Eligibility Rule"
                  id="E5"
                  value={"Employee Class"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth15" fullWidth>
              <InputLabel id="Eligibility12">Operator</InputLabel>
              <Select
                labelId="Eligibility13"
                id="Eligibility14"
                value={operator8}
                label="Operator"
                onChange={handleOperator8Change}
              >
                {varcharList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth16" fullWidth>
              <InputLabel id="Eligibility15">Value</InputLabel>
              <Select
                labelId="Eligibility16"
                id="Eligibility17"
                value={value8}
                label="Value"
                multiple
                onChange={handleValue8Change}
              >
                {employeeTypeList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        {uniqueArray.includes("target_gender") && (
          <>
            {" "}
            <FormControl name="fullWidth14" fullWidth>
              <Tooltip title={"Gender"}>
                <TextField
                  label="Eligibility Rule"
                  id="E5"
                  value={"Gender"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth15" fullWidth>
              <InputLabel id="Eligibility12">Operator</InputLabel>
              <Select
                labelId="Eligibility13"
                id="Eligibility14"
                value={operator9}
                label="Operator"
                onChange={handleOperator9Change}
              >
                {varcharList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth16" fullWidth>
              <InputLabel id="Eligibility15">Value</InputLabel>
              <Select
                labelId="Eligibility16"
                id="Eligibility17"
                value={value9}
                label="Value"
                multiple
                onChange={handleValue9Change}
              >
                {genderList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        {uniqueArray.includes("target_pay_frequency") && (
          <>
            {" "}
            <FormControl name="fullWidth14" fullWidth>
              <Tooltip title={"Pay Frequency"}>
                <TextField
                  label="Eligibility Rule"
                  id="E5"
                  value={"Pay Frequency"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth15" fullWidth>
              <InputLabel id="Eligibility12">Operator</InputLabel>
              <Select
                labelId="Eligibility13"
                id="Eligibility14"
                value={operator10}
                label="Operator"
                onChange={handleOperator10Change}
              >
                {varcharList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth16" fullWidth>
              <InputLabel id="Eligibility15">Value</InputLabel>
              <Select
                labelId="Eligibility16"
                id="Eligibility17"
                value={value10}
                label="Value"
                multiple
                onChange={handleValue10Change}
              >
                {payFrequencyList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        {uniqueArray.includes("union_code") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Union Code"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Union Code"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator11}
                label="Operator"
                onChange={handleOperator11Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value11}
                label="Value"
                onChange={handleValue11Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}

        {uniqueArray.includes("work_building") && (
          <>
            {" "}
            <FormControl name="fullWidth5" fullWidth>
              <Tooltip title={"Work Building"}>
                <TextField
                  label="Eligibility Rule"
                  id="E2"
                  value={"Work Building"}
                  variant="outlined"
                  inputProps={{ readOnly: true }}
                />
              </Tooltip>
            </FormControl>
            <FormControl name="fullWidth6" fullWidth>
              <InputLabel id="Eligibility3">Operator</InputLabel>
              <Select
                labelId="Eligibility4"
                id="Eligibility5"
                value={operator12}
                label="Operator"
                onChange={handleOperator12Change}
              >
                {integerList.map((item, key) => (
                  <MenuItem value={item.listValueKey} key={key}>
                    {item.listValueDisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl name="fullWidth7" fullWidth>
              <TextField
                id="EmployeeEligibility4"
                value={value12}
                label="Value"
                onChange={handleValue12Change}
                variant="outlined"
              />
            </FormControl>
          </>
        )}
        <FormControl fullWidth></FormControl>
        <FormControl fullWidth></FormControl>
        <div className="mt-5 w-full flex justify-end items-center">
          <button
            type="submit"
            className="ml-5 bg-[#0000A3] py-[6px]  shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg   px-6  text-white"
            onClick={(e) => {
              handleSave(e);
            }}
          >
            Save
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

       // <div className="pt-5">
       //    {" "}
       //    {finalList.map((item, key) => (
       //      <div key={key}>
       //        {item[0]} : {item[1]}, {item[2]}
       //      </div>
       //    ))}
       //  </div>

// <div className="pt-5">
//   {" "}
//   {JSON.stringify(
//     [
//       [date.$y, date.$M + 1, date.$D],
//       [eligibilityRules[0].listValueDisplayName, operator0, value0],
//       [eligibilityRules[1].listValueDisplayName, operator1, value1],
//       [eligibilityRules[2].listValueDisplayName, operator2, value2],
//       [eligibilityRules[3].listValueDisplayName, operator3, value3],
//       [eligibilityRules[4].listValueDisplayName, operator4, value4],
//       [eligibilityRules[5].listValueDisplayName, operator5, value5],
//       [eligibilityRules[6].listValueDisplayName, operator6, value6],
//       [eligibilityRules[7].listValueDisplayName, operator7, value7],
//       [eligibilityRules[8].listValueDisplayName, operator8, value8],
//       [eligibilityRules[9].listValueDisplayName, operator9, value9],
//       [eligibilityRules[10].listValueDisplayName, operator10, value10],
//       [eligibilityRules[11].listValueDisplayName, operator11, value11],
//       [eligibilityRules[12].listValueDisplayName, operator12, value12],
//     ],
//     null,
//     "    ",
//   )}
// </div>
