import { DataGrid } from "@mui/x-data-grid";
import { React, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { PiNumberCircleTwoFill, PiCheckCircleFill } from "react-icons/pi";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import Alerts from "../../components/Alerts.jsx";
import * as mutations from "../../graphql/mutations";
import InsuredBenefitPlanOverview from "../../components/InsuredBenefitPlanOverview.jsx";
import USDentalAndVisionSTDOverview from "../../components/USDentalAndVisionSTDOverview.jsx";
import SavingsPlanOverview from "../../components/SavingsPlanOverview.jsx";
import USMedicalPlanOverview from "../../components/USMedicalPlanOverview.jsx";
import PayCheckContributions from "../../components/PayCheckContributions.jsx";
import UsageBasedCostSharing from "../../components/UsageBasedCostSharing.jsx";
import PharmacyUsageBasedCostShare from "../../components/PharmacyUsageBasedCostShare.jsx";
import EmployeeEligibility from "../../components/EmployeeEligibility.jsx";
import AdultDependentEligibility from "../../components/AdultDependentEligibility.jsx";
import ChildDependentEligibility from "../../components/ChildDependentEligibility.jsx";
import AnnualHealthReimbursementAccounts from "../../components/AnnualHealthReimbursementAccounts.jsx";
import MonthlyHealthSavingsAccountContributions from "../../components/MonthlyHealthSavingsAccountContributions.jsx";
import BenefitPlanCoverageDetails from "../../components/BenefitPlanCoverageDetails.jsx";
import PolicyRiders from "../../components/PolicyRiders.jsx";
import FileUploadAccordion from "../../components/FileUploadAccordion.jsx";
import Paper from "@mui/material/Paper";

export default function CreateBenefitS2({ user, signOut }) {
  const [runGetBenefitTableData, setRunGetBenefitTableData] = useState(false);
  const [masterJson, setMasterJson] = useState([]);
  const [wait, setWait] = useState(true);
  const [alert, setAlert] = useState("");
  const [benefitPlanId, setBenefitPlanId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const benefitProgram = JSON.parse(
    localStorage.getItem("benefitProgramFromBenefitPage1"),
  );
  const benefitCounty = JSON.parse(
    localStorage.getItem("contryFromBenefitPage1"),
  );
  const companyName = JSON.parse(
    localStorage.getItem("companyFromBenefitPage1"),
  );
  const planName = JSON.parse(localStorage.getItem("planNameFromBenefitPage1"));

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };
  let numberStylesOn = { padding: "2px", color: "#0000A3", fontSize: "30px" };

  // data filtering starts
  const getBenefitPlansAndPrograms = async (benefitProgram, benefitCounty) => {
    const filteredValues = await API.graphql({
      query: mutations.filterBenefitPlanAndCountry,
      variables: {
        benefit_id: benefitProgram,
        country: benefitCounty,
      },
    });
    setMasterJson(filteredValues.data.filterBenefitPlanAndCountry);
    setWait(false);
  };

  const getBenefitTableData = async () => {
    if (benefitPlanId.length > 0) {
      // console.log("inside", benefitPlanId);
      const filteredValues = await API.graphql({
        query: mutations.filterBenefitFeature,
        variables: {
          benefit_plan_id: benefitPlanId,
        },
      });
      setTableData(filteredValues.data.filterBenefitFeature);
      setWait(false);
    }
  };
  console.log(tableData);

  if (runGetBenefitTableData) {
    getBenefitTableData();
    setTimeout(() => {
      setRunGetBenefitTableData(false);
    }, 5000);
  }

  const uniqueBenefitPlanId = async (benefitProgram, benefitCounty) => {
    const filteredValues = await API.graphql({
      query: queries.generateUniqueBenefitPlanID,
    });
    setBenefitPlanId(filteredValues.data.generateUniqueBenefitPlanID);
    setWait(false);
  };
  // console.log(benefitPlanId)

  useEffect(() => {
    // const benefitProgram = JSON.parse(
    //   localStorage.getItem("benefitProgramFromBenefitPage1")
    // );
    // const benefitCounty = JSON.parse(
    //   localStorage.getItem("contryFromBenefitPage1")
    // );
    uniqueBenefitPlanId();
    getBenefitPlansAndPrograms(benefitProgram, benefitCounty);
    getBenefitTableData();
  }, []);
  // console.log("bd", benefitPlanId);

  let componentsFromMasterJson = [];
  for (let ibpo of masterJson) {
    componentsFromMasterJson.push(ibpo.component_type);
  }

  const uniqueArray = [];
  const seenItems = {};

  for (const item of componentsFromMasterJson) {
    if (!seenItems[item]) {
      uniqueArray.push(item);
      seenItems[item] = true;
    }
  }
  // console.log(uniqueArray);

  let ibpoPropList = [];
  for (let ibpoProp of masterJson) {
    if (ibpoProp.component_type === "insuredbenefitsplanoverview") {
      ibpoPropList.push(ibpoProp);
    }
  }

  let usmpoPropList = [];
  for (let usmpo of masterJson) {
    if (usmpo.component_type === "usmedicalplanoverview") {
      usmpoPropList.push(usmpo);
    }
  }

  let usdvstdPropList = [];
  for (let usdvstd of masterJson) {
    if (usdvstd.component_type === "usdental_vision_std_overview") {
      usdvstdPropList.push(usdvstd);
    }
  }

  let spoPropList = [];
  for (let spo of masterJson) {
    if (spo.component_type === "savings_plan_overview") {
      spoPropList.push(spo);
    }
  }

  let adePropList = [];
  for (let ade of masterJson) {
    if (ade.component_type === "adult_dependent_eligibility") {
      adePropList.push(ade);
    }
  }

  let cdePropList = [];
  for (let cde of masterJson) {
    if (cde.component_type === "child_dependent_eligibility") {
      cdePropList.push(cde);
    }
  }

  let eePropList = [];
  for (let ee of masterJson) {
    if (ee.component_type === "employee_eligibility") {
      eePropList.push(ee);
    }
  }

  let pccPropList = [];
  for (let pcc of masterJson) {
    if (pcc.component_type === "planpaycheckcontributions") {
      pccPropList.push(pcc);
    }
  }

  let ahraPropList = [];
  for (let ahra of masterJson) {
    if (ahra.component_type === "usmedicalplanhraaccounts") {
      ahraPropList.push(ahra);
    }
  }

  let mhsacPropList = [];
  for (let mhsac of masterJson) {
    if (mhsac.component_type === "usmedicalplanhsaaccounts") {
      mhsacPropList.push(mhsac);
    }
  }

  let ubcsPropList = [];
  for (let ubcs of masterJson) {
    if (ubcs.component_type === "usagebasedcostsharing") {
      ubcsPropList.push(ubcs);
    }
  }

  let pubcsPropList = [];
  for (let pubcs of masterJson) {
    if (pubcs.component_type === "pharmacyusagebasedcostsharing") {
      pubcsPropList.push(pubcs);
    }
  }

  let bpcdPropList = [];
  for (let bpcd of masterJson) {
    if (bpcd.component_type === "benefitplancoverageamount") {
      bpcdPropList.push(bpcd);
    }
  }

  let pcPropList = [];
  for (let pc of masterJson) {
    if (pc.component_type === "policy_riders") {
      pcPropList.push(pc);
    }
  }

  const [usmpo1, setusmpo1] = useState([]);
  const [ibpo2, setibpo2] = useState([]);
  const [usdavstdo3, setusdavstdo3] = useState([]);
  const [spo4, setspo4] = useState([]);
  const [ee5, setee5] = useState([]);
  const [ade6, setade6] = useState([]);
  const [cde7, setcde7] = useState([]);
  const [pcc8, setpcc8] = useState([]);
  const [ahra9, setahra9] = useState([]);
  const [mhsa10, setmhsa10] = useState([]);
  const [ubcs11, setubcs11] = useState([]);
  const [pubcs12, setpubcs12] = useState([]);
  const [bpcd13, setbpcd13] = useState([]);
  const [pr14, setpr14] = useState([]);

  const columns = [
    {
      field: "as_of_date",
      renderHeader: () => <strong>{"Effective Date"}</strong>,
      width: 110,
    },
    {
      field: "attribute_category",
      renderHeader: () => <strong>{"Category"}</strong>,
      width: 180,
    },
    {
      field: "attribute_name",
      renderHeader: () => <strong>{"Name"}</strong>,
      width: 180,
    },
    {
      field: "attribute_value",
      renderHeader: () => <strong>{"Value"}</strong>,
      width: 200,
    },
  ];
  console.log("bpi", tableData);

  const getUSMedicalPlanOverviewMappedValues = async (list, date) => {
    // console.log("usmedical", benefitPlanId);
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    let json_data = list;
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));

    console.log(final_json);

    setusmpo1(list);

    getBenefitTableData();
  };

  const getInsuredBenefitPlanOverviewMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };

    let json_data = list;
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));

    console.log(final_json);

    setibpo2(list);
    getBenefitTableData();
  };
  const getUSDentalAndVisionSTDOverviewMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    console.log(list);

    let json_data = list;
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setusdavstdo3(list);

    getBenefitTableData();
  };

  const getSavingsPlanOverviewMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    console.log(list);

    let json_data = list;
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setspo4(list);

    getBenefitTableData();
  };

  const getEmployeeEligibilityMappedValues = async (list, date) => {
    console.log("insu", benefitPlanId);
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    let json_data = { employeeEligibility: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setee5(list);

    getBenefitTableData();
  };

  const getAdultDependentEligibilityMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    // console.log(list);
    let json_data = { adultDependentEligibility: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setade6(list);

    getBenefitTableData();
  };
  const getChildDependentEligibilityMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    // console.log(list);

    let json_data = { adultDependentEligibility: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setcde7(list);

    getBenefitTableData();
  };

  const getPayCheckContributionsMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };

    // let json_data = {list};
    let json_data = { payCheckContributions: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setpcc8(list);

    getBenefitTableData();
  };

  const getAnnualHealthReimbursementAccountsMappedValues = async (
    list,
    date,
  ) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    console.log(list);

    let json_data = { annualHealthReimbursementAccounts: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setahra9(list);

    getBenefitTableData();
  };

  const getMonthlyHealthSavingsAccountContributionsMappedValues = async (
    list,
    date,
  ) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };

    let json_data = { monthlyHealthSavingsAccountContributions: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setmhsa10(list);

    getBenefitTableData();
  };

  const getUsageBasedCostSharingMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };

    console.log(list);

    let json_data = { usageBasedCostSharing: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }
    console.log(final_json);

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      // variables: { benefit_details_data: final_json },
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log("ubcs", final_json);
    setubcs11(list);

    getBenefitTableData();
  };

  const getPharmacyUsageBasedCostShareMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    console.log(list);

    let json_data = { pharmacyUsageBasedCostShare: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setpubcs12(list);

    getBenefitTableData();
  };

  const getBenefitPlanCoverageDetailsMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    console.log(list);

    let json_data = { benefitPlanCoverageDetails: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setbpcd13(list);

    getBenefitTableData();
  };

  const getPolicyRidersMappedValues = async (list, date) => {
    let target_json = {
      as_of_date: date,
      benefit_plan_id: benefitPlanId,
      benefit_plan_display_name: planName,
      benefit_status: "A",
      benefit_id: benefitProgram,
      company_id: companyName,
      country: benefitCounty,
      userid: user,
      operator_id: "",
    };
    console.log(list);

    let json_data = { policyRiders: list };
    target_json["attribute_category"] = Object.keys(json_data)[0];

    var accordion_data = json_data[target_json["attribute_category"]];

    var accordion_data_keys = Object.keys(accordion_data);

    var final_json = [];

    for (const i of accordion_data_keys) {
      var temp_json = { ...target_json };
      temp_json["attribute_key"] = i;

      if (accordion_data[i].includes("<>")) {
        temp_json["operator_id"] = "<>";
        temp_json["attribute_value"] = accordion_data[i].replace("<>", "");
      } else if (accordion_data[i].includes("<=")) {
        temp_json["operator_id"] = "<=";
        temp_json["attribute_value"] = accordion_data[i].replace("<=", "");
      } else if (accordion_data[i].includes(">=")) {
        temp_json["operator_id"] = ">=";
        temp_json["attribute_value"] = accordion_data[i].replace(">=", "");
      } else if (accordion_data[i].includes(">")) {
        temp_json["operator_id"] = ">";
        temp_json["attribute_value"] = accordion_data[i].replace(">", "");
      } else if (accordion_data[i].includes("<")) {
        temp_json["operator_id"] = "<";
        temp_json["attribute_value"] = accordion_data[i].replace("<", "");
      } else if (accordion_data[i].includes("=")) {
        temp_json["operator_id"] = "=";
        temp_json["attribute_value"] = accordion_data[i].replace("=", "");
      } else {
        temp_json["attribute_value"] = accordion_data[i];
      }

      final_json.push(temp_json);
    }

    const mutationPush = await API.graphql({
      query: mutations.createBenefitDetails,
      variables: { benefit_details_data: final_json },
    }).then((data) => console.log(data));
    console.log(final_json);
    setpr14(list);

    getBenefitTableData();
  };
  console.log(runGetBenefitTableData);
  console.log(selectedRows);
  console.log(tableData[selectedRows - 1]);

  const handleOpenDocumentInNewPage = (e) => {
    e.preventDefault();
    window.open(tableData[selectedRows - 1]);
  };

  let finalList = [
    usmpo1,
    ibpo2,
    usdavstdo3,
    spo4,
    ee5,
    ade6,
    cde7,
    pcc8,
    ahra9,
    mhsa10,
    ubcs11,
    pubcs12,
    bpcd13,
    pr14,
  ];

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
          .save:{box-shadow: 0px 10px 15px 19px rgba(0,0,0,0.1);}`}</style>
      {/* <Navbar user={user} signOut={signOut} />
      <div className="flex">
        <Sidebar /> */}
        <div className="2xl:ml-[11rem] ml-4">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/benefit" className="px-2">
              Benefit
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/design/createbenefits1">
              Benefit Profile
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/design/createbenefits2">
              Additional Details
            </Link>
          </div>

          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <MdOutlineAutoFixHigh style={iconStyles} />
              <p className="text-3xl mx-1">Create Benefit</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="'Success! The details provided have been stored'"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Try again later" />
            ) : (
              ""
            )}
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex">
            {/*steps*/}
            <div className="ml-3">
              <div className="flex items-center space-x-2">
                <PiCheckCircleFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 1</p>
                  <p className="text-md pb-1 ">Create Benefit Profile</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiNumberCircleTwoFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 2</p>
                  <p className=" font-bold text-md pb-1 ">Additional Details</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>{" "}
              </div>
            </div>

            {/* form*/}
            <div className="mt-4 w-[900px] h-3/4">
              <form className="ml-20">
                {wait ? (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={wait}
                  >
                    <CircularProgress sx={{ color: "#DB2748" }} />{" "}
                  </Backdrop>
                ) : (
                  <>
                    <div className="mb-8">
                      <Paper elevation={2}>
                        <div className="text-xl font-bold flex justify-center py-1">
                          Benefit Features
                        </div>
                        <div className="text-md my-4 mx-2 text-gray-500 pb-1">
                        Record features for this plan. Add values from the 'Benefit Library' below and click 'Save' to add this to the profile.  If you wish to delete a feature, go to the <Link className="font-bold text-md pb-1 text-blue-600 underline cursor-pointer" to="/design/benefitinventory">Benefit Profile</Link> page, select the benefit and select 'update'. 
                        </div>
                        {/* <div className="flex justify-end space-x-2 pb-3 mx-1 mr-[1rem]">                         
                          <button
                            disabled={selectedRows.length > 0 ? false : true}
                            onClick={handleOpenDocumentInNewPage}
                            className={
                              selectedRows.length > 0
                                ? "bg-[#0000A3] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                                : "bg-gray-500 rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white disabled"
                            }
                          >
                            <a href=""></a>
                            Document In New Page
                          </button>
                          <button
                            disabled={selectedRows.length > 0 ? false : true}
                            // onClick={handleDownloadFile}
                            className={
                              selectedRows.length > 0
                                ? "bg-[#0000A3] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                                : "bg-gray-500 rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white disabled"
                            }
                          >
                            Delete
                          </button>
                        </div> */}
                        <div className="h-[300px]">
                          <div className="h-[300px] w-[820px]">
                            <DataGrid
                              style={{ overflowY: "scroll" }}
                              // rows={rows}
                              rows={tableData.map((item, index) => ({
                                id: index + 1,
                                ...item,
                              }))}
                              // getRowId={(row) => ({
                              //   id: row.benefit_plan_id + row.attribute_name,
                              // })}
                              // rows={tableData}
                              // checkboxSelection
                              columns={columns}
                              onRowSelectionModelChange={(row) => {
                                setSelectedRows(row);
                                console.log(row);
                              }}
                              hideFooter
                              hideFooterPagination
                              hideFooterSelectedRowCount
                            />
                          </div>
                        </div>
                      </Paper>
                    </div>

                    <Paper elevation={2}>
                      <div className="mx-2 my-4">
                        <div className="text-xl font-bold flex justify-center py-1 pb-3">
                          Benefit Library
                        </div>
                        {uniqueArray.includes("usmedicalplanoverview") && (
                          <USMedicalPlanOverview
                            filteredList={usmpoPropList}
                            getUSMedicalPlanOverviewMappedValues={
                              getUSMedicalPlanOverviewMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes(
                          "insuredbenefitsplanoverview",
                        ) && (
                          <InsuredBenefitPlanOverview
                            filteredList={ibpoPropList}
                            getInsuredBenefitPlanOverviewMappedValues={
                              getInsuredBenefitPlanOverviewMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes(
                          "usdental_vision_std_overview",
                        ) && (
                          <USDentalAndVisionSTDOverview
                            filteredList={usdvstdPropList}
                            getUSDentalAndVisionSTDOverviewMappedValues={
                              getUSDentalAndVisionSTDOverviewMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes("savings_plan_overview") && (
                          <SavingsPlanOverview
                            filteredList={spoPropList}
                            getSavingsPlanOverviewMappedValues={
                              getSavingsPlanOverviewMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes("employee_eligibility") && (
                          <EmployeeEligibility
                            filteredList={eePropList}
                            getEmployeeEligibilityMappedValues={
                              getEmployeeEligibilityMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes(
                          "adult_dependent_eligibility",
                        ) && (
                          <AdultDependentEligibility
                            filteredList={adePropList}
                            getAdultDependentEligibilityMappedValues={
                              getAdultDependentEligibilityMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes(
                          "child_dependent_eligibility",
                        ) && (
                          <ChildDependentEligibility
                            filteredList={cdePropList}
                            getChildDependentEligibilityMappedValues={
                              getChildDependentEligibilityMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes("planpaycheckcontributions") && (
                          <PayCheckContributions
                            filteredList={pccPropList}
                            getPayCheckContributionsMappedValues={
                              getPayCheckContributionsMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes("usmedicalplanhraaccounts") && (
                          <AnnualHealthReimbursementAccounts
                            filteredList={ahraPropList}
                            getAnnualHealthReimbursementAccountsMappedValues={
                              getAnnualHealthReimbursementAccountsMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes("usmedicalplanhsaaccounts") && (
                          <MonthlyHealthSavingsAccountContributions
                            filteredList={mhsacPropList}
                            getMonthlyHealthSavingsAccountContributionsMappedValues={
                              getMonthlyHealthSavingsAccountContributionsMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes("usagebasedcostsharing") && (
                          <UsageBasedCostSharing
                            filteredList={ubcsPropList}
                            getUsageBasedCostSharingMappedValues={
                              getUsageBasedCostSharingMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes(
                          "pharmacyusagebasedcostsharing",
                        ) && (
                          <PharmacyUsageBasedCostShare
                            filteredList={pubcsPropList}
                            getPharmacyUsageBasedCostShareMappedValues={
                              getPharmacyUsageBasedCostShareMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes("benefitplancoverageamount") && (
                          <BenefitPlanCoverageDetails
                            filteredList={bpcdPropList}
                            getBenefitPlanCoverageDetailsMappedValues={
                              getBenefitPlanCoverageDetailsMappedValues
                            }
                          />
                        )}
                        {uniqueArray.includes("policy_riders") && (
                          <PolicyRiders
                            filteredList={pcPropList}
                            getPolicyRidersMappedValues={
                              getPolicyRidersMappedValues
                            }
                          />
                        )}
                        <FileUploadAccordion
                          benefitPlanId={benefitPlanId}
                          setRunGetBenefitTableData={setRunGetBenefitTableData}
                          getBenefitTableData={getBenefitTableData}
                        />
                      </div>
                    </Paper>

                    <div className="h-1" />

                    {/**/}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}

// <button
//   type="button"
//   className="ml-5 bg-[#0000A3] py-[6px] shadow-2xl rounded-sm px-6  text-white"
//   onClick={(e) => {
//     handleUpload(e);
//   }}
//   // disabled={true}
// >
//   Save
// </button>

// {uniqueArray.includes("usmedicalplanoverview") && (
//   <USMedicalPlanOverview filteredList={usmpoPropList} getUSMedicalPlanOverviewMappedValues={getUSMedicalPlanOverviewMappedValues} />
// )}
// {uniqueArray.includes("insuredbenefitsplanoverview") && (
//   <InsuredBenefitPlanOverview filteredList={ibpoPropList} getInsuredBenefitPlanOverviewMappedValues={getInsuredBenefitPlanOverviewMappedValues}/>
// )}
// {uniqueArray.includes("usdental_vision_std_overview") && (
//   <USDentalAndVisionSTDOverview
//     filteredList={usdvstdPropList}
//     getUSDentalAndVisionSTDOverviewMappedValues={getUSDentalAndVisionSTDOverviewMappedValues}
//   />
// )}
// {uniqueArray.includes("savings_plan_overview") && (
//   <SavingsPlanOverview filteredList={spoPropList} getSavingsPlanOverviewMappedValues={getSavingsPlanOverviewMappedValues} />
// )}
// {uniqueArray.includes("employee_eligibility") && (
//   <EmployeeEligibility filteredList={eePropList} getEmployeeEligibilityMappedValues={getEmployeeEligibilityMappedValues} />
// )}
// {uniqueArray.includes("adult_dependent_eligibility") && (
//   <AdultDependentEligibility filteredList={adePropList} getAdultDependentEligibilityMappedValues={getAdultDependentEligibilityMappedValues}/>
// )}
// {uniqueArray.includes("child_dependent_eligibility") && (
//   <ChildDependentEligibility filteredList={cdePropList} getChildDependentEligibilityMappedValues={getChildDependentEligibilityMappedValues} />
// )}
// {uniqueArray.includes("planpaycheckcontributions") && (
//   <PayCheckContributions filteredList={pccPropList} getPayCheckContributionsMappedValues={getPayCheckContributionsMappedValues} />
// )}
// {uniqueArray.includes("usmedicalplanhraaccounts") && (
//   <AnnualHealthReimbursementAccounts
//     filteredList={ahraPropList}
//     getAnnualHealthReimbursementAccountsMappedValues={getAnnualHealthReimbursementAccountsMappedValues}
//   />
// )}
// {uniqueArray.includes("usmedicalplanhsaaccounts") && (
//   <MonthlyHealthSavingsAccountContributions
//     filteredList={mhsacPropList}
//     getMonthlyHealthSavingsAccountContributionsMappedValues={getMonthlyHealthSavingsAccountContributionsMappedValues}
//   />
// )}
// {uniqueArray.includes("usagebasedcostsharing") && (
// <UsageBasedCostSharing filteredList={ubcsPropList} getUsageBasedCostSharingMappedValues={getUsageBasedCostSharingMappedValues} />
// )}
// {uniqueArray.includes("pharmacyusagebasedcostsharing") && (
//   <PharmacyUsageBasedCostShare
//     filteredList={pubcsPropList}
//     getPharmacyUsageBasedCostShareMappedValues={getPharmacyUsageBasedCostShareMappedValues}
//   />
// )}
// {uniqueArray.includes("benefitplancoverageamount") && (
//   <BenefitPlanCoverageDetails filteredList={bpcdPropList} getBenefitPlanCoverageDetailsMappedValues={getBenefitPlanCoverageDetailsMappedValues}/>
// )}
// {uniqueArray.includes("policy_riders") && (
//   <PolicyRiders filteredList={pcPropList} getPolicyRidersMappedValues={getPolicyRidersMappedValues}/>
// )}
