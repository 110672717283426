import { React, useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import ColMap from "../../components/ColMap.jsx";
import { Link, useNavigate } from "react-router-dom";
import { BsBoxArrowInRight } from "react-icons/bs";
import Alerts from "../../components/Alerts";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { IContext } from "../../context/IntegrateContext";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";

export default function CreateTemplateS3({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [masterJson, setMasterJson] = useState([]);
  const [createTemplateS1Values, setCreateTemplateS1Values] = useState([]);
  const { modifyData, getS3DropDownData, s4ColumnData, s4ItemData } =
    IContext();
  const [finalColumnMapData, setFinalColumnMapData] = useState([]);
  const appendToFinalColumnMapData = (newValue) => {
    setFinalColumnMapData((prevArray) => [...prevArray, newValue]);
  };

  // console.log("right side values", getS3DropDownData);
  let navigate = useNavigate();

  useEffect(() => {
    const getCreateTemplate1Selection = async () => {
      const createTemplate2Selection = await API.graphql({
        query: queries.readIntegrateAttributes,
      });
      setMasterJson(createTemplate2Selection.data.readIntegrateAttributes);
      setWait(false);
    };
    getCreateTemplate1Selection();
    
    // if (columns.length > 0) {
    //   setWait(false);
    // }

    const unParsedFormDatas = localStorage.getItem("formValues");
    const formData = JSON.parse(unParsedFormDatas);
    setCreateTemplateS1Values(formData);
  }, []);

  const templateType = JSON.parse(localStorage.getItem("formTemplateType"));
  const templateName = JSON.parse(localStorage.getItem("formTemplateName"));
  const country = JSON.parse(localStorage.getItem("formCountry"));
  const companyName = JSON.parse(localStorage.getItem("formCompanyName"));
  // console.log([templateType, templateName, country, companyName]);
  let cNames = [];
  let test = [];
  let columnKeys = [];

  for (var c of masterJson) {
    if (
      c.component_type === "column_map" &&
      c.template_type_id === templateType
    ) {
      test.push(c);
      let tempCName = c.attribute_list_value_display_name;
      let tempCKey = c.attribute_key_name;
      cNames.push(tempCName);
      columnKeys.push(tempCKey);
    } else {
    }
  }
  // console.log("full left", test);

  let columns = cNames;

  var tempColumnData = {};
  
  // const getMappedOption = (optionNumber, optionValue) => {
  //   let start = 0;
  //   for (var key in tempColumnData) {
  //     if (optionNumber === tempColumnData[key] || optionNumber === start) {
  //       tempColumnData[key] = optionValue;
  //     }
  //     start++;
  //   }
  //   console.log("temp col data")
  //   console.log(tempColumnData);
  // };

  const getMappedOption = (optionNumber, optionValue) => {
  // Loop through the keys of tempColumnData (assuming it's an object)

  Object.keys(tempColumnData).forEach((key, index) => {
    // Ensure optionNumber is checked against the current index/key
    if (optionNumber === index) {
      // Set the corresponding value in tempColumnData to the optionValue
      tempColumnData[key] = optionValue;
      appendToFinalColumnMapData(tempColumnData);
    }
  });
};


  let start = 0;
  for (var colName of columnKeys) {
    tempColumnData[colName] = start;
    start++;
  }

  // console.log(tempColumnData);
  // console.log(columns);

  const handleClear = (e) => {
    e.preventDefault();
    navigate("/integrate/createtemplates1");
  };

  const [source_column_name, setSource_column_name] = useState("");

  const columnMapMutation = async () => {
    let s1JSON = {
      company_id: companyName,
      country_code: country,
      template_type_id: templateType,
      template_id: JSON.parse(
        localStorage.getItem("frontend_generated_template_id"),
      ),
      userid: user,
    };

    console.log(finalColumnMapData);
    const mergedData = finalColumnMapData.reduce((acc, obj) => {
      Object.keys(obj).forEach(key => {
        // Check if the value is not a number before adding it to the accumulator
        if (typeof obj[key] !== 'number') {
          acc[key] = obj[key];
        }
      });
      return acc;
    }, {});
    
    console.log("Merged Data")
    console.log(mergedData);

    let s2JSON = Object.keys(mergedData)
      .filter((key) => mergedData[key] !== "Ignore")
      .reduce((result, key) => {
        result[key] = mergedData[key];
        return result;
      }, {});

    var finalJSON = [];

    for (let key in s2JSON) {
      let tempJSON = { ...s1JSON }; // Create a new object for each iteration
      tempJSON.target_column_value = key;
      if (s2JSON[key] === undefined || s2JSON[key] === null) {
        tempJSON.source_column_value_1 = null;
      } else {
        tempJSON.source_column_value_1 = s2JSON[key];
      }
      finalJSON.push(tempJSON);
    }
    let request_file_id = localStorage.getItem("new_file_key_s2_outbound");

    // let tempJson = [
    //   {
    //     company_id: "1",
    //     country_code: "USA",
    //     source_column_value_1: "MBR_GNDR_CD",
    //     target_column_value: "source_gender",
    //     template_id: "69e1f5d3-4353-4d57-9c5c-a32a70315a7f",
    //     template_type_id: "medical_claims",
    //     userid: "todd",
    //   },
    //   {
    //     company_id: "1",
    //     country_code: "USA",
    //     source_column_value_1: "MBR_GNDR_CD",
    //     target_column_value: "source_gender",
    //     template_id: "69e1f5d3-4353-4d57-9c5c-a32a70315a7f",
    //     template_type_id: "medical_claims",
    //     userid: "todd",
    //   },
    // ];
    // let tempFileId =
    //   "public/ui-uploads/createTemplateS2FileUpload/resultJSON.benefitProgram/672003_ByteDance_Inc_MDCLM_20230901_20230930_20231009 - Sheet1.csv";

    // console.log("og",finalJSON);
    // console.log("tempjson",tempJson);
    // console.log("og",request_file_id);
    // console.log("tempfi",tempFileId);
    console.log("Final JSON S3")
    console.log(finalJSON);
    const integrateColumnMapMutation = await API.graphql({
      query: mutations.createIntegrateTemplateColumnMap,
      variables: {
        create_column_map_data: finalJSON,
        file_id: request_file_id,
      },
    }).then((data) => {
      console.log("data needed for step 4", data);
      setSource_column_name(data.data.createIntegrateTemplateColumnMap);
      modifyData(data.data.createIntegrateTemplateColumnMap);
    });

    // let temp = [];
    // for (var v of masterJson) {
    //   if (
    //     v.component_type === "key_value_map" &&
    //     v.template_type_id === createTemplateS1Values.templateType &&
    //     v.attribute_key_name === "target_record_type"
    //   ) {
    //     let tempCName = v.attribute_list_value_display_name;
    //     temp.push(tempCName);
    //   } else {
    //   }
    // }
    // s4ColumnData(temp);
    // console.log("colfun", temp);
  };
  // console.log(columns);
  // console.log("md", source_column_name);

  const handleColNames = () => {};

  const handleSave = async (e) => {
    e.preventDefault();
    columnMapMutation();
    // await s4ItemData(source_column_name);
    handleColNames();
    // console.log("item", temp);
    navigate("/integrate/createtemplates4");
  };

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };
  return (
    <>
      {" "}
      {/* <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar /> */}
        <div className="2xl:ml-[14.5rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates1">
              Create Template
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates3">
              Map Columns
            </Link>
          </div>

          {/* page Heading*/}
          <div>
            <section className="flex items-center">
              <BsBoxArrowInRight style={iconStyles} />
              <p className="text-3xl mx-1">Create Template</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>
          <div className="flex">
            {/*steps*/}
            <div className="ml-3">
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 1</p>
                <p className="text-md pb-1">Set Template Properties</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 2</p>
                <p className="text-md pb-1 ">Capture addtional fields</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 3</p>
                <p className="font-bold text-md pb-1 ">Map columns</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 4</p>
                <p className="text-md pb-1">Map keys to values</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
            </div>

            {/* form*/}
            <div className="mt-4 max-h-[500px] overflow-y-scroll ml-32 space-y-4">
              <div>
                {columns.map((column, key) => (
                  <ColMap
                    column={column}
                    key={key}
                    itno={key}
                    items={getS3DropDownData}
                    getMappedOption={getMappedOption}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="pt-16 w-full flex justify-end items-center">
            <button
              onClick={(e) => {
                handleClear(e);
              }}
              className="ml-10 text-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-10 bg-[#AEB3B7] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
              onClick={() => {
                navigate("/integrate/createtemplates2");
                window.location.reload();
              }}
            >
              Back
            </button>
            <button
              type="submit"
              className="ml-5 bg-[#DB2748] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
              onClick={(e) => {
                handleSave(e);
              }}
            >
              Next
            </button>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}

// const items = getS3DropDownData ;
//   if(items.length > 0){
//     setWait(false);
//   }
// const [tempColumnData, settempColumnData] = useState({})

// const {
//   // handleFileRead,
//   // tempColumnData,
//   // items,
//   // benefitProgramList,
//   // employeePrimaryKeyList,
//   // benefitPlanList,
// } = IContext();
// let start = 0;
// for (var key in tempColumnData) {
//   tempColumnData[key] = start;
//   start++;
// }
// console.log(tempColumnData);
// console.log("this is the jsno you asked for showrya", {
//   template_types: createTemplateS1Values.templateType,
//   template_id: localStorage.getItem("generated_template_id"),
//   company_id: createTemplateS1Values.companyName,
//   country_code: createTemplateS1Values.country,
//   userid: user,
// });
// console.log(masterJson);
// console.log(createTemplateS1Values);
