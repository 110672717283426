import { React, useEffect, useState } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { PiPlus } from "react-icons/pi";
import * as mutations from "../../src/graphql/mutations.js";
import { API } from "aws-amplify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

export default function PharmacyUsageBasedCostShare({
  filteredList,
  getPharmacyUsageBasedCostShareMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };

  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);
  const [masterJson, setMasterJson] = useState([]);
  const [wait, setWait] = useState(true);
  const [date, setDate] = useState("");
  const [value0, setValue0] = useState([]);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState("");
  const [value7, setValue7] = useState("");
  const [value8, setValue8] = useState("");
  const [value9, setValue9] = useState("");
  const [value10, setValue10] = useState("");
  const [value11, setValue11] = useState("");
  const [value12, setValue12] = useState("");
  const [value13, setValue13] = useState("");
  const [value14, setValue14] = useState("");
  const [value15, setValue15] = useState("");
  const [value16, setValue16] = useState("");
  const [value17, setValue17] = useState("");
  const [value18, setValue18] = useState("");
  const [value19, setValue19] = useState("");
  const [value20, setValue20] = useState("");
  const [value21, setValue21] = useState("");
  const [value22, setValue22] = useState("");
  const [value23, setValue23] = useState("");

  const getBenefitPlansAndPrograms = async (benefitProgram, benefitCounty) => {
    const filteredValues = await API.graphql({
      query: mutations.PharmacyUsageBasedCostSharingfilterBenefitPlanAndCountry,
      variables: {
        benefit_id: benefitProgram,
        country: benefitCounty,
      },
    });
    setMasterJson(
      filteredValues.data
        .PharmacyUsageBasedCostSharingfilterBenefitPlanAndCountry,
    );
    setWait(false);
  };

  useEffect(() => {
    const benefitProgram = JSON.parse(
      localStorage.getItem("benefitProgramFromBenefitPage1"),
    );
    const benefitCounty = JSON.parse(
      localStorage.getItem("contryFromBenefitPage1"),
    );

    getBenefitPlansAndPrograms(benefitProgram, benefitCounty);
  }, []);

  let test = [];
  for (let r of masterJson) {
    test.push(r.attribute_key_name_parent);
  }

  let uniqueArray = [];
  const test1 = new Map();
  for (const obj of test) {
    const objString = JSON.stringify(obj);
    if (!test1.has(objString)) {
      test1.set(objString, true);
      uniqueArray.push(obj);
    }
  }

  // console.log("pubc", masterJson);
  const handleValue0Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue0(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue1Change = (event) => {
    setValue1(event.target.value);
  };
  const handleValue2Change = (event) => {
    setValue2(event.target.value);
  };
  const handleValue3Change = (event) => {
    setValue3(event.target.value);
  };
  const handleValue4Change = (event) => {
    setValue4(event.target.value);
  };
  const handleValue5Change = (event) => {
    setValue5(event.target.value);
  };
  const handleValue6Change = (event) => {
    setValue6(event.target.value);
  };
  const handleValue7Change = (event) => {
    setValue7(event.target.value);
  };
  const handleValue8Change = (event) => {
    setValue8(event.target.value);
  };
  const handleValue9Change = (event) => {
    setValue9(event.target.value);
  };
  const handleValue10Change = (event) => {
    setValue10(event.target.value);
  };
  const handleValue11Change = (event) => {
    setValue11(event.target.value);
  };
  const handleValue12Change = (event) => {
    setValue12(event.target.value);
  };
  const handleValue13Change = (event) => {
    setValue13(event.target.value);
  };
  const handleValue14Change = (event) => {
    setValue14(event.target.value);
  };
  const handleValue15Change = (event) => {
    setValue15(event.target.value);
  };
  const handleValue16Change = (event) => {
    setValue16(event.target.value);
  };
  const handleValue17Change = (event) => {
    setValue17(event.target.value);
  };
  const handleValue18Change = (event) => {
    setValue18(event.target.value);
  };
  const handleValue19Change = (event) => {
    setValue19(event.target.value);
  };
  const handleValue20Change = (event) => {
    setValue20(event.target.value);
  };
  const handleValue21Change = (event) => {
    setValue21(event.target.value);
  };
  const handleValue22Change = (event) => {
    setValue22(event.target.value);
  };
  const handleValue23Change = (event) => {
    setValue23(event.target.value);
  };

  let pharmacyMedicalIntegrartionList = [];

  if (uniqueArray.includes("pharmacy_medical_integration")) {
    for (let pmil of masterJson) {
      if (pmil.attribute_key_name_parent === "pharmacy_medical_integration") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pmil.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          pmil.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pmil.attribute_list_value_sort_order_child;
        pharmacyMedicalIntegrartionList.push(dict1);
      }
    }
  }

  pharmacyMedicalIntegrartionList.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );

  const duplicateFinalList = [
    ["pharmacy_medical_integration", value0.join("+")],
    ["in_network_pharmacy_biosimilar_coinsurance_pct", value1],
    ["in_network_pharmacy_generic_coinsurance_pct", value2],
    ["in_network_pharmacy_non_preferred_brand_coinsurance_pct", value3],
    ["in_network_pharmacy_preferred_brand_coinsurance_pct", value4],
    ["out_of_network_pharmacy_generic_coinsurance_pct", value5],
    ["out_of_network_pharmacy_non_preferred_brand__coinsurance_pct", value6],
    ["out_of_network_pharmacy_preferred_brand_coinsurance_pct", value7],
    ["in_network_pharmacy_specialy_coinsurance_pct", value8],
    ["in_network_pharmacy_biosimilar_specialty_copay_amt", value9],
    ["in_network_pharmacy_generic_copay_amt", value10],
    ["in_network_pharmacy_non_preferred_brand_copay_amt", value11],
    ["in_network_pharmacy_preferred_brand_copay_amt", value12],
    ["out_of_network_pharmacy_generic_copay_amt", value13],
    ["out_of_network_pharmacy_non_preferred_brand_copay_amt", value14],
    ["out_of_network_pharmacy_preferred_brand_copay_amt", value15],
    ["in_network_pharmacy_specialty_copay_amt", value16],
    ["in_network_family_deductible_amt", value17],
    ["out_of_network_family_deductible_amt", value18],
    ["in_network_individual_deductible_amt", value19],
    ["out_of_network_individual_deductible_amt", value20],
    ["pharmacy_coinsurance", value21],
    ["pharmacy_copay", value22],
    ["pharmacy_deductible", value23],
  ];

  let finalList = [];

  for (var i of duplicateFinalList) {
    if (i[1].length > 0) {
      finalList.push(i);
    }
  }

  let finalDict = {
    date: `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
      date.$D > 9 ? date.$D : `0${date.$D}`
    }`,
  };

  for (var er of finalList) {
    finalDict[er[0]] = er[1];
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getPharmacyUsageBasedCostShareMappedValues(
        finalDict,
        `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
          date.$D > 9 ? date.$D : `0${date.$D}`
        }`,
      );
    }
  };

  return (
    <>
      {wait ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={wait}
        >
          <CircularProgress sx={{ color: "#DB2748" }} />{" "}
        </Backdrop>
      ) : (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Pharmacy Usage Based Cost Sharing</Typography>
          </AccordionSummary>

          <AccordionDetails className="mx-5 mb-3 flex justify-between">
            <div>
              {alert === "success" && alertStatus ? (
                <Alert onClose={() => setAlertStatus(false)} severity="success">
                  Success! The details provided have been stored
                </Alert>
              ) : alert === "error" && alertStatus ? (
                <Alert onClose={() => setAlertStatus(false)} severity="error">
                  Please, Fill all the fields
                </Alert>
              ) : (
                ""
              )}
            </div>
          </AccordionDetails>

          <AccordionDetails className="grid grid-cols-2 gap-8 mx-5 mb-4">
            <FormControl fullWidth>
              <DatePicker
                label="As Of Date"
                name="date"
                value={date}
                onChange={(date) => setDate(date)}
                format="YYYY-DD-MM"
              />
            </FormControl>
            <FormControl fullWidth></FormControl>

            {uniqueArray.includes("pharmacy_medical_integration") && (
              <>
                {" "}
                <FormControl fullWidth>
                  <Tooltip title={"Pharmacy-Medical Integration"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Pharmacy-Medical Integration"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="PUBCS12346">{"Value"}</InputLabel>
                  <Select
                    labelId="PUBCS123458"
                    id="PUBCS12346"
                    value={value0}
                    multiple
                    label={"Value"}
                    onChange={handleValue0Change}
                  >
                    {pharmacyMedicalIntegrartionList.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_biosimilar_coinsurance_pct",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Biosimilar Drugs"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Biosimilar Drugs"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS12348"
                    value={value1}
                    label={"Amount"}
                    onChange={handleValue1Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_generic_coinsurance_pct",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Generics "}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Generics "}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123439"
                    value={value2}
                    label={"Amount"}
                    onChange={handleValue2Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_non_preferred_brand_coinsurance_pct",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Coinsurance - In-Network Non-Preferred Brand"}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Non-Preferred Brand"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS1234340"
                    value={value3}
                    label={"Percent"}
                    onChange={handleValue3Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_preferred_brand_coinsurance_pct",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Preferred Brand"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Preferred Brand"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123418"
                    value={value4}
                    label={"Percent"}
                    onChange={handleValue4Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_pharmacy_generic_coinsurance_pct",
            ) && (
              <>
                {" "}
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - Out-of-Network Generic"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Out-of-Network Generic"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123418"
                    value={value5}
                    label={"Percent"}
                    onChange={handleValue5Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_pharmacy_non_preferred_brand__coinsurance_pct",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Coinsurance - Out-of-Network Non-Preferred Brand "}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={
                        "Coinsurance - Out-of-Network Non-Preferred Brand "
                      }
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123418"
                    value={value6}
                    label={"Percent"}
                    onChange={handleValue6Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_pharmacy_preferred_brand_coinsurance_pct",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Coinsurance - Out-of-Network Preferred Brand"}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Out-of-Network Preferred Brand"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS1234181"
                    value={value7}
                    label={"Percent"}
                    onChange={handleValue7Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_specialy_coinsurance_pct",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Coinsurance - Specialty Non-Biosimilar Drugs"}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Specialty Non-Biosimilar Drugs"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123432"
                    value={value8}
                    label={"Percent"}
                    onChange={handleValue8Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_biosimilar_specialty_copay_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Biosimilar Drugs"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Biosimilar Drugs"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123433"
                    value={value9}
                    label={"Amount"}
                    onChange={handleValue9Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_pharmacy_generic_copay_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Generics"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Generics"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123433"
                    value={value10}
                    label={"Amount"}
                    onChange={handleValue10Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_non_preferred_brand_copay_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Non-Preferred  Brand"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Non-Preferred  Brand"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123433"
                    value={value11}
                    label={"Amount"}
                    onChange={handleValue11Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_preferred_brand_copay_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-network Preferred Brand"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-network Preferred Brand"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123440"
                    value={value12}
                    label={"Amount"}
                    onChange={handleValue12Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_pharmacy_generic_copay_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Out-of-Network Generic"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Out-of-Network Generic"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123441"
                    value={value13}
                    label={"Amount"}
                    onChange={handleValue13Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_pharmacy_non_preferred_brand_copay_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Co-Pay - Out-of-network Non-Preferred Brand"}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-Pay - Out-of-network Non-Preferred Brand"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123442"
                    value={value14}
                    label={"Amount"}
                    onChange={handleValue14Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_pharmacy_preferred_brand_copay_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-Pay - Out-of-Network Preferred Brand"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-Pay - Out-of-Network Preferred Brand"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123442"
                    value={value15}
                    label={"Amount"}
                    onChange={handleValue15Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_pharmacy_specialty_copay_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Specialty Non-Biosimilar Drugs"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Specialty Non-Biosimilar Drugs"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123442"
                    value={value16}
                    label={"Amount"}
                    onChange={handleValue16Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_family_deductible_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Family Deductible - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Family Deductible - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123449"
                    value={value17}
                    label={"Amount"}
                    onChange={handleValue17Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("out_of_network_family_deductible_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Family Deductible - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Family Deductible - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123450"
                    value={value18}
                    label={"Amount"}
                    onChange={handleValue18Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_individual_deductible_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Individual Deductible - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Individual Deductible - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123451"
                    value={value19}
                    label={"Amount"}
                    onChange={handleValue19Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_individual_deductible_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Individual Deductible - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Individual Deductible - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123451"
                    value={value20}
                    label={"Amount"}
                    onChange={handleValue20Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("pharmacy_coinsurance") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - Pharmacy"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Pharmacy"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123451"
                    value={value21}
                    label={"Percent"}
                    onChange={handleValue21Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("pharmacy_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Pharmacy"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Pharmacy"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123451"
                    value={value22}
                    label={"Amount"}
                    onChange={handleValue22Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("pharmacy_deductible") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Deductible"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Deductible"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PUBCS123451"
                    value={value23}
                    label={"Amount"}
                    onChange={handleValue23Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            <FormControl fullWidth></FormControl>
            <div className="mt-5 w-full flex justify-end items-center">
              <button
                type="submit"
                className="ml-5 bg-[#0000A3] py-[6px]   shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg    px-6  text-white"
                onClick={(e) => {
                  handleSave(e);
                }}
              >
                Save
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
          // <div className="pt-5">
          //   {" "}
          //   {finalList.map((item, key) => (
          //     <div key={key}>
          //       {item[0]} : {item[1]}
          //     </div>
          //   ))}
          // </div>

// <div className="pt-5">
//   {" "}
//   {JSON.stringify(
//     [
//       [date.$y, date.$M + 1, date.$D],
//       [eligibilityRules[0].listValueDisplayName, value0],
//       [eligibilityRules[1].listValueDisplayName, value1],
//       [eligibilityRules[2].listValueDisplayName, value2],
//       [eligibilityRules[3].listValueDisplayName, value3],
//       [eligibilityRules[4].listValueDisplayName, value4],
//       [eligibilityRules[5].listValueDisplayName, value5],
//       [eligibilityRules[6].listValueDisplayName, value6],
//       [eligibilityRules[7].listValueDisplayName, value7],
//       [eligibilityRules[8].listValueDisplayName, value8],
//       [eligibilityRules[9].listValueDisplayName, value9],
//       [eligibilityRules[10].listValueDisplayName, value10],
//       [eligibilityRules[11].listValueDisplayName, value11],
//       [eligibilityRules[12].listValueDisplayName, value12],
//       [eligibilityRules[13].listValueDisplayName, value13],
//       [eligibilityRules[14].listValueDisplayName, value14],
//       [eligibilityRules[15].listValueDisplayName, value15],
//       [eligibilityRules[16].listValueDisplayName, value16],
//       [eligibilityRules[17].listValueDisplayName, value17],
//       [eligibilityRules[18].listValueDisplayName, value18],
//       [eligibilityRules[19].listValueDisplayName, value19],
//     ],
//     null,
//     "    "
//   )}
// </div>

// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[0].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[0].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <InputLabel id="AnnualHealthReimbursementAccounts">
//     {eligibilityRules[0].listValueRightDisplayValue}
//   </InputLabel>
//   <Select
//     labelId="AnnualHealthReimbursementAccounts"
//     id="AnnualHealthReimbursementAccounts"
//     value={value0}
//     label={eligibilityRules[0].listValueRightDisplayValue}
//     multiple
//     onChange={handleValue0Change}
//   >
//     {pharmacyMedicalIntegrartionList.map((item, key) => (
//       <MenuItem value={item.listValueKey} key={key}>
//         {item.listValueDisplayName}
//       </MenuItem>
//     ))}
//   </Select>
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[1].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[1].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value1}
//     label={eligibilityRules[1].listValueRightDisplayValue}
//     onChange={handleValue1Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[2].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[2].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value2}
//     label={eligibilityRules[2].listValueRightDisplayValue}
//     onChange={handleValue2Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[3].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[3].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value3}
//     label={eligibilityRules[3].listValueRightDisplayValue}
//     onChange={handleValue3Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[4].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[4].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value4}
//     label={eligibilityRules[4].listValueRightDisplayValue}
//     onChange={handleValue4Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[5].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[5].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value5}
//     label={eligibilityRules[5].listValueRightDisplayValue}
//     onChange={handleValue5Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[6].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[6].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value6}
//     label={eligibilityRules[6].listValueRightDisplayValue}
//     onChange={handleValue6Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[7].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[7].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value7}
//     label={eligibilityRules[7].listValueRightDisplayValue}
//     onChange={handleValue7Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[8].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[8].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value8}
//     label={eligibilityRules[8].listValueRightDisplayValue}
//     onChange={handleValue8Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[9].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[9].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value9}
//     label={eligibilityRules[9].listValueRightDisplayValue}
//     onChange={handleValue9Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[10].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[10].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value10}
//     label={eligibilityRules[10].listValueRightDisplayValue}
//     onChange={handleValue10Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[11].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[11].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value11}
//     label={eligibilityRules[11].listValueRightDisplayValue}
//     onChange={handleValue11Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[12].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[12].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value12}
//     label={eligibilityRules[12].listValueRightDisplayValue}
//     onChange={handleValue12Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[13].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[13].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value13}
//     label={eligibilityRules[13].listValueRightDisplayValue}
//     onChange={handleValue13Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[14].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[14].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value14}
//     label={eligibilityRules[14].listValueRightDisplayValue}
//     onChange={handleValue14Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[15].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[15].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value15}
//     label={eligibilityRules[15].listValueRightDisplayValue}
//     onChange={handleValue15Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[16].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[16].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value16}
//     label={eligibilityRules[16].listValueRightDisplayValue}
//     onChange={handleValue16Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[17].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[17].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value17}
//     label={eligibilityRules[17].listValueRightDisplayValue}
//     onChange={handleValue17Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[18].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[18].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value18}
//     label={eligibilityRules[18].listValueRightDisplayValue}
//     onChange={handleValue18Change}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl fullWidth>
//   <Tooltip title={eligibilityRules[19].listValueDisplayName}>
//     <TextField
//       label="Cost Sharing Provision"
//       id="outlined-basic"
//       value={eligibilityRules[19].listValueDisplayName}
//       variant="outlined"
//       inputProps={{ readOnly: true }}
//     />
//   </Tooltip>
// </FormControl>
// <FormControl fullWidth>
//   <TextField
//     id="AnnualHealthReimbursementAccounts"
//     value={value19}
//     label={eligibilityRules[19].listValueRightDisplayValue}
//     onChange={handleValue19Change}
//     variant="outlined"
//   />
// </FormControl>
//
//
// for (let rules of filteredList) {
//   if (rules.child_operator_flag === "child") {
//     let dict1 = {
//       listValueKey: "",
//       listValueDisplayName: "",
//       listValueSortOrder: "",
//       listValueRightDisplayValue: "",
//     };
//
//     dict1.listValueKey = rules.attribute_key_name_parent;
//     dict1.listValueDisplayName = rules.attribute_key_display_name_parent;
//     dict1.listValueSortOrder = rules.attribute_list_value_sort_order_parent;
//     dict1.listValueRightDisplayValue = rules.attribute_key_display_name_child;
//     duplicateEligibilityRules.push(dict1);
//   }
// }

// const uniqueMapCid = new Map();
// for (const obj of duplicateEligibilityRules) {
//   const objString = JSON.stringify(obj);
//   if (!uniqueMapCid.has(objString)) {
//     uniqueMapCid.set(objString, true);
//     eligibilityRules.push(obj);
//   }
// }
