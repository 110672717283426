import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { PiPlus } from "react-icons/pi";
import * as mutations from "../../src/graphql/mutations.js";
import { API } from "aws-amplify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Alert from "@mui/material/Alert";

export default function UsageBasedCostSharing({
  filteredList,
  getUsageBasedCostSharingMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };

  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);
  const [masterJson, setMasterJson] = useState([]);
  const [wait, setWait] = useState(true);
  const [date, setDate] = useState("");
  const [value0, setValue0] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState("");
  const [value7, setValue7] = useState("");
  const [value8, setValue8] = useState("");
  const [value9, setValue9] = useState("");
  const [value10, setValue10] = useState("");
  const [value11, setValue11] = useState("");
  const [value12, setValue12] = useState("");
  const [value13, setValue13] = useState("");
  const [value14, setValue14] = useState("");
  const [value15, setValue15] = useState("");
  const [value16, setValue16] = useState("");
  const [value17, setValue17] = useState("");
  const [value18, setValue18] = useState("");
  const [value19, setValue19] = useState("");
  const [value20, setValue20] = useState("");
  const [value21, setValue21] = useState("");
  const [value22, setValue22] = useState("");
  const [value23, setValue23] = useState("");
  const [value24, setValue24] = useState("");
  const [value25, setValue25] = useState("");
  const [value26, setValue26] = useState("");
  const [value27, setValue27] = useState("");
  const [value28, setValue28] = useState("");
  const [value29, setValue29] = useState("");
  const [value30, setValue30] = useState("");
  const [value31, setValue31] = useState("");
  const [value32, setValue32] = useState("");
  const [value33, setValue33] = useState("");
  const [value34, setValue34] = useState("");
  const [value35, setValue35] = useState("");
  const [value36, setValue36] = useState("");
  const [value37, setValue37] = useState("");
  const [value38, setValue38] = useState("");
  const [value39, setValue39] = useState("");
  const [value40, setValue40] = useState("");
  const [value41, setValue41] = useState("");
  const [value42, setValue42] = useState("");
  const [value43, setValue43] = useState("");
  const [value44, setValue44] = useState("");
  const [value45, setValue45] = useState("");
  const [value46, setValue46] = useState("");
  const [value47, setValue47] = useState("");
  const [value48, setValue48] = useState("");

  const getBenefitPlansAndPrograms = async (benefitProgram, benefitCounty) => {
    const filteredValues = await API.graphql({
      query: mutations.UsageBasedCostSharingfilterBenefitPlanAndCountry,
      variables: {
        benefit_id: benefitProgram,
        country: benefitCounty,
      },
    });
    setMasterJson(
      filteredValues.data.UsageBasedCostSharingfilterBenefitPlanAndCountry,
    );
    setWait(false);
  };

  useEffect(() => {
    const benefitProgram = JSON.parse(
      localStorage.getItem("benefitProgramFromBenefitPage1"),
    );
    const benefitCounty = JSON.parse(
      localStorage.getItem("contryFromBenefitPage1"),
    );

    getBenefitPlansAndPrograms(benefitProgram, benefitCounty);
  }, []);

  let test = [];
  for (let r of masterJson) {
    test.push(r.attribute_key_name_parent);
  }

  let uniqueArray = [];
  const test1 = new Map();
  for (const obj of test) {
    const objString = JSON.stringify(obj);
    if (!test1.has(objString)) {
      test1.set(objString, true);
      uniqueArray.push(obj);
    }
  }

  const handleValue0Change = (event) => {
    setValue0(event.target.value);
  };
  const handleValue1Change = (event) => {
    setValue1(event.target.value);
  };
  const handleValue2Change = (event) => {
    setValue2(event.target.value);
  };
  const handleValue3Change = (event) => {
    setValue3(event.target.value);
  };
  const handleValue4Change = (event) => {
    setValue4(event.target.value);
  };
  const handleValue5Change = (event) => {
    setValue5(event.target.value);
  };
  const handleValue6Change = (event) => {
    setValue6(event.target.value);
  };
  const handleValue7Change = (event) => {
    setValue7(event.target.value);
  };
  const handleValue8Change = (event) => {
    setValue8(event.target.value);
  };
  const handleValue9Change = (event) => {
    setValue9(event.target.value);
  };
  const handleValue10Change = (event) => {
    setValue10(event.target.value);
  };
  const handleValue11Change = (event) => {
    setValue11(event.target.value);
  };
  const handleValue12Change = (event) => {
    setValue12(event.target.value);
  };
  const handleValue13Change = (event) => {
    setValue13(event.target.value);
  };
  const handleValue14Change = (event) => {
    setValue14(event.target.value);
  };
  const handleValue15Change = (event) => {
    setValue15(event.target.value);
  };
  const handleValue16Change = (event) => {
    setValue16(event.target.value);
  };
  const handleValue17Change = (event) => {
    setValue17(event.target.value);
  };
  const handleValue18Change = (event) => {
    setValue18(event.target.value);
  };
  const handleValue19Change = (event) => {
    setValue19(event.target.value);
  };
  const handleValue20Change = (event) => {
    setValue20(event.target.value);
  };
  const handleValue21Change = (event) => {
    setValue21(event.target.value);
  };
  const handleValue22Change = (event) => {
    setValue22(event.target.value);
  };
  const handleValue23Change = (event) => {
    setValue23(event.target.value);
  };
  const handleValue24Change = (event) => {
    setValue24(event.target.value);
  };
  const handleValue25Change = (event) => {
    setValue25(event.target.value);
  };
  const handleValue26Change = (event) => {
    setValue26(event.target.value);
  };
  const handleValue27Change = (event) => {
    setValue27(event.target.value);
  };
  const handleValue28Change = (event) => {
    setValue28(event.target.value);
  };
  const handleValue29Change = (event) => {
    setValue29(event.target.value);
  };
  const handleValue30Change = (event) => {
    setValue30(event.target.value);
  };
  const handleValue31Change = (event) => {
    setValue31(event.target.value);
  };
  const handleValue32Change = (event) => {
    setValue32(event.target.value);
  };
  const handleValue33Change = (event) => {
    setValue33(event.target.value);
  };
  const handleValue34Change = (event) => {
    setValue34(event.target.value);
  };
  const handleValue35Change = (event) => {
    setValue35(event.target.value);
  };
  const handleValue36Change = (event) => {
    setValue36(event.target.value);
  };
  const handleValue37Change = (event) => {
    setValue37(event.target.value);
  };
  const handleValue38Change = (event) => {
    setValue38(event.target.value);
  };
  const handleValue39Change = (event) => {
    setValue39(event.target.value);
  };
  const handleValue40Change = (event) => {
    setValue40(event.target.value);
  };
  const handleValue41Change = (event) => {
    setValue41(event.target.value);
  };
  const handleValue42Change = (event) => {
    setValue42(event.target.value);
  };
  const handleValue43Change = (event) => {
    setValue43(event.target.value);
  };
  const handleValue44Change = (event) => {
    setValue44(event.target.value);
  };
  const handleValue45Change = (event) => {
    setValue45(event.target.value);
  };
  const handleValue46Change = (event) => {
    setValue46(event.target.value);
  };
  const handleValue47Change = (event) => {
    setValue47(event.target.value);
  };
  const handleValue48Change = (event) => {
    setValue48(event.target.value);
  };

  const glassesContactsServiceFrequency = [];
  const eyeExamServiceFrequency = [];
  const dualCover = [];

  if (uniqueArray.includes("glasses_contacts_service_frequency")) {
    for (let pmil of masterJson) {
      if (
        pmil.attribute_key_name_parent === "glasses_contacts_service_frequency"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pmil.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          pmil.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pmil.attribute_list_value_sort_order_child;
        glassesContactsServiceFrequency.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("eye_exam_service_frequency")) {
    for (let pmil of masterJson) {
      if (pmil.attribute_key_name_parent === "eye_exam_service_frequency") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pmil.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          pmil.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pmil.attribute_list_value_sort_order_child;
        eyeExamServiceFrequency.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("dual_cover")) {
    for (let pmil of masterJson) {
      if (pmil.attribute_key_name_parent === "dual_cover") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = pmil.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          pmil.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = pmil.attribute_list_value_sort_order_child;
        dualCover.push(dict1);
      }
    }
  }
  glassesContactsServiceFrequency.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  eyeExamServiceFrequency.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  dualCover.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);

  const duplicateFinalList = [
    ["dental_deductible_family", value0],
    ["dental_deductible_individual", value1],
    ["dental_in_network_major_coinsurance", value2],
    ["dental_in_network_major_copay", value3],
    ["dental_in_network_minor_coinsurance", value4],
    ["dental_in_network_minor_copay", value5],
    ["dental_in_network_orthodontia_coinsurance", value6],
    ["dental_in_network_orthodontia_copay", value7],
    ["dental_in_network_preventative_coinsurance", value8],
    ["dental_in_network_preventative_copay", value9],
    ["dental_life_time_cap_orthodontia", value10],
    ["dental_maximum_allowable_benefit", value11],
    ["dental_out_of_network_major_coinsurance", value12],
    ["dental_out_of_network_major_copay", value13],
    ["dental_out_of_network_minor_coinsurance", value14],
    ["dental_out_of_network_minor_copay", value15],
    ["dental_out_of_network_orthodontia_coinsurance", value16],
    ["dental_out_of_network_orthodontia_copay", value17],
    ["dental_out_of_network_preventative_coinsurance", value18],
    ["dental_out_of_network_preventative_copay", value19],
    ["dual_cover", value20],
    ["eye_exam_service_frequency", value21],
    ["glasses_contacts_service_frequency", value22],
    ["in_network_coinsurance_pct", value23],
    ["in_network_copay_amt", value24],
    ["in_network_eye_exam_copay", value25],
    ["in_network_family_deductible_amt", value26],
    ["in_network_family_out_of_pocket_max_amt", value27],
    ["in_network_frame_contact_copay", value28],
    ["in_network_individual_deductible_amt", value29],
    ["in_network_individual_out_of_pocket_max_amt", value30],
    ["maximum_allowable_benefit", value31],
    ["medical_coinsurance", value32],
    ["medical_copay", value33],
    ["medical_deductible", value34],
    ["medical_in_network_max", value35],
    ["medical_out_of_network_max", value36],
    ["out_of_network_coinsurance_pct", value37],
    ["out_of_network_copay_amt", value38],
    ["out_of_network_eye_exam_copay", value39],
    ["out_of_network_family_deductible_amt", value40],
    ["out_of_network_family_out_of_pocket_max_amt", value41],
    ["out_of_network_frame_contact_copay", value42],
    ["out_of_network_individual_out_of_pocket_max_amt", value43],
    ["pharmacy_coinsurance", value44],
    ["pharmacy_copay", value45],
    ["pharmacy_deductible", value46],
    ["vision_in_network_maximium_allowable_benefit", value47],
    ["vision_out_of_network_maximium_allowable_benefit", value48],
  ];

  let finalList = [];

  for (var i of duplicateFinalList) {
    if (i[1].length > 0) {
      finalList.push(i);
    }
  }

  let finalDict = {
    date: `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
      date.$D > 9 ? date.$D : `0${date.$D}`
    }`,
  };

  for (var er of finalList) {
    finalDict[er[0]] = er[1];
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getUsageBasedCostSharingMappedValues(
        finalDict,
        `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
          date.$D > 9 ? date.$D : `0${date.$D}`
        }`,
      );
    }
  };

  return (
    <>
      {wait ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={wait}
        >
          <CircularProgress sx={{ color: "#DB2748" }} />{" "}
        </Backdrop>
      ) : (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Usage Based Cost Sharing</Typography>
          </AccordionSummary>

          <AccordionDetails className="mx-5 mb-3 flex justify-between">
            <div>
              {alert === "success" && alertStatus ? (
                <Alert onClose={() => setAlertStatus(false)} severity="success">
                  Success! The details provided have been stored
                </Alert>
              ) : alert === "error" && alertStatus ? (
                <Alert onClose={() => setAlertStatus(false)} severity="error">
                  Please, Fill all the fields
                </Alert>
              ) : (
                ""
              )}
            </div>
          </AccordionDetails>

          <AccordionDetails className="grid grid-cols-2 gap-8 mx-5 mb-4">
            <FormControl fullWidth>
              <DatePicker
                label="As Of Date"
                name="date"
                value={date}
                onChange={(date) => setDate(date)}
                format="YYYY-DD-MM"
              />
            </FormControl>

            <FormControl fullWidth></FormControl>

            {uniqueArray.includes("dental_deductible_family") && (
              <>
                {" "}
                <FormControl fullWidth>
                  <Tooltip title={"Dental Deductible Family"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Dental Deductible Family"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS12348"
                    value={value0}
                    label={"Amount"}
                    onChange={handleValue0Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_deductible_individual") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Dental Deductible Individual"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Dental Deductible Individual"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS12348"
                    value={value1}
                    label={"Amount"}
                    onChange={handleValue1Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_major_coinsurance") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance -In-Network Major Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance -In-Network Major Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123439"
                    value={value2}
                    label={"Percent"}
                    onChange={handleValue2Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_major_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Major Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Major Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS1234340"
                    value={value3}
                    label={"Amount"}
                    onChange={handleValue3Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_minor_coinsurance") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Minor Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Minor Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123418"
                    value={value4}
                    label={"Percent"}
                    onChange={handleValue4Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_minor_copay") && (
              <>
                {" "}
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Minor Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Minor Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123418"
                    value={value5}
                    label={"Amount"}
                    onChange={handleValue5Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_in_network_orthodontia_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Orthodontia"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Orthodontia"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123418"
                    value={value6}
                    label={"Percent"}
                    onChange={handleValue6Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_orthodontia_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-network Orthodontia"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-network Orthodontia"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS1234181"
                    value={value7}
                    label={"Amount"}
                    onChange={handleValue7Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_in_network_preventative_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Preventative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Preventative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123432"
                    value={value8}
                    label={"Percent"}
                    onChange={handleValue8Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_preventative_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Preventative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Preventative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123433"
                    value={value9}
                    label={"Amount"}
                    onChange={handleValue9Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_life_time_cap_orthodontia") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Life-time Orthodontia Cap"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Life-time Orthodontia Cap"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123433"
                    value={value10}
                    label={"Amount"}
                    onChange={handleValue10Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_maximum_allowable_benefit") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Dental Max Benefit"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Dental Max Benefit"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123433"
                    value={value11}
                    label={"Amount"}
                    onChange={handleValue11Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_major_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Coinsurance - Out-of-Network Major Restorative"}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Out-of-Network Major Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123440"
                    value={value12}
                    label={"Percent"}
                    onChange={handleValue12Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_out_of_network_major_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Out-of-Network Major Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Out-of-Network Major Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123441"
                    value={value13}
                    label={"Amount"}
                    onChange={handleValue13Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_minor_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Coinsurance: Out-of-Network Minor Restorative"}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance: Out-of-Network Minor Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123442"
                    value={value14}
                    label={"Percent"}
                    onChange={handleValue14Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_out_of_network_minor_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Out-of-Network Minor Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Out-of-Network Minor Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123442"
                    value={value15}
                    label={"Amount"}
                    onChange={handleValue15Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_orthodontia_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - Out-of-Network Orthodontia"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Out-of-Network Orthodontia"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123442"
                    value={value16}
                    label={"Percent"}
                    onChange={handleValue16Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_orthodontia_copay",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-Pay: Out-of-Network Orthodontia"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-Pay: Out-of-Network Orthodontia"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123449"
                    value={value17}
                    label={"Amount"}
                    onChange={handleValue17Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_preventative_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance: Out-of-Network Preventative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance: Out-of-Network Preventative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123450"
                    value={value18}
                    label={"Percent"}
                    onChange={handleValue18Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_preventative_copay",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay: Out-of-Network Preventative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay: Out-of-Network Preventative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123451"
                    value={value19}
                    label={"Amount"}
                    onChange={handleValue19Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dual_cover") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Dual Coverge Rule"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Dual Coverge Rule"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="UBCS1234178">{"Value"}</InputLabel>
                  <Select
                    labelId="UBCS1234190"
                    id="UBCS1234202"
                    value={value20}
                    label={"Value"}
                    onChange={handleValue20Change}
                  >
                    {dualCover.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            {uniqueArray.includes("eye_exam_service_frequency") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Eye-Exams Frequency"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Eye-Exams Frequency"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="UBCS1234178">{"Value"}</InputLabel>
                  <Select
                    labelId="UBCS1234190"
                    id="UBCS1234202"
                    value={value21}
                    label={"Value"}
                    onChange={handleValue21Change}
                  >
                    {eyeExamServiceFrequency.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("glasses_contacts_service_frequency") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Maximum frequency for glasses/contacts"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Maximum frequency for glasses/contacts"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="UBCS1234178">{"Value"}</InputLabel>
                  <Select
                    labelId="UBCS1234190"
                    id="UBCS1234202"
                    value={value22}
                    label={"Value"}
                    onChange={handleValue22Change}
                  >
                    {glassesContactsServiceFrequency.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_coinsurance_pct") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123457"
                    value={value23}
                    label={"Percent"}
                    onChange={handleValue23Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_copay_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network "}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network "}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123457"
                    value={value24}
                    label={"Amount"}
                    onChange={handleValue24Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_eye_exam_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Eye-Exams"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Eye-Exams"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123457"
                    value={value25}
                    label={"Amount"}
                    onChange={handleValue25Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_family_deductible_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Family Deductible - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Family Deductible - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123460"
                    value={value26}
                    label={"Amount"}
                    onChange={handleValue26Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_family_out_of_pocket_max_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Family OOP Max - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Family OOP Max - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123460"
                    value={value27}
                    label={"Amount"}
                    onChange={handleValue27Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_frame_contact_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-network Glasses/Contacts"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-network Glasses/Contacts"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123464"
                    value={value28}
                    label={"Amount"}
                    onChange={handleValue28Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_individual_deductible_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Individual Deductible - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Individual Deductible - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123465"
                    value={value29}
                    label={"Amount"}
                    onChange={handleValue29Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "in_network_individual_out_of_pocket_max_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Individual OOP Max - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Individual OOP Max - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123466"
                    value={value30}
                    label={"Amount"}
                    onChange={handleValue30Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("maximum_allowable_benefit") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Maximum Allowable Benefit"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Maximum Allowable Benefit"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123467"
                    value={value31}
                    label={"Amount"}
                    onChange={handleValue31Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("medical_coinsurance") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123467"
                    value={value32}
                    label={"Amount"}
                    onChange={handleValue32Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("medical_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Copay"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Copay"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123467"
                    value={value33}
                    label={"Amount"}
                    onChange={handleValue33Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("medical_deductible") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Deductible"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Deductible"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123470"
                    value={value34}
                    label={"Amount"}
                    onChange={handleValue34Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("medical_in_network_max") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Medical Max Benefit - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Medical Max Benefit - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123470"
                    value={value35}
                    label={"Amount"}
                    onChange={handleValue35Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("medical_out_of_network_max") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Medical Max Benefit - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Medical Max Benefit - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123474"
                    value={value36}
                    label={"Amount"}
                    onChange={handleValue36Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("out_of_network_coinsurance_pct") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123475"
                    value={value37}
                    label={"Number"}
                    onChange={handleValue37Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("out_of_network_copay_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123476"
                    value={value38}
                    label={"Number"}
                    onChange={handleValue38Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("out_of_network_eye_exam_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Out-of-Network Eye-Exam"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Out-of-Network Eye-Exam"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123477"
                    value={value39}
                    label={"Number"}
                    onChange={handleValue39Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("out_of_network_family_deductible_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Family Deductible - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Family Deductible - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123518"
                    value={value40}
                    label={"Number"}
                    onChange={handleValue40Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_family_out_of_pocket_max_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Family OOP Max - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Family OOP Max - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123479"
                    value={value41}
                    label={"Number"}
                    onChange={handleValue41Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("out_of_network_frame_contact_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Copay - Out-of-Network Glasses/Contacts"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Copay - Out-of-Network Glasses/Contacts"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123480"
                    value={value42}
                    label={"Number"}
                    onChange={handleValue42Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_individual_out_of_pocket_max_amt",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Individual OOP max - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Individual OOP max - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123481"
                    value={value43}
                    label={"Number"}
                    onChange={handleValue43Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("pharmacy_coinsurance") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - Pharmacy"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Pharmacy"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123482"
                    value={value44}
                    label={"Number"}
                    onChange={handleValue44Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("pharmacy_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Pharmacy"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Pharmacy"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123523"
                    value={value45}
                    label={"Number"}
                    onChange={handleValue45Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("pharmacy_deductible") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Deductible"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Deductible"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123524"
                    value={value46}
                    label={"Amount"}
                    onChange={handleValue46Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "vision_in_network_maximium_allowable_benefit",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Vision Max Benefit - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Vision Max Benefit - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123485"
                    value={value47}
                    label={"Percent"}
                    onChange={handleValue47Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "vision_out_of_network_maximium_allowable_benefit",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Vision Max Benefit - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Vision Max Benefit - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="UBCS123486"
                    value={value48}
                    label={"Percent"}
                    onChange={handleValue48Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            <FormControl fullWidth></FormControl>
            <div className="mt-5 w-full flex justify-end items-center">
              <button
                type="submit"
                className="ml-5 bg-[#0000A3] py-[6px]   shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg    px-6  text-white"
                onClick={(e) => {
                  handleSave(e);
                }}
              >
                Save
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

          // <div className="pt-5">
          //   {" "}
          //   {finalList.map((item, key) => (
          //     <div key={key}>
          //       {item[0]} : {item[1]}
          //     </div>
          //   ))}
          // </div>

// <div className="pt-5">
//   {" "}
//   {JSON.stringify(
//     [
//       [date.$y, date.$M + 1, date.$D],
//       [eligibilityRules[0].listValueDisplayName, value0],
//       [eligibilityRules[1].listValueDisplayName, value1],
//       [eligibilityRules[2].listValueDisplayName, value2],
//       [eligibilityRules[3].listValueDisplayName, value3],
//       [eligibilityRules[4].listValueDisplayName, value4],
//       [eligibilityRules[5].listValueDisplayName, value5],
//       [eligibilityRules[6].listValueDisplayName, value6],
//       [eligibilityRules[7].listValueDisplayName, value7],
//       [eligibilityRules[8].listValueDisplayName, value8],
//       [eligibilityRules[9].listValueDisplayName, value9],
//       [eligibilityRules[10].listValueDisplayName, value10],
//       [eligibilityRules[11].listValueDisplayName, value11],
//       [eligibilityRules[12].listValueDisplayName, value12],
//       [eligibilityRules[13].listValueDisplayName, value13],
//       [eligibilityRules[14].listValueDisplayName, value14],
//       [eligibilityRules[15].listValueDisplayName, value15],
//       [eligibilityRules[16].listValueDisplayName, value16],
//       [eligibilityRules[17].listValueDisplayName, value17],
//       [eligibilityRules[18].listValueDisplayName, value18],
//       [eligibilityRules[19].listValueDisplayName, value19],
//     ],
//     null,
//     "    "
//   )}
// </div>
//
//
// let eligibilityRules = [];
//
// for (let rules of filteredList) {
//   if (rules.child_operator_flag === "child") {
//     let dict1 = {
//       listValueKey: "",
//       listValueDisplayName: "",
//       listValueSortOrder: "",
//       listValueRightDisplayValue: "",
//     };
//
//     dict1.listValueKey = rules.attribute_key_name_parent;
//     dict1.listValueDisplayName = rules.attribute_key_display_name_parent;
//     dict1.listValueSortOrder = rules.attribute_list_value_sort_order_parent;
//     dict1.listValueRightDisplayValue = rules.attribute_key_display_name_child;
//     eligibilityRules.push(dict1);
//   }
// }
