import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { v4 as uuidv4 } from "uuid";
import { Storage } from "aws-amplify";
import { BsEject } from "react-icons/bs";
import { MdFilterListAlt } from "react-icons/md";
import Paper from "@mui/material/Paper";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill } from "react-icons/pi";
import { BiUpload } from "react-icons/bi";
import Alert from "@mui/material/Alert";

export default function FileUploadAccordion({
  benefitPlanId,
  setRunGetBenefitTableData,
  getBenefitTableData,
}) {
  let uploadStyles = { fontSize: "23px" };

  const [userMadeAlert, setUserMadeAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [date, setDate] = useState("");
  const [documentType, setDocumentType] = useState("");

  const onFileChange = (e) => {
    const files = e.target.files[0];
    if (files) {
      const updatedList = [files];
      setFileList(updatedList);
      // console.log(updatedList);
    }
  };
  const handleFileName = (event) => {
    setFileName(event.target.value);
  };

  const sendEmptyDocumentTypeAlert = () => {
    alert("Please Select Document Type");
  };

  const uploadFile = async () => {
    // let resultJSON = benefit;
    // let benefitProgram = localStorage.getItem("benefitProgramFromBenefitPage1").replace(/['"]+/g, '')
    let mixOfChar =
      localStorage
        .getItem("benefitProgramFromBenefitPage1")
        .replace(/['"]+/g, "") +
      localStorage.getItem("contryFromBenefitPage1").replace(/['"]+/g, "") +
      localStorage.getItem("planNameFromBenefitPage1").replace(/['"]+/g, "");

    let companyName = localStorage
      .getItem("companyFromBenefitPage1")
      .replace(/['"]+/g, "");
    console.log(companyName);
    console.log(documentType);

    let realDate = `${date.$y}-${
      date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`
    }-${date.$D > 9 ? date.$D : `0${date.$D}`}`;

    console.log("red", realDate);

    // console.log(benefitProgram)
    // console.log(benefitProgram.replace(/['"]+/g, ''));

    // let file_name =
    //   "program_design_documentation/" +
    //   companyName.replaceAll('^"|"$', "") +
    //   "/" +
    //   mixOfChar.replaceAll('^"|"$', "") +
    //   "/" +
    //   fileList[0].name;

    let file_name =
      "program_design_documentation/" +
      companyName.replaceAll('^"|"$', "") +
      "/" +
      benefitPlanId +
      "/" +
      documentType +
      "/" +
      realDate +
      "/" +
      uuidv4() +
      "/" +
      fileList[0].name;

    // console.log(uuidv4())
    // "run_report_output/" + companyName.replaceAll("^\"|\"$", "")+"/" +benefitProgram.replaceAll("^\"|\"$", "") +"/"+fileList[0].name;
    setRunGetBenefitTableData(true);
    console.log(file_name);
    const result = await Storage.put(file_name, fileList[0], {
      contentType: fileList[0].type,
    }).then((data) => {
      console.log("Finished File Upload Process. Printing Status");
      // console.log(resultJSON);
      console.log(data);
    });
    getBenefitTableData();
    return result;
  };
  // if (data.key !== undefined) {
  //   setUploadFilePath(data.key);
  //   console.log("File Uploaded" + data.key);
  //   createTemplateS2Metadata(data.key);
  // } else {
  //   console.log("The File Upload Failed");
  // }

  const handleSave = async (e) => {
    console.log(documentType.length);
    e.preventDefault();
    if (documentType.length > 1) {
      await uploadFile();
      setAlertStatus(true);
      setUserMadeAlert("success");
      console.log("File Uploaded by the User", fileList);
      console.log("User Give Name", fileName);
    } else {
      alert("Document Types is Requried");
    }
    // if (date.$y === null || date.$D === null) {
    //   alert("Fill The Date");
    // } else {
    //   getAnnualHealthReimbursementAccountsMappedValues(finalList);
    // }
  };
  const documentTypeList = [
    {
      listValueKey: "Policy Documents",
      listValueDisplayName: "Policy Documents",
    },
    { listValueKey: "Legal Contract", listValueDisplayName: "Legal Contract" },
    {
      listValueKey: "Policy Addendum",
      listValueDisplayName: "Policy Addendum",
    },
    { listValueKey: "Plan Summary", listValueDisplayName: "Plan Summary" },
    {
      listValueKey: "Broker Commissions Schedule",
      listValueDisplayName: "Broker Commissions Schedule",
    },
  ];
  const handleDocumentChange = (event) => {
    setDocumentType(event.target.value);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Policy and Plan Documents</Typography>
      </AccordionSummary>
      <div className="mx-8">
        {userMadeAlert === "success" && alertStatus ? (
          <Alert onClose={() => setAlertStatus(false)} severity="success">
            Success! The details provided have been stored
          </Alert>
        ) : userMadeAlert === "error" && alertStatus ? (
          <Alert onClose={() => setAlertStatus(false)} severity="error">
            Please, Fill all the fields
          </Alert>
        ) : (
          ""
        )}
      </div>

      <AccordionDetails className="grid grid-cols-2 gap-8 mx-5 mb-4 mt-10">
        <FormControl name="fullWidth2" fullWidth>
          <DatePicker
            label="As Of Date"
            name="date"
            value={date}
            onChange={(date) => setDate(date)}
            format="YYYY-DD-MM"
          />
        </FormControl>

        <FormControl name="fullWidth2" fullWidth></FormControl>

        <FormControl fullWidth className="cursor-pointer">
          <div className="bg-gray-100 border border-gray-400 rounded-md h-full w-full cursor-pointer">
            <div className="relative w-full h-full flex justify-center items-center cursor-pointer">
              <div className="flex w-full justify-center items-center">
                <BiUpload style={uploadStyles} />
                <p className="text-lg cursor-pointer text-black pl-4">
                  Upload file
                </p>
              </div>
              <input
                type="file"
                className="opacity-0 absolute h-full w-full cursor-pointer"
                onChange={onFileChange}
                name="uploadedFiles"
                id="uploadedFiles"
                accept=".doc, .docx,.pdf"
              />
            </div>
            <div className="flex justify-center text-gray-500 mt-1">
              {fileList.map((file, key) => (
                <p key={key}>{file.name}</p>
              ))}
            </div>
          </div>
        </FormControl>

        <FormControl name="fullWidth5" fullWidth>
          <InputLabel id="USMedicalPlanInvoiceFrequency2">
            Document Type
          </InputLabel>

          <Tooltip title={"Document Type"}>
            <Select
              labelId="USMedicalPlanInvoiceFrequency3"
              id="USMedicalPlanInvoiceFrequency4"
              value={documentType}
              label="Document Type"
              onChange={handleDocumentChange}
            >
              {documentTypeList.map((item, key) => (
                <MenuItem value={item.listValueKey} key={key}>
                  {item.listValueDisplayName}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>
        </FormControl>

        <FormControl fullWidth></FormControl>
        <div className="mt-5 w-full flex justify-end items-center">
          <button
            type="submit"
            className="ml-5 bg-[#0000A3] py-[6px]   shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg    px-6  text-white"
            onClick={(e) => {
              handleSave(e);
            }}
          >
            Save
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

// <div>User Given File Name : {fileName}</div>

// <FormControl fullWidth>
//   <TextField
//     label="Document Type"
//     id="outlined-basic"
//     value={fileName}
//     variant="outlined"
//     onChange={handleFileName}
//
// </FormControl>
//   />

// <Tooltip title={""}>
//   <TextField
//     label="F"
//     id="outlined-basic"
//     value={""}
//     variant="outlined"
//     inputProps={{ readOnly: true }}
//   />
// </Tooltip>
