import { React, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import DropDownLinks from "./DropDownLinks";
import { NavLink } from "react-router-dom";
import { BsFillCaretRightFill } from "react-icons/bs";
import {
  DashboardRounded,
  AssessmentRounded,
  BusinessRounded,
  DragIndicatorRounded,
  SettingsInputComponentRounded,
} from "@mui/icons-material";
import { MdOutlineReorder } from "react-icons/md";

export default function Sidebar() {
  const [burger, setBurger] = useState(true);
  const [companyNav, setCompanyNav] = useState(false);
  const [designNav, setDesignNav] = useState(false);
  const [integrateNav, setIntegrateNav] = useState(false);
  const [reportNav, setReportNav] = useState(false);
  const [dashboardNav, setDashboardNav] = useState(false);
  const [communicateNav, setCommunicateNav] = useState(false);
  const [companyState, setCompanyState] = useState(false);
  const [designState, setDesignState] = useState(false);
  const [integrateState, setIntegrateState] = useState(false);
  const [reportState, setReportState] = useState(false);
  const [dashboardState, setDashboardState] = useState(false);
  const [communicateState, setCommunicateState] = useState(false);

  const handleBurger = () => {
    setBurger(!burger);
  };

  const handleCompanyClick = () => {
    setCompanyNav(!companyNav);
    setCompanyState(true);
    setDesignState(false);
    setIntegrateState(false);
    setReportState(false);
    setDashboardState(false);
    setCommunicateState(false);
  };

  const handleDesignClick = () => {
    setDesignNav(!designNav);
    setCompanyState(false);
    setDesignState(true);
    setIntegrateState(false);
    setReportState(false);
    setDashboardState(false);
    setCommunicateState(false);
  };

  const handleIntegrateClick = () => {
    setIntegrateNav(!integrateNav);
    setCompanyState(false);
    setDesignState(false);
    setIntegrateState(true);
    setReportState(false);
    setDashboardState(false);
    setCommunicateState(false);
  };

  const handleReportClick = () => {
    setReportNav(!reportNav);
    setCompanyState(false);
    setDesignState(false);
    setIntegrateState(false);
    setReportState(true);
    setDashboardState(false);
    setCommunicateState(false);
  };

  const handleDashboardClick = () => {
    setDashboardNav(!dashboardNav);
    setCompanyState(false);
    setDesignState(false);
    setIntegrateState(false);
    setReportState(false);
    setDashboardState(true);
    setCommunicateState(false);
  };

  const handleCommunicateClick = () => {
    setCommunicateNav(!communicateNav);
    setCompanyState(false);
    setDesignState(false);
    setIntegrateState(false);
    setReportState(false);
    setDashboardState(false);
    setCommunicateState(true);
  };

  return (
    <div className="flex w-[213px] mr-8 bg-white mt-[2px] min-h-[90vh] max-h-[full]"
// flex w-[213px] mr-8 bg-white mt-[2px] h-[90vh]
      >
      <div className="flex">
      
        {/*burger ? (
          <div onClick={handleBurger} className="hover:cursor-pointer p-2 ">
            <AiOutlineClose size={25} />
          </div>
        ) : (
          <div onClick={handleBurger} className="hover:cursor-pointer p-2 ">
            <MdOutlineReorder size={25} />
          </div>
        )*/}

        {burger && (
          <div className="flex w-full">
            <div className="h-full w-[1px] bg-[#7A7A7A]" />
            <div className="pl-9 w-full mt-20">
              <div className="mt-4 flex top-0 hover:bg-gray-100">
                <DropDownLinks
                  pageLink="/"
                  show={companyState}
                  clickFunction={handleCompanyClick}
                  linkName="Company"
                  displayBool={companyNav}
                  ln1="Create Company"
                  to1="/company/create-company"
                  ln2="Manage Company"
                  to2="/company/manage-company"
                  ln3="Manage Permissions"
                  to3="/company/manage-permissions"
                  ln4="Create User"
                  to4="/company/create-user"
                />
              </div>

              <div className="mt-14 flex hover:bg-gray-100">
                <DropDownLinks
                  pageLink="/design"
                  clickFunction={handleDesignClick}
                  linkName="Design"
                  show={designState}
                  displayBool={designNav}
                  ln1="Create Benefit"
                  to1="/design/createbenefits1"
                  ln2="Inventory"
                  to2="/design/benefitinventory"
                />
              </div>

              <div className="mt-14 flex hover:bg-gray-100">
                <DropDownLinks
                  pageLink="/integrate"
                  show={integrateState}
                  clickFunction={handleIntegrateClick}
                  linkName="Integrate"
                  displayBool={integrateNav}
                  ln1="Create Template"
                  to1="/integrate/createtemplates1"
                  ln2="Create E-Form Template"
                  to2="/integrate/uploadtemplates2"
                  ln3="Manage Template"
                  to3="/integrate/manage-template"
                  ln4="Upload File"
                  to4="/integrate/uploadtemplates1"
                  ln5="EForm Upload"
                  to5="/integrate/eformuploads1"
                  // ln5="E-Form Upload"
                  // to5="/integrate/eformuploads1"
                />
              </div>

              <div className="mt-14 flex hover:bg-gray-100">
                <DropDownLinks
                  pageLink="/report"
                  show={reportState}
                  clickFunction={handleReportClick}
                  linkName="Report"
                  displayBool={reportNav}
                  ln1="Run Report"
                  to1="/report/runreport"
                  ln2="Manage Reports"
                  to2="/report/manage-reports"
                />
              </div>

              <div className="mt-14 flex hover:bg-gray-100">
                <div>
                  <AssessmentRounded
                    fontSize="small"
                    style={{ marginTop: "6x", paddingTop: "2x" }}
                  />
                </div>
                <div>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "my-2 mx-2 text-lg font-semibold"
                        : "my-2 mx-2 text-lg hover:font-semibold"
                    }
                    to="/dashboard"
                  >
                    Dashboard
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {burger ? (
        <div className="" />
      ) : (
        <div className="ml-2 bg-[#7A7A7A] w-[1px]" />
      )}
    </div>
  );
}

        // <div className="ml-10 bg-[#7A7A7A] w-[1px]" />

// <DropDownLinks
//   pageLink="/dashboard"
//   show={dashboardState}
//   clickFunction={handleDashboardClick}
//   linkName="Dashboard"
//   displayBool={dashboardNav}
//   ln1="op1"
//   to1="/in-development"
//   ln2="op2"
//   to2="/in-development"
//   ln3="op3"
//   to3="/in-development"
//   ln4="op4"
//   to4="/in-development"
// />

// <hr className="my-1"/>
//   <div className="mt-4 flex ">
//     <DropDownLinks
//       pageLink="/communicate"
//       show={communicateState}
//       clickFunction={handleCommunicateClick}
//       linkName="Communicate"
//       displayBool={communicateNav}
//       ln1="op1"
//       to1="/in-development"
//       ln2="op2"
//       to2="/in-development"
//       ln3="op3"
//       to3="/in-development"
//       ln4="op4"
//       to4="/in-development"
//     />
//   </div>
