import React from "react";
import { NavLink } from "react-router-dom";
import { BsFillCaretRightFill } from "react-icons/bs";
import {
  DashboardRounded,
  AssessmentRounded,
  BusinessRounded,
  DragIndicatorRounded,
  SettingsInputComponentRounded,
} from "@mui/icons-material";

export default function DropDownLinks({
  show,
  clickFunction,
  linkName,
  displayBool,
  ln1,
  to1,
  ln2,
  to2,
  ln3,
  to3,
  ln4,
  to4,
}) {
  return (
    <div className="flex">
      <div className="hover:cursor-pointer" onClick={clickFunction}>
        {linkName === "Company" ? (
          <BusinessRounded
            fontSize="small"
            style={{ marginTop: "6x", paddingTop: "2x" }}
          />
        ) : linkName === "Design" ? (
          <DragIndicatorRounded
            fontSize="small"
            style={{ marginTop: "6x", paddingTop: "2x" }}
          />
        ) : linkName === "Integrate" ? (
          <SettingsInputComponentRounded
            fontSize="small"
            style={{ marginTop: "6x", paddingTop: "2x" }}
          />
        ) : linkName === "Report" ? (
          <DashboardRounded
            fontSize="small"
            style={{ marginTop: "6x", paddingTop: "2x" }}
          />
        ) : linkName === "Dashboard" ? (
          <AssessmentRounded
            fontSize="small"
            style={{ marginTop: "6x", paddingTop: "2x" }}
          />
        ) : linkName === "Communicate" ? (
          <BusinessRounded
            fontSize="small"
            style={{ marginTop: "6x", paddingTop: "2x" }}
          />
        ) : (
          ""
        )}
      </div>
      <div>
        <NavLink
          onClick={clickFunction}
          end
          className="my-2 mx-2 text-lg hover:font-semibold"
          // className={({ isActive }) =>
          //   isActive ? "my-2 mx-2 text-lg hover:font-bold" : "my-2 mx-2 "
          // }
        >
          {linkName}
        </NavLink>

        {show && displayBool && (
          <div className="mx-2 mt-4 ">
            <NavLink
              to={to1}
              className={({ isActive }) =>
                isActive
                  ? "text-sm flex font-bold mt-1 text-gray-800 "
                  : "text-sm text-gray-400 flex hover:font-bold mt-1 "
              }
            >
              {ln1}
            </NavLink>

            <NavLink
              to={to2}
              className={({ isActive }) =>
                isActive
                  ? "text-sm flex  text-gray-800 font-bold mt-1"
                  : "text-sm text-gray-400 flex hover:font-bold mt-1 "
              }
            >
              {ln2}
            </NavLink>

            <NavLink
              to={to3}
              className={({ isActive }) =>
                isActive
                  ? "text-sm flex text-gray-800 font-bold mt-1"
                  : "text-sm text-gray-400 flex hover:font-bold mt-1 "
              }
            >
              {ln3}
            </NavLink>

            <NavLink
              to={to4}
              className={({ isActive }) =>
                isActive
                  ? "text-sm flex font-bold mt-1"
                  : "text-sm  text-gray-400 flex hover:font-bold mt-1 "
              }
            >
              {ln4}
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}
// <BsFillCaretRightFill style={{ marginTop: "6x", paddingTop: "2x" }} />
