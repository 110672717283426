import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Link as MuiLink } from "@mui/material";
import Alerts from "../../components/Alerts.jsx";
import ChatBot from "../../components/ChatBot.jsx";
import { IContext } from "../../context/IntegrateContext.jsx";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries.js";
import { API } from "aws-amplify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { v4 as uuidv4 } from "uuid";

export default function RunReport({ user, signOut }) {
  const [alert, setAlert] = useState("");
  const [date, setDate] = useState("");
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportFilterList, setReportFilterList] = useState([]);
  const [wait, setWait] = useState(true);
  const [filterWait, setFilterWait] = useState(true);
  // const [programType, setProgramType] = useState("");
  const [countryClick, setCountryClick] = useState(false);
  const [cadClick, setCADclick] = useState(false);
  const [ipctClick, setIPCTClick] = useState(false);
  const [genderClick, setGenderClick] = useState(false);
  const [iniClick, setINIClick] = useState(false);
  const [mssClick, setMSSClick] = useState(false);
  const [cmtClick, setCMTClick] = useState(false);
  const [cpdClick, setCPDClick] = useState(false);
  const [costCategoryClick, setCostCategoryClick] = useState(false);
  const [benefitProgramClick, setBenefitProgramClick] = useState(false);
  const [costTypeClick, setCostTypeClick] = useState(false);
  const [beginDateClick, setBeginDateClick] = useState(false);
  const [endDateClick, setEndDateClick] = useState(false);
  const [headCountClick, setheadCountClick] = useState(false);
  const [planPaidAmoutClick, setplanPaidAmoutClick] = useState(false);
  const [amountClick, setamountClick] = useState(false);
  const [openDialog,setopenDialog] = useState(false);

  const [operator0, setOperator0] = useState("");
  const [operator1, setOperator1] = useState("");
  const [operator2, setOperator2] = useState("");
  const [operator3, setOperator3] = useState("");
  const [operator4, setOperator4] = useState("");
  const [operator5, setOperator5] = useState("");
  const [operator6, setOperator6] = useState("");
  const [operator7, setOperator7] = useState("");
  const [operator8, setOperator8] = useState("");
  const [operator9, setOperator9] = useState("");
  const [operator10, setOperator10] = useState("");
  const [operator11, setOperator11] = useState("");
  const [operator12, setOperator12] = useState("");
  const [operator13, setOperator13] = useState("");
  const [operator14, setOperator14] = useState("");
  const [operator15, setOperator15] = useState("");

  const [value0, setValue0] = useState([]);
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [value4, setValue4] = useState([]);
  const [value5, setValue5] = useState([]);
  const [value6, setValue6] = useState([]);
  // const [value7, setValue7] = useState([]);
  const [value8, setValue8] = useState([]);
  const [value9, setValue9] = useState([]);
  const [value10, setValue10] = useState([]);
  // const [value11, setValue11] = useState([]);
  // const [value12, setValue12] = useState([]);
  const [value13, setValue13] = useState([]);
  const [value14, setValue14] = useState([]);
  const [value15, setValue15] = useState([]);

  // const [andor1, setAndOr1] = useState("");
  // const [andor2, setAndOr2] = useState("");
  // const [andor3, setAndOr3] = useState("");
  // const [andor4, setAndOr4] = useState("");
  // const [andor5, setAndOr5] = useState("");
  // const [andor6, setAndOr6] = useState("");
  // const [andor7, setAndOr7] = useState("");
  // const [andor8, setAndOr8] = useState("");

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handleReportTypeChange = async (event) => {
    setReportType(event.target.value);
  };
  const handleReportNameChange = (event) => {
    setReportName(event.target.value);
  };
  // const handleProgramTypeChange = (event) => {
  //   setProgramType(event.target.value);
  // };
  const handleValue0Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue0(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue1Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue1(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue2Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue2(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue3Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue3(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue4Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue4(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue5Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue5(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue6Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue6(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue8Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue8(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue9Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue9(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue10Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue10(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue13Change = (event) => {
    setValue13(event.target.value);
  };
  const handleValue14Change = (event) => {
    const {
      target: { value },
    } = event;
    setValue14(typeof value === "string" ? value.split(",") : value);
  };
  const handleValue15Change = (event) => {
    setValue15(event.target.value);
  };

  const handleOperator0Change = (event) => {
    setOperator0(event.target.value);
  };
  const handleOperator1Change = (event) => {
    setOperator1(event.target.value);
  };
  const handleOperator2Change = (event) => {
    setOperator2(event.target.value);
  };
  const handleOperator3Change = (event) => {
    setOperator3(event.target.value);
  };
  const handleOperator4Change = (event) => {
    setOperator4(event.target.value);
  };
  const handleOperator5Change = (event) => {
    setOperator5(event.target.value);
  };
  const handleOperator6Change = (event) => {
    setOperator6(event.target.value);
  };
  const handleOperator7Change = (event) => {
    setOperator7(event.target.value);
  };
  const handleOperator8Change = (event) => {
    setOperator8(event.target.value);
  };
  const handleOperator9Change = (event) => {
    setOperator9(event.target.value);
  };
  const handleOperator10Change = (event) => {
    setOperator10(event.target.value);
  };
  const handleOperator11Change = (event) => {
    setOperator11(event.target.value);
  };
  const handleOperator12Change = (event) => {
    setOperator12(event.target.value);
  };
  const handleOperator13Change = (event) => {
    setOperator13(event.target.value);
  };
  const handleOperator14Change = (event) => {
    setOperator14(event.target.value);
  };
  const handleOperator15Change = (event) => {
    setOperator15(event.target.value);
  };
  // const handleAndOr1Change = (event) => {
  //   setAndOr1(event.target.value);
  // };
  // const handleAndOr2Change = (event) => {
  //   setAndOr2(event.target.value);
  // };
  // const handleAndOr3Change = (event) => {
  //   setAndOr3(event.target.value);
  // };
  // const handleAndOr4Change = (event) => {
  //   setAndOr4(event.target.value);
  // };
  // const handleAndOr5Change = (event) => {
  //   setAndOr5(event.target.value);
  // };
  // const handleAndOr6Change = (event) => {
  //   setAndOr6(event.target.value);
  // };
  // const handleAndOr7Change = (event) => {
  //   setAndOr7(event.target.value);
  // };

  // let numberStylesOn = { padding: "2px", color: "#0000A3", fontSize: "30px" };
  // let numberStylesOut = { padding: "2px", color: "#AEB3B7", fontSize: "30px" };

  const { tempCompanyNames } = IContext();

  // const { tempCompanyNames, templateAndbenefitprogramTypeList } = IContext();
  // console.log(tempCompanyNames);

  let companyNameList = [];
  for (var cn of tempCompanyNames) {
    let tempCompanyName = {
      company_id: "",
      company_name: "",
    };
    tempCompanyName.company_id = cn.company_id;
    tempCompanyName.company_name = cn.company_name;
    companyNameList.push(tempCompanyName);
  }
  useEffect(() => {
    setWait(true);
    setTimeout(() => {
      setWait(false);
    }, 2000);
    return () => {};
  }, []);
  // if( companyNameList.length > 1 && templateAndbenefitprogramTypeList.length > 1 ){
  //     setWait(false)
  // }
  // console.log(templateAndbenefitprogramTypeList);
  // query ReadReportType {
  //
  //   readReportType {
  //     report_category_display_name
  //     report_category
  //     report_category_sort_order
  //     report_type_display_name
  //     report_type_sort_order
  //     report_type
  //   }
  // }

  const [typeAndName, setTypeAndName] = useState([]);
  useEffect(() => {
    const getBenefitAttributes = async () => {
      const benefitAttributes = await API.graphql({
        query: queries.readReportType,
      });

      setTypeAndName(benefitAttributes.data.readReportType);
      setWait(false);
    };

    getBenefitAttributes();
  }, []);
  // console.log("tn",typeAndName);
  const [granularity, setGranularity] = useState("");
  const [aggregatedColumnList, setAggregatedColumnList] = useState("");
  const [selectedAllCols, setSelectedAllCols] = useState("");

  const getReportFilters = async () => {
    const reportFilters = await API.graphql({
      query: mutations.StandardReportFilter,
      variables: {
        report_type: reportName,
      },
    });
    setReportFilterList(reportFilters.data.StandardReportFilter);
    for (var rn of typeAndName) {
      // console.log(rn, reportName)
      if (rn.report_category === reportName) {
        setGranularity(rn.granularity);
        setSelectedAllCols(rn.is_select_all_cols);
        setAggregatedColumnList(rn.aggregated_column_list);
      }
    }

    setFilterWait(false);
  };
  if (
    reportType.length > 1 &&
    reportName.length > 1 &&
    reportFilterList.length === 0
  ) {
    getReportFilters();
  }

  // console.log(granularity, selectedAllCols)

  // console.log("reportFilterList", reportFilterList);
  // console.log("reportFilterList", reportFilterList);
  let varcharList = [];
  let integerList = [];
  let dupvarcharList = [];
  let dupintegerList = [];
  let countryList = [];
  let targetClaimDecisionList = [];
  let targetCoverageTierList = [];
  let targetGenderList = [];
  let targetInNetworkFlagList = [];
  let targetServiceSettingList = [];
  let targetRecordTypeList = [];
  let costCategoryList = [];
  let duplicatecostCategoryList = [];
  let benefitProgramList = [];
  let duplicatebenefitProgramList = [];
  let costTypeList = [];
  let duplicatecostTypeList = [];
  let duplicatecountryList = [];
  let duplicatetargetClaimDecisionList = [];
  let duplicatetargetCoverageTierList = [];
  let duplicatetargetGenderList = [];
  let duplicatetargetInNetworkFlagList = [];
  let duplicatetargetServiceSettingList = [];
  let duplicatetargetRecordTypeList = [];
  let duplicateHeadcountList = [];
  let headCountList = [];
  let uniqueArray = [];
  let displayFilterOptions = [];

  for (var dfo of reportFilterList) {
    if (displayFilterOptions.includes(dfo.filter_display_name)) {
    } else {
      displayFilterOptions.push(dfo.filter_display_name);
    }
  }
  // console.log("capital", displayFilterOptions);

  for (var dk of reportFilterList) {
    if (uniqueArray.includes(dk.filter)) {
    } else {
      uniqueArray.push(dk.filter);
    }
  }
  // console.log("uniqueArray", uniqueArray);

  // console.log("rfl", reportFilterList);

  let tempReportTypeList = [];
  for (var t2 of typeAndName) {
    let temp3 = {
      template_id: "",
      template_display_name: "",
      template_sort_order: "",
    };
    temp3.template_id = t2.report_type;
    temp3.template_display_name = t2.report_type_display_name;
    temp3.template_sort_order = t2.report_type_sort_order;
    tempReportTypeList.push(temp3);
  }
  let reportTypeList = [];
  const uniqueMap3 = new Map();
  for (const obj of tempReportTypeList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap3.has(objString)) {
      uniqueMap3.set(objString, true);
      reportTypeList.push(obj);
    }
  }

  let tempReportNameList = [];

  for (var t3 of typeAndName) {
    if (t3.report_type === reportType) {
      let temp3 = {
        template_id: "",
        template_display_name: "",
        template_sort_order: "",
      };
      temp3.template_id = t3.report_category;
      temp3.template_display_name = t3.report_category_display_name;
      temp3.template_sort_order = t3.report_category_sort_order;
      tempReportNameList.push(temp3);
    }
  }

  let reportNameList = [];
  const uniqueMap4 = new Map();
  for (const obj of tempReportNameList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap4.has(objString)) {
      uniqueMap4.set(objString, true);
      reportNameList.push(obj);
    }
  }

  for (var ov of reportFilterList) {
    if (ov.operator === "operator_varchar") {
      let temp3 = {
        key: "",
        displayName: "",
        sortOrder: "",
      };
      temp3.key = ov.operator_value;
      temp3.displayName = ov.operator_display_name;
      // temp3.sortOrder = ov.filter_display_name_sort_order;
      dupvarcharList.push(temp3);
    }
  }

  const uniqueMap6 = new Map();
  for (const obj of dupvarcharList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap6.has(objString)) {
      uniqueMap6.set(objString, true);
      varcharList.push(obj);
    }
  }

  for (var oi of reportFilterList) {
    if (oi.operator === "operator_integer") {
      let temp3 = {
        key: "",
        displayName: "",
        sortOrder: "",
      };
      temp3.key = oi.operator_value;
      temp3.displayName = oi.operator_display_name;
      // temp3.sortOrder = oi.filter_display_name_sort_order;
      dupintegerList.push(temp3);
    }
  }

  const uniqueMap7 = new Map();
  for (const obj of dupintegerList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap7.has(objString)) {
      uniqueMap7.set(objString, true);
      integerList.push(obj);
    }
  }

  if (uniqueArray.includes("country")) {
    for (let c of reportFilterList) {
      if (c.filter === "country") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatecountryList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatecountryList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        countryList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("target_claim_decision")) {
    for (let c of reportFilterList) {
      if (c.filter === "target_claim_decision") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatetargetClaimDecisionList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatetargetClaimDecisionList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        targetClaimDecisionList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("target_coverage_tier")) {
    for (let c of reportFilterList) {
      if (c.filter === "target_coverage_tier") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatetargetCoverageTierList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatetargetCoverageTierList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        targetCoverageTierList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("target_gender")) {
    for (let c of reportFilterList) {
      if (c.filter === "target_gender") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatetargetGenderList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatetargetGenderList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        targetGenderList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("target_in_network_flag")) {
    for (let c of reportFilterList) {
      if (c.filter === "target_in_network_flag") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatetargetInNetworkFlagList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatetargetInNetworkFlagList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        targetInNetworkFlagList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("target_service_setting")) {
    for (let c of reportFilterList) {
      if (c.filter === "target_service_setting") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatetargetServiceSettingList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatetargetServiceSettingList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        targetServiceSettingList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("target_record_type")) {
    for (let c of reportFilterList) {
      if (c.filter === "target_record_type") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatetargetRecordTypeList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatetargetRecordTypeList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        targetRecordTypeList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("cost_category")) {
    for (let c of reportFilterList) {
      if (c.filter === "cost_category") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatecostCategoryList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatecostCategoryList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        costCategoryList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("benefit_id")) {
    for (let c of reportFilterList) {
      if (c.filter === "benefit_id") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatebenefitProgramList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatebenefitProgramList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        benefitProgramList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("cost_type")) {
    for (let c of reportFilterList) {
      if (c.filter === "cost_type") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicatecostTypeList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicatecostTypeList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        costTypeList.push(obj);
      }
    }
  }

  if (uniqueArray.includes("headcount_type")) {
    for (let c of reportFilterList) {
      if (c.filter === "headcount_type") {
        let dict1 = {
          key: "",
          displayName: "",
          sortOrder: "",
        };
        dict1.key = c.filter_value;
        dict1.displayName = c.filter_value_display_name;
        dict1.sortOrder = c.filter_display_name_sort_order;
        duplicateHeadcountList.push(dict1);
      }
    }
    const uniqueMap5 = new Map();
    for (const obj of duplicateHeadcountList) {
      const objString = JSON.stringify(obj);
      if (!uniqueMap5.has(objString)) {
        uniqueMap5.set(objString, true);
        headCountList.push(obj);
      }
    }
  }

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "40px" };

  let navigate = useNavigate();

  useEffect(() => {});
  const duplicateFinalList = [
    ["country", operator0, value0],
    ["target_claim_decision", operator1, value1],
    ["target_coverage_tier", operator2, value2],
    ["target_gender", operator3, value3],
    ["target_in_network_flag", operator4, value4],
    ["target_service_setting", operator5, value5],
    ["target_record_type", operator6, value6],
    ["claim_paid_date", operator7, [date.$y, date.$M + 1, date.$D]],
    ["cost_category", operator8, value8],
    ["benefit_id", operator9, value9],
    ["cost_type", operator10, value10],
    ["begin_date", operator11, [beginDate.$y, beginDate.$M + 1, beginDate.$D]],
    ["endDate", operator12, [endDate.$y, endDate.$M + 1, endDate.$D]],
    ["amount", operator13, value13],
    // ["headcount_type", operator14, value14],
    // ["amount", operator13, value13],
  ];

  let finalList = [];

  for (var i of duplicateFinalList) {
    if (i[1].length > 0) {
      finalList.push(i);
    }
  }
  // console.log("final", finalList);
  // console.log("dfo", displayFilterOptions);

  const handleSave = async (e) => {
    if (
      companyName < 1 ||
      reportName < 1 ||
      reportType < 1
      // programType < 1
    ) {
      e.preventDefault();
      setAlert("error");
    } else {
      e.preventDefault();
      let postList = [];
      let reportRequestId = uuidv4();
      for (var item of finalList) {
        // console.log(item);
        const targetJson = {
          company_id: companyName,
          field_name: "na",
          filter_operator: "na",
          filter_value_list: [],
          is_and_or: "and",
          report_id: reportName,
          report_request_id: reportRequestId,
          report_type: reportType,
        };
        targetJson.field_name = item[0];
        targetJson.filter_operator = item[1];
        targetJson.filter_value_list = item[2];
        postList.push(targetJson);
      }

      console.log("api", postList);

      const sendReportParameters = await API.graphql({
        query: mutations.createReportInput,
        variables: {
          company_id: companyName,
          report_request_id: reportRequestId,
          report_type: reportType,
          report_id: reportName,
          is_select_all_column: selectedAllCols,
          column_list: "",
          granularity: granularity,
          aggregated_column_list: aggregatedColumnList,
          create_userid: user,
          is_delete: "",
          delete_userid: "",
        },
      }).then((data) => {
        // console.log(data);
      });

      const sendReportFilters = await API.graphql({
        query: mutations.createReportFilter,
        variables: {
          report_filter_data: postList,
        },
      }).then((data) => {
        // console.log(data);
      });

      await setAlert("success");
      setopenDialog(true);
      // window.location.reload();
      // navigate("/integrate/uploadtemplateS3");
    }
  };

  const handleClear = () => {
    window.location.reload(false);
  };

  const handleDialogClose = () =>{
    setopenDialog(false);
    window.location.reload();
  }

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
            input[type="checkbox"]{accent-color:#0000A3;}
          `}</style>
      {/* <Navbar user={user} signOut={signOut} />
      <div className="flex">
        <div>
          <Sidebar />
        </div> */}
        {/* <SimpleDialog
          open={openDialog}
          onClose={handleDialogClose}
        /> */}
        <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        >
          <DialogTitle><b>Success</b></DialogTitle>
          <DialogContent>
            <p>Your report creation request is successful!</p>
            Click here to go to <MuiLink component={Link} to="/report/manage-reports">Manage Reports</MuiLink> to track the report
          </DialogContent>
          <DialogActions>
            <Button className="bg-[#0000A3] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white" onClick={handleDialogClose} variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <div className="2xl:ml-[15rem] ml-10">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/report" className="px-2">
              Report
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/report">
              Standard Report
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/report/runreport">
              Create Report
            </Link>
          </div>
          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <MdOutlineAutoFixHigh style={iconStyles} />
              <p className="text-3xl mx-1">Run Report</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Successfully, Proceed to upload the file in next page"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Please fill all the fields" />
            ) : (
              ""
            )}
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>
          {reportName.length > 1 ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={filterWait}
            >
              <CircularProgress sx={{ color: "#DB2748" }} />{" "}
            </Backdrop>
          ) : (
            ""
          )}
          {/* form*/}
          <div className="flex mt-5 ">
            <div className="w-[900px] ml-10 mt-4">
              <Paper elevation={2} className="mb-4">
                <div className="px-10 py-10">
                  <div className="flex justify-center">
                    <div className="font-medium text-2xl">
                      Report Parameters
                    </div>
                  </div>
                  <form className="ml-4 grid grid-cols-3 gap-8 mt-8">
                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS22">
                        Company Name
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS23"
                        id="UploadTemplateS24"
                        value={companyName}
                        label="Company Name"
                        onChange={handleCompanyNameChange}
                      >
                        {companyNameList.map((option, key) => (
                          <MenuItem value={option.company_id} key={key}>
                            {option.company_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS26">
                        Report Type
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS27"
                        id="UploadTemplateS28"
                        value={reportType}
                        label="Report Type"
                        onChange={handleReportTypeChange}
                      >
                        {reportTypeList.map((option, key) => (
                          <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS29">
                        Report Name
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS37"
                        id="UploadTemplateS38"
                        value={reportName}
                        label="Report Name"
                        onChange={handleReportNameChange}
                      >
                        {reportNameList.map((option, key) => (
                          <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </form>
                </div>
                {/*
                                    *
                          
                                          <div>{companyName}</div>
                                          <div>{reportType}</div>
                                          <div>{reportName}</div>
                                    * */}
              </Paper>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Report Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="px-10 py-10">
                    <div className="flex justify-center">
                      <div className="font-bold text-2xl">Report Filters</div>
                    </div>

                    <div className="grid grid-cols-3 mt-8 mb-4">
                      {displayFilterOptions.includes("Country") && (
                        <div
                          className={
                            countryClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setCountryClick(!countryClick)}
                        >
                          Country
                        </div>
                      )}
                      {displayFilterOptions.includes(
                        "Claim adjudication decision",
                      ) && (
                        <div
                          className={
                            cadClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setCADclick(!cadClick)}
                        >
                          Claim adjudication decision
                        </div>
                      )}
                      {displayFilterOptions.includes(
                        "Insurance policy coverage tier",
                      ) && (
                        <div
                          className={
                            ipctClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setIPCTClick(!ipctClick)}
                        >
                          Insurance policy coverage tier
                        </div>
                      )}
                      {displayFilterOptions.includes("Gender") && (
                        <div
                          className={
                            genderClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setGenderClick(!genderClick)}
                        >
                          Gender
                        </div>
                      )}
                      {displayFilterOptions.includes(
                        "In Network Indicator",
                      ) && (
                        <div
                          className={
                            iniClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setINIClick(!iniClick)}
                        >
                          In Network Indicator
                        </div>
                      )}
                      {displayFilterOptions.includes(
                        "Medical Service Setting",
                      ) && (
                        <div
                          className={
                            mssClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setMSSClick(!mssClick)}
                        >
                          Medical Service Setting
                        </div>
                      )}
                      {displayFilterOptions.includes("Covered Member Type") && (
                        <div
                          className={
                            cmtClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setCMTClick(!cmtClick)}
                        >
                          Covered Member Type
                        </div>
                      )}
                      {displayFilterOptions.includes("Claim paid Date") && (
                        <div
                          className={
                            cpdClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setCPDClick(!cpdClick)}
                        >
                          Claim paid Date
                        </div>
                      )}
                      {displayFilterOptions.includes("Cost Category") && (
                        <div
                          className={
                            costCategoryClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() =>
                            setCostCategoryClick(!costCategoryClick)
                          }
                        >
                          Cost Category
                        </div>
                      )}
                      {displayFilterOptions.includes("Benefit Program") && (
                        <div
                          className={
                            benefitProgramClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() =>
                            setBenefitProgramClick(!benefitProgramClick)
                          }
                        >
                          Benefit Program
                        </div>
                      )}
                      {displayFilterOptions.includes("Cost Type") && (
                        <div
                          className={
                            costTypeClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setCostTypeClick(!costTypeClick)}
                        >
                          Cost Type
                        </div>
                      )}
                      {displayFilterOptions.includes("Begin Date") && (
                        <div
                          className={
                            beginDateClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setBeginDateClick(!beginDateClick)}
                        >
                          Begin Date
                        </div>
                      )}
                      {displayFilterOptions.includes("End Date") && (
                        <div
                          className={
                            endDateClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setEndDateClick(!endDateClick)}
                        >
                          End Date
                        </div>
                      )}
                      {displayFilterOptions.includes("Headcount Dimension") && (
                        <div
                          className={
                            headCountClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setheadCountClick(!headCountClick)}
                        >
                          Headcount Dimension
                        </div>
                      )}
                      {displayFilterOptions.includes("Plan Paid Amount") && (
                        <div
                          className={
                            planPaidAmoutClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() =>
                            setplanPaidAmoutClick(!planPaidAmoutClick)
                          }
                        >
                          Plan Paid Amount
                        </div>
                      )}

                      {displayFilterOptions.includes("Amount") && (
                        <div
                          className={
                            amountClick
                              ? "text-center px-2 py-2 bg-gray-300 text-white rounded-xl my-2 mx-2"
                              : "text-center px-2 py-2 bg-[#0000A3] text-white rounded-xl my-2 mx-2"
                          }
                          onClick={() => setamountClick(!amountClick)}
                        >
                          Amount
                        </div>
                      )}
                    </div>

                    {reportName.length > 1 ? (
                      <>
                        {uniqueArray.includes("country") && countryClick && (
                          <form className="ml-5 flex space-x-8  mt-8">
                            <FormControl name="fullWidth14" fullWidth>
                              <Tooltip title={""}>
                                <TextField
                                  label="Filter"
                                  id="E5"
                                  value="Country"
                                  variant="outlined"
                                  inputProps={{ readOnly: true }}
                                />
                              </Tooltip>
                            </FormControl>
                            <FormControl name="fullWidth15" fullWidth>
                              <InputLabel id="Eligibility12">
                                Operator
                              </InputLabel>
                              <Select
                                id="Eligibility14"
                                value={operator0}
                                label="Operator"
                                onChange={handleOperator0Change}
                              >
                                {varcharList.map((item, key) => (
                                  <MenuItem value={item.key} key={key}>
                                    {item.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl name="fullWidth16" fullWidth>
                              <InputLabel id="Eligibility20">Value</InputLabel>
                              <Select
                                id="Eligibility102"
                                value={value0}
                                multiple
                                label="value"
                                onChange={handleValue0Change}
                              >
                                {countryList.map((item, key) => (
                                  <MenuItem value={item.key} key={key}>
                                    {item.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </form>
                        )}

                        {uniqueArray.includes("target_claim_decision") &&
                          cadClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Claim adjudication decision"}>
                                  <TextField
                                    label="Filter"
                                    id="target_claim_decision"
                                    value="Claim adjudication decision"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator1}
                                  label="Operator"
                                  onChange={handleOperator1Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth16" fullWidth>
                                <InputLabel id="Eligibility21">
                                  Value
                                </InputLabel>
                                <Select
                                  id="Eligibility103"
                                  value={value1}
                                  lable="Datta Kiran Rao"
                                  multiple
                                  onChange={handleValue1Change}
                                >
                                  {targetClaimDecisionList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("target_coverage_tier") &&
                          ipctClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip
                                  title={"Insurance policy coverage tier"}
                                >
                                  <TextField
                                    label="Filter"
                                    id="target_coverage_tier"
                                    value="Insurance policy coverage tier"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator2}
                                  label="Operator"
                                  onChange={handleOperator2Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth16" fullWidth>
                                <InputLabel id="Eligibility22">
                                  Value
                                </InputLabel>
                                <Select
                                  id="Eligibility104"
                                  value={value2}
                                  lable="Datta Kiran Rao"
                                  multiple
                                  onChange={handleValue2Change}
                                >
                                  {targetCoverageTierList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("target_gender") &&
                          genderClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Gender"}>
                                  <TextField
                                    label="Filter"
                                    id="target_gender"
                                    value="Gender"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator3}
                                  label="Operator"
                                  onChange={handleOperator3Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth16" fullWidth>
                                <InputLabel id="Eligibility23">
                                  Value
                                </InputLabel>
                                <Select
                                  id="Eligibility105"
                                  value={value3}
                                  lable="Datta Kiran Rao"
                                  multiple
                                  onChange={handleValue3Change}
                                >
                                  {targetGenderList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("target_in_network_flag") &&
                          iniClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"In Network Indicator"}>
                                  <TextField
                                    label="Filter"
                                    id="target_in_network_flag"
                                    value="In Network Indicator"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator4}
                                  label="Operator"
                                  onChange={handleOperator4Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth16" fullWidth>
                                <InputLabel id="Eligibility24">
                                  Value
                                </InputLabel>
                                <Select
                                  id="Eligibility106"
                                  value={value4}
                                  lable="Datta Kiran Rao"
                                  multiple
                                  onChange={handleValue4Change}
                                >
                                  {targetInNetworkFlagList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("target_service_setting") &&
                          mssClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Medical Service Setting"}>
                                  <TextField
                                    label="Filter"
                                    id="target_service_setting"
                                    value="Medical Service Setting"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator5}
                                  label="Operator"
                                  onChange={handleOperator5Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth16" fullWidth>
                                <InputLabel id="Eligibility25">
                                  Value
                                </InputLabel>
                                <Select
                                  id="Eligibility107"
                                  value={value5}
                                  lable="Datta Kiran Rao"
                                  multiple
                                  onChange={handleValue5Change}
                                >
                                  {targetServiceSettingList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("target_record_type") &&
                          cmtClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Covered Member Type"}>
                                  <TextField
                                    label="Filter"
                                    id="target_record_type"
                                    value="Covered Member Type"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator6}
                                  label="Operator"
                                  onChange={handleOperator6Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth16" fullWidth>
                                <InputLabel id="Eligibility26">
                                  Value
                                </InputLabel>
                                <Select
                                  id="Eligibility108"
                                  value={value6}
                                  lable="Datta Kiran Rao"
                                  multiple
                                  onChange={handleValue6Change}
                                >
                                  {targetRecordTypeList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("claim_paid_date") &&
                          cpdClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Claim paid Date"}>
                                  <TextField
                                    label="Filter"
                                    id="claim_paid_date"
                                    value="Claim paid Date"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator7}
                                  label="Operator"
                                  onChange={handleOperator7Change}
                                >
                                  {integerList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth16" fullWidth>
                                <DatePicker
                                  label="Claim paid Date"
                                  name="date"
                                  value={date}
                                  onChange={(date) => setDate(date)}
                                  format="YYYY-DD-MM"
                                />
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("cost_category") &&
                          costCategoryClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Covered Member Type"}>
                                  <TextField
                                    label="Filter"
                                    id="target_record_type"
                                    value="Cost Category"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator8}
                                  label="Operator"
                                  onChange={handleOperator8Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth18" fullWidth>
                                <InputLabel id="Value">Value</InputLabel>
                                <Select
                                  id="Eligibility109"
                                  value={value8}
                                  multiple
                                  label="Value"
                                  onChange={handleValue8Change}
                                >
                                  {costCategoryList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("benefit_id") &&
                          benefitProgramClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Covered Member Type"}>
                                  <TextField
                                    label="Filter"
                                    id="target_record_type"
                                    value="Benefit Program"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator9}
                                  label="Operator"
                                  onChange={handleOperator9Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth19" fullWidth>
                                <InputLabel id="Eligibility28">
                                  Value
                                </InputLabel>
                                <Select
                                  id="Eligibility110"
                                  value={value9}
                                  label="value"
                                  multiple
                                  onChange={handleValue9Change}
                                >
                                  {benefitProgramList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("cost_type") && costTypeClick && (
                          <form
                            className="ml-5 flex space-x-8  mt-8"
                            // grid grid-cols-4 gap-6
                          >
                            <FormControl name="fullWidth14" fullWidth>
                              <Tooltip title={"Covered Member Type"}>
                                <TextField
                                  label="Filter"
                                  id="target_record_type"
                                  value="Cost Type"
                                  variant="outlined"
                                  inputProps={{ readOnly: true }}
                                />
                              </Tooltip>
                            </FormControl>
                            <FormControl name="fullWidth15" fullWidth>
                              <InputLabel id="Eligibility12">
                                Operator
                              </InputLabel>
                              <Select
                                id="Eligibility14"
                                value={operator10}
                                label="Operator"
                                onChange={handleOperator10Change}
                              >
                                {varcharList.map((item, key) => (
                                  <MenuItem value={item.key} key={key}>
                                    {item.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl name="fullWidth110" fullWidth>
                              <InputLabel id="Eligibility29">Value</InputLabel>
                              <Select
                                id="Eligibility111"
                                value={value10}
                                multiple
                                label="value"
                                onChange={handleValue10Change}
                              >
                                {costTypeList.map((item, key) => (
                                  <MenuItem value={item.key} key={key}>
                                    {item.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </form>
                        )}

                        {uniqueArray.includes("begin_date") &&
                          beginDateClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Begin Date"}>
                                  <TextField
                                    label="Filter"
                                    id="begin_date"
                                    value="Begin Date"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator11}
                                  label="Operator"
                                  onChange={handleOperator11Change}
                                >
                                  {integerList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth16" fullWidth>
                                <DatePicker
                                  label="Begin Date"
                                  name="date"
                                  value={beginDate}
                                  onChange={(beginDate) =>
                                    setBeginDate(beginDate)
                                  }
                                  format="YYYY-DD-MM"
                                />
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("end_date") && endDateClick && (
                          <form
                            className="ml-5 flex space-x-8  mt-8"
                            // grid grid-cols-4 gap-6
                          >
                            <FormControl name="fullWidth14" fullWidth>
                              <Tooltip title={"End Date"}>
                                <TextField
                                  label="Filter"
                                  id="claim_paid_date"
                                  value="End Date"
                                  variant="outlined"
                                  inputProps={{ readOnly: true }}
                                />
                              </Tooltip>
                            </FormControl>
                            <FormControl name="fullWidth15" fullWidth>
                              <InputLabel id="Eligibility12">
                                Operator
                              </InputLabel>
                              <Select
                                id="Eligibility14"
                                value={operator12}
                                label="Operator"
                                onChange={handleOperator12Change}
                              >
                                {integerList.map((item, key) => (
                                  <MenuItem value={item.key} key={key}>
                                    {item.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl name="fullWidth16" fullWidth>
                              <DatePicker
                                label="End Date"
                                name="date"
                                value={endDate}
                                onChange={(endDate) => setEndDate(endDate)}
                                format="YYYY-DD-MM"
                              />
                            </FormControl>
                          </form>
                        )}

                        {uniqueArray.includes("amount") && amountClick && (
                          <form
                            className="ml-5 flex space-x-8  mt-8"
                            // grid grid-cols-4 gap-6
                          >
                            <FormControl name="fullWidth14" fullWidth>
                              <Tooltip title={"Amount"}>
                                <TextField
                                  label="Filter"
                                  id="amount"
                                  value="Amount"
                                  variant="outlined"
                                  inputProps={{ readOnly: true }}
                                />
                              </Tooltip>
                            </FormControl>
                            <FormControl name="fullWidth15" fullWidth>
                              <InputLabel id="Eligibility12">
                                Operator
                              </InputLabel>
                              <Select
                                id="Eligibility14"
                                value={operator13}
                                label="Operator"
                                onChange={handleOperator13Change}
                              >
                                {integerList.map((item, key) => (
                                  <MenuItem value={item.key} key={key}>
                                    {item.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl name="fullWidth16" fullWidth>
                              <TextField
                                name="fullWidth1"
                                fullWidth
                                value={value13}
                                onChange={handleValue13Change}
                                id="amount"
                                label="Amount"
                                variant="outlined"
                                // error={companyName.length > 0 ? false : true}
                              />
                            </FormControl>
                          </form>
                        )}

                        {uniqueArray.includes("headcount_type") &&
                          headCountClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Headcount Dimension"}>
                                  <TextField
                                    label="Filter"
                                    id="target_record_type"
                                    value="Headcount Dimension"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility14"
                                  value={operator14}
                                  label="Operator"
                                  onChange={handleOperator14Change}
                                >
                                  {varcharList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth110" fullWidth>
                                <InputLabel id="Eligibility29">
                                  Value
                                </InputLabel>
                                <Select
                                  id="Eligibility111"
                                  value={value14}
                                  multiple
                                  label="value"
                                  onChange={handleValue14Change}
                                >
                                  {headCountList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </form>
                          )}

                        {uniqueArray.includes("sum(plan_paid_amt)") &&
                          planPaidAmoutClick && (
                            <form
                              className="ml-5 flex space-x-8  mt-8"
                              // grid grid-cols-4 gap-6
                            >
                              <FormControl name="fullWidth14" fullWidth>
                                <Tooltip title={"Plan Paid Amount"}>
                                  <TextField
                                    label="Filter"
                                    id="sum(plan_paid_amt)"
                                    value="Plan Paid Amount"
                                    variant="outlined"
                                    inputProps={{ readOnly: true }}
                                  />
                                </Tooltip>
                              </FormControl>
                              <FormControl name="fullWidth15" fullWidth>
                                <InputLabel id="Eligibility12">
                                  Operator
                                </InputLabel>
                                <Select
                                  id="Eligibility15"
                                  value={operator15}
                                  label="Operator"
                                  onChange={handleOperator15Change}
                                >
                                  {integerList.map((item, key) => (
                                    <MenuItem value={item.key} key={key}>
                                      {item.displayName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl name="fullWidth110" fullWidth>
                                <TextField
                                  name="fullWidth1"
                                  fullWidth
                                  value={value15}
                                  onChange={handleValue15Change}
                                  id="sum(plan_paid_amt)"
                                  label="Plan Paid Amount"
                                  variant="outlined"
                                  // error={contactRole.length > 0 ? false : true}
                                />
                              </FormControl>
                            </form>
                          )}
                      </>
                    ) : (
                      "Please select a report to run in the ‘Report Parameters'"
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>

              <div className="pt-8 pb-12 col-start-1 col-end-3 w-full flex justify-end items-center">
                <button
                  onClick={(e) => {
                    handleClear(e);
                  }}
                  className="ml-10 bg-gray-100 rounded-md text-gray-600 shadow-black shadow-[0_1px_4px_rgb(0,0,0,0.2)] py-[6px] px-5"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ml-5 bg-[#DB2748] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                  onClick={(e) => {
                    handleSave(e);
                  }}
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute right-0">
          <ChatBot />
        </div>
      {/* </div> */}
    </>
  );
}

// <div className="pt-5">
//   {" "}
//   {JSON.stringify(
//     [
//       ["country", operator0, value0],
//       ["target_claim_decision", operator1, value1],
//       ["target_coverage_tier", operator2, value2],
//       ["target_gender", operator3, value3],
//       ["target_in_network_flag", operator4, value4],
//       ["target_service_setting", operator5, value5],
//       ["target_record_type", operator6, value6],
//       [
//         "claim_paid_date",
//         operator7,
//         [date.$y, date.$M + 1, date.$D],
//       ],
//       ["cost_category", operator8, value8],
//       ["benefit_id", operator9, value9],
//       ["cost_type", operator10, value10],
//       [
//         "begin_date",
//         operator11,
//         [beginDate.$y, beginDate.$M + 1, beginDate.$D],
//       ],
//       [
//         "endDate",
//         operator12,
//         [endDate.$y, endDate.$M + 1, endDate.$D],
//       ],
//       ["amount", operator13, value13],
//       ["headcount_type", operator14, value14],
//       ["sum(plan_paid_amt)", operator15, value15],
//     ],
//     null,
//     "    ",
//   )}
// </div>

// <div>
//   {finalList.map((item, i) => [
//     <p key={i}>{item[0]}</p>,
//     <p key={i}>{item[ 1 ]}</p>,
//     <p key={i}>{item[2]}</p>,
//   ])}
// </div>
