import React from "react";

export default function LinkCard({ icon, linkText }) {
  return (
    <div className="bg-[#F2F2FA] mx-5 w-[250px] h-[250px] mt-10 flex flex-col justify-center ">
      <div className="flex justify-center items-center px-4 py-2">{icon}</div>
      <div className="text-xl text-center mt-4">{linkText}</div>
    </div>
  );
}
