import { React } from "react";
import LinkCard from "../../components/LinkCard.jsx";
import { BsBoxArrowInRight, BsBoxes, BsEject } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import ChatBot from "../../components/ChatBot.jsx";
import Paper from "@mui/material/Paper";
import CompanyPageImage from "../../assets/Company Page.jpg";

export default function LandingPage({ profiles, user, signOut }) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "60px" };

  return (
    <>
      {/* <Navbar user={user} signOut={signOut} />
      <div className="flex">
        <Sidebar /> */}
        <div className="pt-16 2xl:pt-24 h-fit xl:mx-20 xl:mr-[25rem] 2xl:mr-[40rem] 2xl:ml-[15rem]">
          <Paper className="mt-6  pl-1" elevation={2}>
            <div className="text-center font-bold uppercase text-2xl py-2">Company</div>
            <div className="flex">
              <div className="w-[584px] h-[458px] grid grid-cols-2 justify-center items-center">
                <Link to="/company/create-company">
                  <LinkCard
                    icon={<BsBoxArrowInRight style={iconStyles} />}
                    linkText="Create Company"
                  />
                </Link>
                <Link to="/company/manage-company">
                  <LinkCard
                    icon={<BsBoxes style={iconStyles} />}
                    linkText="Manage Companies"
                  />
                </Link>
                <Link to="/in-development" hidden>
                  <LinkCard
                    icon={<AiOutlineUser style={iconStyles} />}
                    linkText="Create User"
                  />
                </Link>
                <Link to="/in-development" hidden>
                  <LinkCard
                    icon={<BsEject style={iconStyles} />}
                    linkText="Manage Permissions"
                  />
                </Link>
              </div>

              <div className="w-[400px] rounded-lg flex justify-center items-center 2xl:w-[584px] py-5 px-5">
                <img src={CompanyPageImage} alt="" />
              </div>
            </div>
          </Paper>
        </div>

      {/* </div> */}
    </>
  );
}
