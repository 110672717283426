import { React, useEffect, useState } from "react";
import ColMapNested from "../../components/ColMapNested";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Alerts from "../../components/Alerts.jsx";
import { Link, useNavigate } from "react-router-dom";
import { BsBoxArrowInRight } from "react-icons/bs";
import { IContext } from "../../context/IntegrateContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";

export default function CreateTemplateS4({ user, signOut }) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };
  const [wait, setWait] = useState(true);
//   const [masterJson, setMasterJson] = useState([]);
  const [alert, setAlert] = useState("");
  const [createTemplateS1Values, setCreateTemplateS1Values] = useState([]);
  // const { getS4ColumnData, getS4ItemData } = IContext();
  const {testData} = IContext();
  const [pracWait, setPracWait] = useState(false);
  const [tempColWait, setTempcolwait] = useState(false);
  const [pracArray, setpracArray] = useState([]);
  const [tempColumnData,settempColumnData] = useState([]);

  // console.log("dropdown data", getS4ItemData);
  // console.log("col data", getS4ColumnData);

  setTimeout(() => {
    setWait(false);
  }, 1500);

  let displayName = ["datta"];

  useEffect(() => {
    // const getCreateTemplate1Selection = async () => {
    //   const createTemplate3Selection = await API.graphql({
    //     query: queries.readIntegrateAttributes,
    //   }).then((data)=>{
    //     setMasterJson(data.data.readIntegrateAttributes);
    //     setWait(false);
    //   });
    // };
    // getCreateTemplate1Selection();
    // console.log(testData)
    const unParsedFormDatas = localStorage.getItem("formTemplateType");
    const formTemplateType = JSON.parse(unParsedFormDatas);
    setCreateTemplateS1Values(formTemplateType);
  }, []);

    if (!pracWait || !tempColWait) {
        if (testData && testData.length > 0) {
            let tempsetpracArray = []
            setpracArray([]);
            for (let i = 0; i < testData.length; i++) {
            let tempPracArray = [];
            for (let tc of testData[i].source_columns[0].source_values) {
                let tempDict = {};
                tempDict.key = testData[i].key
                tempDict.keyDisplayName = testData[i].keyDisplayName
                tempDict.column = tc;
                tempDict.options = testData[i].source_columns[0].option;
                tempPracArray.push(tempDict);
            }
            tempsetpracArray.push(tempPracArray);
            }
            setpracArray(tempsetpracArray);  
        }
        if (pracArray.length > 0) {
            let tempsettempColumnData = []
            settempColumnData([]);
            for (let i = 0; i < pracArray.length; i++) {
                let start = 0;
                let nesting = {};
                for (let j = 0; j < pracArray[i].length; j++) {
                nesting[pracArray[i][j].column] = start;
                start++;
                }
                tempsettempColumnData.push(nesting);
            }
            settempColumnData(tempsettempColumnData);
            if (tempColumnData.length > 0) {
                setPracWait(true);
                console.log("Generated PracArray:",pracArray);
                setTempcolwait(true); 
                console.log("Generated Temp Column Data",tempColumnData);
            } 
        } 
  }
  let navigate = useNavigate();
 
  const getMappedOption = (batchNumber, optionNumber, optionValue) => {
    // console.log(batchNumber, optionNumber, optionValue);
    for (let i = 0; i < tempColumnData.length; i++) {
      let start = 0;
      for (let key in tempColumnData[i]) {
        if (
          batchNumber === i &&
          (optionNumber === tempColumnData[i][key] || optionNumber === start)
        ) {
          tempColumnData[i][key] = optionValue;
        }
        start++;
      }
    }
  };

  const keyValueMapping = async () => {
    let finalJson = tempColumnData;
    console.log("fj", finalJson);

    const neededJson = {
      template_id: JSON.parse(
        localStorage.getItem("frontend_generated_template_id"),
      ),
      template_type_id: JSON.parse(localStorage.getItem("formTemplateType")),
      company_id: JSON.parse(localStorage.getItem("formCompanyName")),
      country_code: JSON.parse(localStorage.getItem("formCountry")),
      userid: user,
    };
    console.log("nj", neededJson);

    let resultJSON = [];

    for (let key in finalJson) {
      for (let subkey in finalJson[key]) {
        let tempJSON = { ...neededJson };
        tempJSON.target_column = key;
        tempJSON.source_column = key.replace("target", "source");
        tempJSON.attribute_source_value = subkey;
        tempJSON.attribute_target_value = finalJson[key][subkey];
        resultJSON.push(tempJSON);
      }
    };

    for (let index = 0; index < resultJSON.length; index++) {
      for (let j = 0; j < pracArray.length; j++) {
        if(resultJSON[index].attribute_source_value = pracArray[j][0].column){
          resultJSON[index].source_column = pracArray[j][0].key.replace("target", "source")
          resultJSON[index].target_column = pracArray[j][0].key
        }
      }
    }

    console.log("rj", resultJSON);

    const uploadKeyValueMappings = await API.graphql({
      query: mutations.createIntegrateTemplateKeyValueMap,
      variables: {
        key_value_map_data: resultJSON,
      },
    }).then((data) => {
      console.log(data);
      if (data.data.createIntegrateTemplateKeyValueMap === "Success") {
        navigate("/integrate/manage-template");
      }
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setAlert("success");
    await keyValueMapping();
  };

  const handleClear = (e) => {
    e.preventDefault();
    navigate("/integrate/createtemplates1");
  };

  return (
    <>
      {" "}
        <div className="2xl:ml-[14.5rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates1">
              Create Template
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates4">
              Map keys to values
            </Link>
          </div>

          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <BsBoxArrowInRight style={iconStyles} />
              <p className="text-3xl mx-1">Create Template</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            <section className="">
              {alert === "success" ? (
                <Alerts
                  severity="success"
                  info="Template Creation Successful"
                />
              ) : alert === "error" ? (
                <Alerts
                  severity="error"
                  info="Please fill all the Required Fields.!!!"
                />
              ) : (
                ""
              )}
            </section>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait && !pracWait && !tempColWait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex">
            <div className="ml-3">
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 1</p>
                <p className="text-md pb-1">Set Template Properties</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 2</p>
                <p className="text-md pb-1 ">Capture addtional fields</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 3</p>
                <p className="text-md pb-1">Map columns</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 4</p>
                <p className="font-bold text-md pb-1 ">Map keys to values</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
            </div>

            <div className="mt-4  ml-[7.5rem] max-h-[500px] overflow-y-scroll">
            {(pracWait && tempColWait && pracArray.length > 0)  ? (
                  pracArray.map((col, key) => (
                    <div className="mb-12 space-y-4" key={key}>
                      <div className="capitalize text-center mb-2">{col[0].keyDisplayName}</div>
                      <div>
                        {
                        col.map((c, index) => (
                          <ColMapNested
                            column={c.column}
                            key={index}
                            parentItno={key}
                            itno={index}
                            items={c.options}
                            readyFlag = {pracWait && tempColWait}
                            getMappedOption={getMappedOption}
                          />
                        ))
                        }
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              <div className="pt-16 w-full flex justify-end items-center">
                <button
                  onClick={(e) => {
                    handleClear(e);
                  }}
                  className="ml-10 text-gray-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ml-10 bg-[#AEB3B7] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                  onClick={() => {
                    navigate("/integrate/createtemplates3");
                  }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="ml-5 bg-[#0000A3] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                  onClick={(e) => {
                    handleSave(e);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}