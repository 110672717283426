import { React } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar.jsx";
import Sidebar from "../components/Sidebar.jsx";

export default function InDev({ user, signOut }) {
  let navigate = useNavigate();

  return (
    <>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="flex flex-col justify-center items-center w-[870px]">
          <p className="text-xl">Under Development</p>
          <button className="bg-[#0000A3] py-2 px-4  text-white text-lg my-4" onClick={() => navigate('/company')}>Home</button>
        </div>
      </div>
    </>
  );
}
