/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAllCompanies = /* GraphQL */ `
  query GetAllCompanies {
    getAllCompanies {
      as_of_date
      company_country_hq
      company_id
      company_id_status
      company_name
      company_state_hq
      currency_preference
      date_format_preference
      effective_date
      filename
      industry_id
      industry_name
      is_exclude_from_benchmarks
      load_dt
      poc_email
      poc_role
      primary_poc
      sub_industry_id
      sub_industry_name
      userid
      __typename
    }
  }
`;
export const getcompanyindustryattributes = /* GraphQL */ `
  query Getcompanyindustryattributes {
    getcompanyindustryattributes {
      industry_description
      industry_key
      industry_type_display_name
      industry_sort_order
      sub_industry
      sub_industry_description
      sub_industry_key
      sub_industry_type_display_name
      sub_industry_sort_order
      __typename
    }
  }
`;
export const getCommonValues = /* GraphQL */ `
  query GetCommonValues {
    getCommonValues {
      as_of_date
      attribute_key_description
      attribute_key_display_name
      attribute_key_name
      attribute_key_value_type
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      __typename
    }
  }
`;
export const getDesignConfValues = /* GraphQL */ `
  query GetDesignConfValues {
    getDesignConfValues {
      as_of_date
      category_name
      category_display_name
      attribute_key_name
      attribute_key_display_name
      attribute_key_description
      attribute_key_value_type
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      list_type
      __typename
    }
  }
`;
export const getIntegrateConfValues = /* GraphQL */ `
  query GetIntegrateConfValues {
    getIntegrateConfValues {
      as_of_date
      attribute_key_description
      attribute_key_display_name
      attribute_key_name
      attribute_key_value_type
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      category_display_name
      category_name
      __typename
    }
  }
`;
export const getIntegrateKeysTemplateType = /* GraphQL */ `
  query GetIntegrateKeysTemplateType {
    getIntegrateKeysTemplateType {
      as_of_date
      feature_key
      template_type_id
      __typename
    }
  }
`;
export const getReportConfValues = /* GraphQL */ `
  query GetReportConfValues {
    getReportConfValues {
      as_of_date
      category
      enum_attribute_display_name
      enum_attribute_value
      enum_value_sort_order
      feature_description
      feature_key
      value_type
      __typename
    }
  }
`;
export const readCountryStateCombo = /* GraphQL */ `
  query ReadCountryStateCombo {
    readCountryStateCombo {
      country_display_name
      country_code
      country_sort_order
      state_display_name
      state_code
      state_sort_order
      __typename
    }
  }
`;
export const readBenefitAttributes = /* GraphQL */ `
  query ReadBenefitAttributes {
    readBenefitAttributes {
      as_of_date
      attribute_key_description_child
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_list_valid_value_child
      attribute_list_valid_value_parent
      attribute_list_value_display_name_child
      attribute_list_value_display_name_parent
      attribute_list_value_sort_order_child
      attribute_list_value_sort_order_parent
      benefit_id
      child_operator_flag
      component_id
      component_source_table
      component_target_table
      component_type
      country
      user_id
      __typename
    }
  }
`;
export const readBenefitAttributesBenefitPlanCoverageAmount = /* GraphQL */ `
  query ReadBenefitAttributesBenefitPlanCoverageAmount {
    readBenefitAttributesBenefitPlanCoverageAmount {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesBenefitPlanOverview = /* GraphQL */ `
  query ReadBenefitAttributesBenefitPlanOverview {
    readBenefitAttributesBenefitPlanOverview {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesInsuredBenefitsPlanOverview = /* GraphQL */ `
  query ReadBenefitAttributesInsuredBenefitsPlanOverview {
    readBenefitAttributesInsuredBenefitsPlanOverview {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesPharmacyUsageBasedCostSharing = /* GraphQL */ `
  query ReadBenefitAttributesPharmacyUsageBasedCostSharing {
    readBenefitAttributesPharmacyUsageBasedCostSharing {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesPlanPayCheckContributions = /* GraphQL */ `
  query ReadBenefitAttributesPlanPayCheckContributions {
    readBenefitAttributesPlanPayCheckContributions {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesPolicyRiders = /* GraphQL */ `
  query ReadBenefitAttributesPolicyRiders {
    readBenefitAttributesPolicyRiders {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesSavingsPlanOverview = /* GraphQL */ `
  query ReadBenefitAttributesSavingsPlanOverview {
    readBenefitAttributesSavingsPlanOverview {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesUsMedicalPlanHraAccounts = /* GraphQL */ `
  query ReadBenefitAttributesUsMedicalPlanHraAccounts {
    readBenefitAttributesUsMedicalPlanHraAccounts {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesUsMedicalPlanHsaAccounts = /* GraphQL */ `
  query ReadBenefitAttributesUsMedicalPlanHsaAccounts {
    readBenefitAttributesUsMedicalPlanHsaAccounts {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesUsMedicalPlanoverview = /* GraphQL */ `
  query ReadBenefitAttributesUsMedicalPlanoverview {
    readBenefitAttributesUsMedicalPlanoverview {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesUsageBasedCostSharing = /* GraphQL */ `
  query ReadBenefitAttributesUsageBasedCostSharing {
    readBenefitAttributesUsageBasedCostSharing {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitAttributesusdentalvisionstdOverview = /* GraphQL */ `
  query ReadBenefitAttributesusdentalvisionstdOverview {
    readBenefitAttributesusdentalvisionstdOverview {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readEmployeeEligibility = /* GraphQL */ `
  query ReadEmployeeEligibility {
    readEmployeeEligibility {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readChildDependentEligibility = /* GraphQL */ `
  query ReadChildDependentEligibility {
    readChildDependentEligibility {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readAdultDependentEligibility = /* GraphQL */ `
  query ReadAdultDependentEligibility {
    readAdultDependentEligibility {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const readBenefitDetails = /* GraphQL */ `
  query ReadBenefitDetails {
    readBenefitDetails {
      as_of_date
      attribute_category
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_valid_value
      attribute_list_valid_value_display_name
      attribute_list_valid_value_sort_order
      benefit_id
      benefit_plan_display_name
      benefit_plan_id
      benefit_status
      company_id
      company_id_status
      company_name
      component_source_table
      component_target_table
      component_type
      country_code
      country_name
      intermediate_region_name
      operator_id
      region_name
      sub_region_name
      __typename
    }
  }
`;
export const readCompanyAttributes = /* GraphQL */ `
  query ReadCompanyAttributes {
    readCompanyAttributes {
      as_of_date
      attribute_key_description
      attribute_key_display_name
      attribute_key_name
      attribute_key_value_type
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      category_display_name
      category_name
      filename
      load_dt
      __typename
    }
  }
`;
export const readCompanyDetails = /* GraphQL */ `
  query ReadCompanyDetails {
    readCompanyDetails {
      as_of_date
      company_country_hq
      company_country_hq_intermediate_region
      company_country_hq_name
      company_country_hq_region_name
      company_country_hq_sub_region_name
      company_country_hq_two_digit_iso_code
      company_id
      company_name
      company_state_hq
      company_state_hq_name
      company_status
      industry_id
      industry_name
      is_exclude_from_benchmarks
      poc_email
      poc_role
      primary_poc
      sub_industry_id
      sub_industry_name
      employer_size
      employer_size_display_name
      oranization_id
      organization_name
      userid
      __typename
    }
  }
`;
export const readDashboardAttributes = /* GraphQL */ `
  query ReadDashboardAttributes {
    readDashboardAttributes {
      as_of_date
      dashboard_category_sort_order
      dashboard_display_name
      dashboard_id
      dashboard_id_sort_order
      dashboard_link
      dashboard_type_id
      dashboard_type_name
      image_id
      __typename
    }
  }
`;
export const readDashboardList = /* GraphQL */ `
  query ReadDashboardList {
    readDashboardList {
      category_name
      category_display_name
      attribute_list_value_sort_order
      dashboard_ids
      dashboard_display_names
      dashboard_link
      __typename
    }
  }
`;
export const readIntegrateAttributes = /* GraphQL */ `
  query ReadIntegrateAttributes {
    readIntegrateAttributes {
      as_of_date
      attribute_key_description
      attribute_key_display_name
      attribute_key_name
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      component_source_table
      component_type
      template_type_id
      __typename
    }
  }
`;
export const readIntegrateDetails = /* GraphQL */ `
  query ReadIntegrateDetails {
    readIntegrateDetails {
      as_of_date
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      company_id
      company_id_status
      company_name
      component_source_table
      component_target_table
      component_type
      country_code
      country_name
      intermediate_region_name
      region_name
      sub_region_name
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      userid
      __typename
    }
  }
`;
export const readIntegrateBenefitDetails = /* GraphQL */ `
  query ReadIntegrateBenefitDetails {
    readIntegrateBenefitDetails {
      template_type_id
      company_id
      country
      benefit_id
      benefit_program_display_name
      benefit_plan_id
      benefit_plan_display_name
      __typename
    }
  }
`;
export const readIntegrateFileLog = /* GraphQL */ `
  query ReadIntegrateFileLog {
    readIntegrateFileLog {
      company_id
      company_name
      etl_functional_process_id
      file_id
      filename
      load_dt
      message
      status
      submission_type
      template_display_name
      template_id
      template_type_display_name
      template_type_id
      __typename
    }
  }
`;
export const readIntegrateTemplateColumnMap = /* GraphQL */ `
  query ReadIntegrateTemplateColumnMap {
    readIntegrateTemplateColumnMap {
      as_of_date
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      company_id
      company_id_status
      company_name
      component_source_table
      component_target_table
      component_type
      country_code
      country_name
      intermediate_region_name
      region_name
      sub_region_name
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      userid
      __typename
    }
  }
`;
export const readPublishedDemographicProviderReconDetails = /* GraphQL */ `
  query ReadPublishedDemographicProviderReconDetails($request_id: String) {
    readPublishedDemographicProviderReconDetails(request_id: $request_id) {
      administrator_comments
      company_id
      country
      ee_country_entry_date
      ee_date_of_birth
      ee_emplid
      ee_first_name
      ee_job_family
      ee_last_name
      ee_middle_name
      ee_recent_hire_date
      ee_source_employee_class
      ee_source_hr_status_code
      ee_target_employee_class
      ee_target_hr_status_code
      ee_template_display_name
      ee_template_types
      ee_termination_date
      emp_join_key_value
      emplid
      invoice_frequency
      load_dt
      national_ids
      provider_benefit_id
      provider_coverage_begin_date
      provider_coverage_end_date
      provider_date_of_birth
      provider_emplid
      provider_first_name
      provider_join_key_value
      provider_last_name
      provider_member_unique_key_value
      provider_middle_name
      provider_national_id
      provider_plan_display_name
      provider_policy_id
      provider_template_display_name
      provider_template_type_id
      provider_vendor_name
      recon_status
      recon_status_detail
      reconciliation_type
      report_request_id
      report_run_by_userid
      report_run_date
      row_id
      snap_dt
      __typename
    }
  }
`;
export const readReportRawAttributes = /* GraphQL */ `
  query ReadReportRawAttributes {
    readReportRawAttributes {
      as_of_date
      company_id
      company_name
      company_status
      component_source_table
      component_target_table
      component_type
      country_code
      country_name
      file_id
      filename
      intermediate_region_name
      recon_type
      recon_type_display_name
      recon_type_display_order
      region_name
      sub_region_name
      template_display_name
      template_id
      template_type_display_name
      template_type_id
      __typename
    }
  }
`;
export const readReportReconDetails = /* GraphQL */ `
  query ReadReportReconDetails {
    readReportReconDetails {
      company_id
      company_name
      company_status
      create_userid
      created_dt
      delete_userid
      deleted_dt
      input_file_id_1
      input_file_id_1_name
      input_file_id_1_template_id
      input_file_id_1_template_name
      input_file_id_1_template_type_id
      input_file_id_1_template_type_name
      input_file_id_2
      input_file_id_2_name
      input_file_id_2_template_id
      input_file_id_2_template_name
      input_file_id_2_template_type_id
      input_file_id_2_template_type_name
      is_delete
      last_publish_dt
      publish_status
      publish_userid
      recon_type
      recon_type_display_name
      report_request_id
      __typename
    }
  }
`;
export const readReportReconDetailsManageRecon = /* GraphQL */ `
  query ReadReportReconDetailsManageRecon {
    readReportReconDetailsManageRecon {
      company_id
      company_name
      company_status
      create_userid
      created_dt
      delete_userid
      deleted_dt
      input_file_id_1
      input_file_id_1_name
      input_file_id_1_template_id
      input_file_id_1_template_name
      input_file_id_1_template_type_id
      input_file_id_1_template_type_name
      input_file_id_2
      input_file_id_2_name
      input_file_id_2_template_id
      input_file_id_2_template_name
      input_file_id_2_template_type_id
      input_file_id_2_template_type_name
      is_delete
      last_publish_dt
      publish_status
      publish_userid
      recon_type
      recon_type_display_name
      report_request_id
      __typename
    }
  }
`;
export const readReportReconEnrollmentSysToProviderPublishedDetails = /* GraphQL */ `
  query ReadReportReconEnrollmentSysToProviderPublishedDetails(
    $request_id: String
  ) {
    readReportReconEnrollmentSysToProviderPublishedDetails(
      request_id: $request_id
    ) {
      administrator_comments
      benefit_sys_benefit_id
      benefit_sys_benefit_plan_id
      benefit_sys_country
      benefit_sys_coverage_begin_dt
      benefit_sys_coverage_end_dt
      benefit_sys_date_of_birth
      benefit_sys_dep_join_key_value
      benefit_sys_dependent_national_id
      benefit_sys_dependent_system_id
      benefit_sys_emp_join_key_value
      benefit_sys_emplid
      benefit_sys_employee_national_id
      benefit_sys_employee_system_id
      benefit_sys_first_name
      benefit_sys_full_name
      benefit_sys_last_name
      benefit_sys_middle_name
      benefit_sys_policy_id
      benefit_sys_snap_dt
      benefit_sys_source_coverage_tier
      benefit_sys_source_record_type
      benefit_sys_target_coverage_tier
      benefit_sys_target_record_type
      benefit_sys_template_id
      benefit_sys_template_type_id
      company_id
      company_name
      country
      emplid
      file_id_1
      file_id_2
      filename_1
      filename_2
      load_dt
      provider_benefit_id
      provider_benefit_plan_id
      provider_country
      provider_coverage_begin_dt
      provider_coverage_end_dt
      provider_date_of_birth
      provider_dep_join_key_value
      provider_dependent_national_id
      provider_emp_join_key_value
      provider_emplid
      provider_employee_national_id
      provider_first_name
      provider_full_name
      provider_last_name
      provider_middle_name
      provider_policy_id
      provider_snap_dt
      provider_source_coverage_tier
      provider_source_record_type
      provider_target_coverage_tier
      provider_target_record_type
      provider_template_id
      provider_template_type_id
      provider_vendor_name
      publish_userid
      recon_status
      recon_status_detail
      reconciliation_type
      report_request_id
      report_run_by_userid
      report_run_dt
      row_id
      __typename
    }
  }
`;
export const readReportReconEnrollmentSysToProviderUnpublishedDetails = /* GraphQL */ `
  query ReadReportReconEnrollmentSysToProviderUnpublishedDetails(
    $request_id: String
  ) {
    readReportReconEnrollmentSysToProviderUnpublishedDetails(
      request_id: $request_id
    ) {
      administrator_comments
      benefit_sys_benefit_id
      benefit_sys_benefit_plan_id
      benefit_sys_country
      benefit_sys_coverage_begin_dt
      benefit_sys_coverage_end_dt
      benefit_sys_date_of_birth
      benefit_sys_dep_join_key_value
      benefit_sys_dependent_national_id
      benefit_sys_dependent_system_id
      benefit_sys_emp_join_key_value
      benefit_sys_emplid
      benefit_sys_employee_national_id
      benefit_sys_employee_system_id
      benefit_sys_first_name
      benefit_sys_full_name
      benefit_sys_last_name
      benefit_sys_middle_name
      benefit_sys_policy_id
      benefit_sys_snap_dt
      benefit_sys_source_coverage_tier
      benefit_sys_source_record_type
      benefit_sys_target_coverage_tier
      benefit_sys_target_record_type
      benefit_sys_template_id
      benefit_sys_template_type_id
      company_id
      company_name
      country
      create_userid
      emplid
      file_id_1
      file_id_2
      filename_1
      filename_2
      load_dt
      provider_benefit_id
      provider_benefit_plan_id
      provider_country
      provider_coverage_begin_dt
      provider_coverage_end_dt
      provider_date_of_birth
      provider_dep_join_key_value
      provider_dependent_national_id
      provider_emp_join_key_value
      provider_emplid
      provider_employee_national_id
      provider_first_name
      provider_full_name
      provider_last_name
      provider_middle_name
      provider_policy_id
      provider_snap_dt
      provider_source_coverage_tier
      provider_source_record_type
      provider_target_coverage_tier
      provider_target_record_type
      provider_template_id
      provider_template_type_id
      provider_vendor_name
      recon_status
      recon_status_detail
      reconciliation_type
      report_request_id
      report_run_by_userid
      report_run_dt
      __typename
    }
  }
`;
export const readReportReconFundingDepositsToPayrollPublishedDetails = /* GraphQL */ `
  query ReadReportReconFundingDepositsToPayrollPublishedDetails(
    $request_id: String
  ) {
    readReportReconFundingDepositsToPayrollPublishedDetails(
      request_id: $request_id
    ) {
      administrator_comments
      company_id
      company_name
      country
      emplid
      file_id_1
      file_id_2
      filename_1
      filename_2
      load_dt
      payroll_amount
      payroll_country
      payroll_emp_join_key_value
      payroll_emplid
      payroll_national_id
      payroll_negative_is_positive_bool
      payroll_pay_withholding_deposit_date
      payroll_snap_dt
      payroll_source_income_deduction_type
      payroll_system_id
      payroll_target_income_deduction_type
      payroll_template_id
      payroll_template_type_id
      provider_amount
      provider_country
      provider_emp_join_key_value
      provider_emplid
      provider_national_id
      provider_negative_is_positive_bool
      provider_pay_withholding_deposit_date
      provider_snap_dt
      provider_source_income_deduction_type
      provider_system_id
      provider_target_income_deduction_type
      provider_template_id
      provider_template_type_id
      publish_userid
      recon_status
      recon_status_detail
      reconciliation_type
      report_request_id
      report_run_by_userid
      report_run_dt
      row_id
      variance_amount
      __typename
    }
  }
`;
export const readReportReconFundingDepositsToPayrollUnpublishedDetails = /* GraphQL */ `
  query ReadReportReconFundingDepositsToPayrollUnpublishedDetails(
    $request_id: String
  ) {
    readReportReconFundingDepositsToPayrollUnpublishedDetails(
      request_id: $request_id
    ) {
      administrator_comments
      company_id
      company_name
      country
      create_userid
      emplid
      file_id_1
      file_id_2
      filename_1
      filename_2
      load_dt
      payroll_amount
      payroll_country
      payroll_emp_join_key_value
      payroll_emplid
      payroll_national_id
      payroll_negative_is_positive_bool
      payroll_pay_withholding_deposit_date
      payroll_snap_dt
      payroll_source_income_deduction_type
      payroll_system_id
      payroll_target_income_deduction_type
      payroll_template_id
      payroll_template_type_id
      provider_amount
      provider_country
      provider_emp_join_key_value
      provider_emplid
      provider_national_id
      provider_negative_is_positive_bool
      provider_pay_withholding_deposit_date
      provider_snap_dt
      provider_source_income_deduction_type
      provider_system_id
      provider_target_income_deduction_type
      provider_template_id
      provider_template_type_id
      recon_status
      recon_status_detail
      reconciliation_type
      report_request_id
      report_run_by_userid
      report_run_dt
      variance_amount
      __typename
    }
  }
`;
export const readReportReconProcLog = /* GraphQL */ `
  query ReadReportReconProcLog($request_id: String) {
    readReportReconProcLog(request_id: $request_id) {
      log_timestamp
      message
      report_request_id
      status
      __typename
    }
  }
`;
export const readUnpublishedDemographicProviderReconDetails = /* GraphQL */ `
  query ReadUnpublishedDemographicProviderReconDetails($request_id: String) {
    readUnpublishedDemographicProviderReconDetails(request_id: $request_id) {
      administrator_comments
      company_id
      company_name
      country
      create_userid
      ee_country_entry_date
      ee_date_of_birth
      ee_emplid
      ee_first_name
      ee_job_family
      ee_last_name
      ee_middle_name
      ee_recent_hire_date
      ee_source_employee_class
      ee_source_hr_status_code
      ee_target_employee_class
      ee_target_hr_status_code
      ee_template_display_name
      ee_template_type_id
      ee_termination_date
      emp_join_key_value
      emplid
      invoice_frequency
      load_dt
      national_ids
      provider_benefit_id
      provider_coverage_begin_date
      provider_coverage_end_date
      provider_date_of_birth
      provider_emplid
      provider_first_name
      provider_join_key_value
      provider_last_name
      provider_member_unique_key_value
      provider_middle_name
      provider_national_id
      provider_plan_display_name
      provider_policy_id
      provider_template_display_name
      provider_template_type_id
      provider_vendor_name
      recon_status
      recon_status_detail
      reconciliation_type
      report_request_id
      report_run_by_userid
      report_run_date
      snap_dt
      __typename
    }
  }
`;
export const readWebsiteContentDetails = /* GraphQL */ `
  query ReadWebsiteContentDetails {
    readWebsiteContentDetails {
      as_of_date
      content_id
      content_text
      placement_description
      web_page
      __typename
    }
  }
`;
export const readeFormAttributes = /* GraphQL */ `
  query ReadeFormAttributes {
    readeFormAttributes {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_source_table
      component_target_table
      list_type
      template_type_id
      __typename
    }
  }
`;
export const readeFormDetails = /* GraphQL */ `
  query ReadeFormDetails {
    readeFormDetails {
      as_of_date
      attribute_array_value
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_value_display_name
      attribute_valid_value
      benefit_display_name
      benefit_id
      company_id
      company_id_status
      company_name
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      __typename
    }
  }
`;
export const readeFormInvoiceTemplate = /* GraphQL */ `
  query ReadeFormInvoiceTemplate {
    readeFormInvoiceTemplate {
      administrator_comments
      amount
      benefit_display_name
      benefit_id
      company_id
      company_name
      cost_category
      cost_category_display_name
      cost_type
      cost_type_display_name
      country
      country_display_name
      currency_code
      eform_destination_table
      sub_plan_id
      sub_plan_id_display_name
      template_display_name
      template_id
      template_type_display_name
      template_type_id
      userid
      vendor
      __typename
    }
  }
`;
export const readeFormTemplateMetaData = /* GraphQL */ `
  query ReadeFormTemplateMetaData {
    readeFormTemplateMetaData {
      as_of_date
      benefit_display_name
      benefit_id
      company_id
      company_id_status
      company_name
      eform_destination_table
      rn_template_status
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      userid
      __typename
    }
  }
`;
export const readeFormHeadcountTemplate = /* GraphQL */ `
  query ReadeFormHeadcountTemplate {
    readeFormHeadcountTemplate {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      headcount_type
      headcount_type_display_name
      amount
      administrator_comments
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const readeFormOperationalTemplate = /* GraphQL */ `
  query ReadeFormOperationalTemplate {
    readeFormOperationalTemplate {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      sla_key
      sla_description
      sla_value
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const readeFormGeneralUsageTemplate = /* GraphQL */ `
  query ReadeFormGeneralUsageTemplate {
    readeFormGeneralUsageTemplate {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      headcount_type
      headcount_type_display_name
      amount
      administrator_comments
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const readeFormFileUploadLog = /* GraphQL */ `
  query ReadeFormFileUploadLog {
    readeFormFileUploadLog {
      company_id
      template_id
      transaction_id
      filename
      load_dt
      __typename
    }
  }
`;
export const readDesignFileUploadLog = /* GraphQL */ `
  query ReadDesignFileUploadLog {
    readDesignFileUploadLog {
      company_id
      benefit_plan_id
      filename
      load_dt
      __typename
    }
  }
`;
export const readBenefitInventoryOverview = /* GraphQL */ `
  query ReadBenefitInventoryOverview {
    readBenefitInventoryOverview {
      company_id
      company_name
      company_country_hq
      industry_id
      industry_name
      sub_industry_id
      sub_industry_name
      benefit_plan_id
      benefit_plan_display_name
      benefit_status
      benefit_id
      benefit_program_display_name
      benefit_category
      benefit_sub_category
      country
      two_digit_iso_code
      country_name
      region
      sub_region
      intermediate_region
      userid
      load_dt
      __typename
    }
  }
`;
export const readReportType = /* GraphQL */ `
  query ReadReportType {
    readReportType {
      report_category_display_name
      report_category
      report_category_sort_order
      report_type_display_name
      report_type_sort_order
      report_type
      is_select_all_cols
      granularity
      column_list
      aggregated_column_list
      __typename
    }
  }
`;
export const readStandardReportSelect = /* GraphQL */ `
  query ReadStandardReportSelect {
    readStandardReportSelect {
      report_category_display_name
      report_category
      report_category_sort_order
      report_type_display_name
      report_type_sort_order
      report_type
      component_source_table
      filter_source_table
      component_target_table
      operator
      operator_value
      operator_display_name
      filter
      filter_display_name
      attribute_key_value_type
      filter_value
      filter_value_display_name
      filter_display_name_sort_order
      __typename
    }
  }
`;
export const generateUniqueBenefitPlanID = /* GraphQL */ `
  query GenerateUniqueBenefitPlanID {
    generateUniqueBenefitPlanID
  }
`;
export const readOrganizationUsers = /* GraphQL */ `
  query ReadOrganizationUsers {
    readOrganizationUsers {
      organization_id
      organization_name
      organization_type
      organization_type_display_name
      userid
      __typename
    }
  }
`;
export const readIntegrateTemplateOverview = /* GraphQL */ `
  query ReadIntegrateTemplateOverview {
    readIntegrateTemplateOverview {
      as_of_date
      template_id
      template_status
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      company_id_status
      country_code
      country_name
      region_name
      sub_region_name
      intermediate_region_name
      userid
      rn
      data_source
      __typename
    }
  }
`;
export const readStandardReportOverview = /* GraphQL */ `
  query ReadStandardReportOverview {
    readStandardReportOverview {
      report_request_id
      company_id
      company_name
      organization_id
      report_type_display_name
      report_type
      create_userid
      status_timestamp
      job_status
      file_size
      file_url
      __typename
    }
  }
`;
