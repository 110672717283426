import { createContext, useContext, useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries.js";
import * as mutations from "../graphql/mutations";
import awsExports from "../aws-exports.js";
Amplify.configure(awsExports);
API.configure(awsExports);

const IntegrateContext = createContext();

export function IntegrateContextProvider({ children }) {
  const items = ["item1", "item2", "item3", "item4"];
  const [columns, setColumns] = useState([]);
  const [tempColumnData, settempColumnData] = useState({});
  const [getS3DropDownData, setGetS3DropDownData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [getS4ColumnData, setGetS4ColumnData] = useState([]);
  const [getS4ItemData, setGetS4ItemData] = useState([]);
  let source_column_name = [];
  let source_distinct_values = [];

  const modifyData = async (data) => {
    console.log("modify", data);
    setTestData(data);
    // for (let d of data) {
    //   source_column_name.push(d.source_column_name);
    //   source_distinct_values.push(d.source_distinct_values);
    // }
    //
    // setGetS4ColumnData(source_column_name);
    // setGetS4ItemData(source_distinct_values);
    // console.log("col",source_column_name);
    //   console.log("item",source_distinct_values);
  };

  const s3ColumnData = async (columns) => {
    // console.log(columns);
    await setGetS3DropDownData(columns);
  };
  // console.log("after setting",getS3DropDownData)

  const s4ColumnData = async (columns) => {
    // const newCol = columns.filter((col, i) => columns.indexOf(col) === i);
    // await setGetS4ColumnData(newCol);
  };
  const s4ItemData = async (items) => {
    // console.log("s4", items);
    // await setGetS4ItemData(items);
  };

  const handleFileRead = (e) => {
    const content = e.target.result;
    const lines = content.split("\n");
    const columnNames = lines[0].split(",");
    setColumns(columnNames);
    let start = 0;
    for (var colName of columnNames) {
      tempColumnData[colName] = start;
      start++;
    }
  };

  const [templateTypeList, setTemplateTypeList] = useState([
    "TemplateType1",
    "TemplateType2",
    "TemplateType3",
    "TemplateType4",
  ]);

  const [fileExtensionList, setFileExtensionList] = useState([
    "XLSX",
    "XLS",
    "CSV",
    "TXT",
  ]);

  const [templateNameList, setTemplateNameList] = useState([
    "TemplateName1",
    "TemplateName2",
    "TemplateName3",
    "TemplateName4",
  ]);

  const [sheetNameList, setSheetNameList] = useState([
    "SheetName1",
    "SheetName2",
    "SheetName3",
    "SheetName4",
  ]);

  const [companyNameList, setCompanyNameList] = useState([
    "CompanyName1",
    "CompanyName2",
    "CompanyName3",
    "CompanyName4",
  ]);

  const [startRowList, setStartRowList] = useState(["1", "2", "3", "4"]);

  const [countryList, setCountryList] = useState([]);
  const fetchOptions = async () => {
    let countriesUrl = "https://trial.mobiscroll.com/content/countries.json";
    let data = await fetch(countriesUrl);
    let countriesJson = await data.json();
    let temp_country = [];
    for (const country in countriesJson) {
      temp_country.push(countriesJson[country].text);
    }
    setCountryList(temp_country);
  };

  const [dateformatList, setDateFormatList] = useState([
    "YYYY-MM-DD",
    "MM-DD-YYYY",
    "DD-MM-YYYY",
  ]);

  const [benefitProgramList, setBenefitProgramList] = useState([
    "program1",
    "program2",
    "program3",
    "program4",
  ]);

  const [employeePrimaryKeyList, setemployeePrimaryKeyList] = useState([
    "primaryKey1",
    "primaryKey2",
    "primaryKey3",
    "primaryKey4",
  ]);

  const [benefitPlanList, setBenefitPlanList] = useState([
    "plan1",
    "plan2",
    "plan3",
    "plan4",
  ]);

  const [templateSubmissionList, setTemplateSubmissionList] = useState([
    "Upload File",
    "Submit e-form",
  ]);

  const [masterJson, setMasterJson] = useState([]);
  const [masterJsonWait, setMasterJsonWait] = useState(true);
  const [
    templateAndbenefitprogramTypeList,
    settemplateAndbenefitprogramTypeList,
  ] = useState([]);

  const [tempCompanyNames, setTempCompanyNames] = useState([]);

  useEffect(() => {
    const getUploadSelection = async () => {
      // const uploadSelection = await API.graphql({
      //   query: queries.readIntegrateDetails,
      // });
      //
      // let tempUploadSelection = uploadSelection.data.readIntegrateDetails;
      // setMasterJson(tempUploadSelection);
      //   console.log(tempUploadSelection)
      // setMasterJsonWait(false);
      // let tempCompanyId = [];
      // for (var i of tempUploadSelection) {
      //   if (
      //     tempCompanyId.company_id !== i.company_id &&
      //     i.company_id.length > 0
      //   ) {
      //     let tempId = {
      //       company_id: "",
      //       company_name: "",
      //     };
      //     tempId.company_id = i.company_id;
      //     tempId.company_name = i.company_name;
      //     tempCompanyId.push(tempId);
      //   } else {
      //   }
      // }
      //
      // const companyNamesList = [];
      // const uniqueMapId = new Map();
      // for (const obj of tempCompanyId) {
      //   const objString = JSON.stringify(obj);
      //   if (!uniqueMapId.has(objString)) {
      //     uniqueMapId.set(objString, true);
      //     companyNamesList.push(obj);
      //   }
      // }
      // setCompanyNameList(companyNamesList);
    };

    const getCreateTemplateCompanyNames = async () => {
      const createTemplateCompanyNames = await API.graphql({
        query: mutations.CompanyListUseridFilter,
        variables: {
          userid: localStorage.getItem("loggedInUser"),
        },
      });
      setTempCompanyNames(
        createTemplateCompanyNames.data.CompanyListUseridFilter,
      );

      for (var cn of tempCompanyNames) {
        let tempCompanyName = {
          companyNameId: "",
          companyNameDisplayName: "",
        };
        tempCompanyName.companyNameId = cn.company_id;
        tempCompanyName.companyNameDisplayName = cn.company_name;
        companyNameList.push(tempCompanyName);
      }
    };

    const getTemplateTypeAndBenefitProgramType = async () => {
      const templateAndbenefitprogramSelection = await API.graphql({
        query: queries.getIntegrateConfValues,
      });
      // console.log(templateAndbenefitprogramSelection.data);integratecon

      settemplateAndbenefitprogramTypeList(
        templateAndbenefitprogramSelection.data.getIntegrateConfValues,
      );
    };

    getCreateTemplateCompanyNames();
    getUploadSelection();
    getTemplateTypeAndBenefitProgramType();

    return () => {};
  }, []);
  // console.log(masterJson)
  // console.log(companyNameList)

  useEffect(() => {
    fetchOptions();
    return () => {};
  }, []);

  return (
    <IntegrateContext.Provider
      value={{
        modifyData,
        tempCompanyNames,
        masterJson,
        masterJsonWait,
        templateAndbenefitprogramTypeList,
        getS4ColumnData,
        getS4ItemData,
        testData,
        getS3DropDownData,
        setGetS3DropDownData,
        s4ColumnData,
        s4ItemData,
        s3ColumnData,
        templateTypeList,
        templateSubmissionList,
        fileExtensionList,
        templateNameList,
        sheetNameList,
        companyNameList,
        startRowList,
        countryList,
        dateformatList,
        benefitProgramList,
        employeePrimaryKeyList,
        benefitPlanList,
        handleFileRead,
        items,
        columns,
        tempColumnData,
      }}
    >
      {children}
    </IntegrateContext.Provider>
  );
}

export function IContext() {
  return useContext(IntegrateContext);
}
