import { React, useState } from "react";
import { BiRightArrowAlt } from "react-icons/bi";

export default function ColMap({ column, itno, parentItno, items, getMappedOption }) {
  const [value, setValue] = useState("Ignore");
    // console.log(column)
    // console.log(value)
  let arrowStyles = { padding: "2px", color: "#9ca3af", fontSize: "30px" };

  const handleChange = (e) => {
    let changedValue = e.target.value;
    setValue(changedValue);
    getMappedOption(itno, changedValue);
  };

  return (
    <>
      <style>
        {`
        select {
                 -webkit-appearance: none;
                 -moz-appearance: none;
                 background: transparent;
                 background-image: url("data:image/svg+xml;utf8,<svg fill='%230000A3' height='24' padding-top='10' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                 background-repeat: no-repeat;
                 background-position-x: 100%;
                 background-position-y: 12px;
                 border: 1px solid #dfdfdf;
                 border-radius: 2px;
            }
          `}
      </style>
      <div className="flex items-center py-2">
        <div className="flex border border-gray-400 w-[250px] h-[50px]">
          <div
            className={`my-1 mx-2 w-3 ${
              // value === "Ignore" ? "bg-[#C5AB22]" : value.length > 0 ? "bg-[#22C55E]" : "bg-[#A30000]"

              value === "Ignore" ? "bg-[#C5AB22]" : value.length > 0 ? "bg-[#22C55E]" : ""
            }`}
          />
          <p className="capitalize py-3 text-gray-400 pl-4 text-center">
            {column.length > 20 ? column.slice(0, 20) + "..." : column}
          </p>
        </div>
        <div className="mx-4">
          {/*"-->"*/}
          <BiRightArrowAlt style={arrowStyles} />
        </div>
        <div>
          <select
            className="flex border text-gray-400 border-gray-400 w-[250px] h-[50px] bg-white px-4 mr-2"
            value={value}
            onChange={handleChange}
            key={itno}
          >
            <option value="Ignore">Ignore</option>
            {items.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

// onChange(e, dest) setText(e.target.value); dest=text
