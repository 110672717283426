import { BsBoxes } from "react-icons/bs";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { React, useState, useEffect, useRef } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
// import { API } from "aws-amplify";
import ChatBot from "../../components/ChatBot.jsx";
import * as mutations from "../../graphql/mutations.js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Amplify, { Auth, API, Storage } from "aws-amplify";

// const downloadFile = async () => {
//    try {
//      const fileKey = 'create-template-uploads/Outbound/0002501194/Test - Benefit Census 7820244182 6.csv'; // Replace with your file key
//      const url = await Storage.get(fileKey);
//      window.open(url, '_blank');
//    } catch (error) {
//      console.error('Error downloading file:', error);
//    }
//  };

export default function ManageReports({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const manageTemplateData = async () => {
      const mTD = await API.graphql({
        query: mutations.ReportRunFilter,
        variables: { userid: user },
      });
      setRows(mTD.data.ReportRunFilter);
      setWait(false);
    };

    manageTemplateData();

    return () => {};
  }, []);

    console.log(rows)
  const columns = [
    {
      field: "company_name",
      renderHeader: () => <strong>{"Company"}</strong>,
      width: 200,
    },
    {
      field: "report_type",
      renderHeader: () => <strong>{"Report Type"}</strong>,
      width: 200,
    },
    {
      field: "file_size",
      renderHeader: () => <strong>{"File Size"}</strong>,
      width: 100,
    },
    {
      field: "create_userid",
      renderHeader: () => <strong>{"Ran By"}</strong>,
      width: 150,
    },
    {
      field: "status_timestamp",
      renderHeader: () => <strong>{"Run Time"}</strong>,
      width: 250,
    },
    {
      field: "report_request_id",
      renderHeader: () => <strong>{"Report ID"}</strong>,
      width: 150,
    },
    {
      field: "job_status",
      renderHeader: () => <strong>{"Report Status"}</strong>,
      width: 150,
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  console.log(rows);

  const handleDeleteRow = async () => {
    console.log(selectedRows);
    // let newRows = rows.filter((row) => !selectedRows.includes(row.template_id));
    // setRows(newRows);
    // const deleteOperation = await API.graphql({
    //   query: mutations.deleteTemplateDetails,
    //   variables: {
    //     delete_template_details: selectedRows,
    //     userid: user,
    //   },
    // });
  };

  const handleDownloadFile = async () => {
    if (selectedRows.length === 1) {
      let dLink = rows.filter((row) =>
        row.report_request_id === selectedRows[0] ? row.file_url : "",
      );
      console.log(dLink[0].file_url);
      try {
        const url = await Storage.get(dLink[0].file_url);
        console.log(url);
        window.open(url, "_blank");
      } catch (error) {
        console.log("Error Downloading File", error);
      }
    } else {
      alert("please choose one file to download");
    }
  };

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };

  const navigate = useNavigate();

  const [action, setAction] = useState(false);

  let actionRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!actionRef.current.contains(e.target)) {
        setAction(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      {" "}
      <style>{`.css-1si6n65{overflow-y: hidden!important;} .css-frlfct{color:#9ca3af!important;} .css-y599qu{color:#6b7280!important;}`}</style>
      {/* <Navbar user={user} signOut={signOut} />
      <div className="flex w-full">
        <div>
          <Sidebar />
        </div> */}
        <div className=" xl:ml-[3rem] 2xl:ml-[15rem] h-fit w-[855px] ">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/report" className="px-2">
              Report
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/report/manage-reports">
              Manage Reports
            </Link>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          {/* page Heading*/}
          <div>
            <section className="flex items-center">
              <BsBoxes style={iconStyles} />
              <p className="text-3xl mx-1">Manage Reports</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
          </div>

          <div className="flex items-center  justify-end w-[1050px]">
            <div className="flex justify-end mt-1">
              <section ref={actionRef}>
                <div className="flex w-full justify-center items-center">
                  <div className="flex justify-center items-center space-x-4">
                    <button
                      onClick={() => window.location.reload(false)}
                      className="bg-[#DB2748] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                    >
                      Refresh
                    </button>
                    <button
                      disabled={selectedRows.length > 0 ? false : true}
                      onClick={handleDownloadFile}
                      className={
                        selectedRows.length > 0
                          ? "bg-[#0000A3] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                          : "bg-gray-500 rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white disabled"
                      }
                    >
                      Download
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="h-[450px] w-[1050px] pt-8 mr-4">
            <DataGrid
              style={{ overflowY: "scroll" }}
              rows={rows}
              getRowId={(row) => row.report_request_id}
              checkboxSelection
              columns={columns}
              onRowSelectionModelChange={(row) => setSelectedRows(row)}
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
            />
          </div>
        </div>

        <div className="absolute right-0">
          <ChatBot />
        </div>
      {/* </div> */}
    </>
  );
}

// <div
//   onClick={() => setAction((prev) => !prev)}
//   className="hover:cursor-pointer px-[10px] py-2 text-md border border-gray-400 bg-gray-100 flex items-center"
// >
//   Actions
//   <div className="pl-2">
//     <AiOutlineCaretDown />
//   </div>
// </div>
// {action && (
//   <div className="hover:cursor-pointer absolute mt-[10rem] ml-1 space-y-1 flex z-[1] flex-col justify-center w-[98.4667px] h-fit bg-white shadow-lg py-4 ">
//     <p className=" hover:bg-gray-200 capitalize text-md font-medium text-center">
//       Update
//     </p>
//     <button
//       onClick={handleDeleteRow}
//       className="hover:bg-gray-200 capitalize text-md font-medium text-center"
//     >
//       delete
//     </button>
//     <button
//       onClick={handleDownloadFile}
//       className="hover:bg-gray-200 capitalize text-md font-medium text-center"
//     >
//       download
//     </button>
//   </div>
// )}

// {
//   field: "file_url",
//   renderHeader: () => <strong>{"Download Link"}</strong>,
//   width: 200,
// },
