import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { PiPlus } from "react-icons/pi";
import Alert from "@mui/material/Alert";


export default function SavingsPlanOverview({
  filteredList,
  getSavingsPlanOverviewMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };

  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);
  const [date, setDate] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [autoEnrollment, setAutoEnrollment] = useState("");
  const [retirementPlan, setRetirementPlan] = useState("");
  const finalList = {
      "date": `${date.$y}-${(date.$M + 1) > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${(date.$D) > 9 ? date.$D : `0${date.$D}`}`,
    vendor_name:vendorName,
    auto_enrollment:autoEnrollment,
    retirement_plan:retirementPlan,
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getSavingsPlanOverviewMappedValues(finalList, `${date.$y}-${(date.$M + 1) > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${(date.$D) > 9 ? date.$D : `0${date.$D}`}`);
    }
  };

  const handleVendorNameChange = (event) => {
    setVendorName(event.target.value);
  };
  const handleAutoEnrollmentChange = (event) => {
    setAutoEnrollment(event.target.value);
  };
  const handleRetirementPlanChange = (event) => {
    setRetirementPlan(event.target.value);
  };

  let autoEnrollmentList = [];
  let retirementPlanList = [];

  for (let item1 of filteredList) {
    if (item1.attribute_key_name_parent === "auto_enrollment_rule") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = item1.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        item1.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = item1.attribute_list_value_sort_order_child;

      autoEnrollmentList.push(dict1);
    }
  }

  for (let item3 of filteredList) {
    if (item3.attribute_key_name_parent === "retirement_plan_type") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = item3.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        item3.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = item3.attribute_list_value_sort_order_child;

      retirementPlanList.push(dict1);
    }
  }

  autoEnrollmentList.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder
  );
  retirementPlanList.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Benefit Plan Overview</Typography>
      </AccordionSummary>

      <AccordionDetails className="mx-5 mb-3 flex justify-between">
        <div>
          {alert === "success" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="success">
              Success! The details provided have been stored
            </Alert>
          ) : alert === "error" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="error">
              Please, Fill all the fields
            </Alert>
          ) : (
            ""
          )}
        </div>
      </AccordionDetails>

      <AccordionDetails className="grid grid-cols-2 gap-8 mx-5 mb-4">
        <FormControl name="fullWidth2" fullWidth>
          <DatePicker
            label="As Of Date"
            name="date"
            value={date}
            onChange={(date) => setDate(date)}
            format="YYYY-DD-MM"
          />
        </FormControl>

        <FormControl name="fullWidth3" fullWidth></FormControl>

        <FormControl name="fullWidth4" fullWidth>
          <TextField
            id="SavingsPlanVendorName"
            value={vendorName}
            label="Vendor Name"
            onChange={handleVendorNameChange}
            variant="outlined"
          />
        </FormControl>

        <FormControl name="fullWidth5" fullWidth>
          <InputLabel id="SavingsPlanInvoiceFrequency2">
            Automatic enrollment date
          </InputLabel>
          <Select
            labelId="SavingsPlanInvoiceFrequency3"
            id="SavingsPlanInvoiceFrequency4"
            value={autoEnrollment}
            label="Automatic enrollment date"
            onChange={handleAutoEnrollmentChange}
          >
            {autoEnrollmentList.map((item, key) => (
              <MenuItem value={item.listValueKey} key={key}>
                {item.listValueDisplayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl name="fullWidth6" fullWidth>
          <InputLabel id="SavingsPlanInusredStatus2">
            Retirement Savings Plan Type
          </InputLabel>
          <Select
            labelId="SavingsPlanInusredStatus3"
            // valueId="SavingsPlanInusredStatus4"
            id="SavingsPlanInusredStatus5"
            value={retirementPlan}
            label="Retirement Savings Plan Type"
            onChange={handleRetirementPlanChange}
          >
            {retirementPlanList.map((item, key) => (
              <MenuItem value={item.listValueKey} key={key}>
                {item.listValueDisplayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="mt-20 w-full flex justify-end items-center">
          <button
            type="submit"
            className="ml-5 bg-[#0000A3] py-[6px] shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg  px-6  text-white"
            onClick={(e) => {
              handleSave(e);
            }}
          >
            Save
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

      // <div className="pt-5">
      //   {" "}
      //   {JSON.stringify(
      //     [
      //       [date.$y, date.$M + 1, date.$D],
      //       vendorName,
      //       autoEnrollment,
      //       retirementPlan,
      //     ],
      //     null,
      //     "    "
      //   )}
      // </div>
