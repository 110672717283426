import { React, useState, useEffect, useRef } from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { withStyles } from '@mui/material/styles';
import { MdSend,MdCancelScheduleSend } from "react-icons/md";
import Paper from "@mui/material/Paper";
import imgAC from "../assets/imgAC.png";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries.js";
import * as mutations from "../graphql/mutations.js";
import * as subscriptions from "../graphql/subscriptions.js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TypingAnimation from "./TypingAnimation";
import { v4 as uuidv4 } from "uuid";
import { dividerClasses } from "@mui/material";
import { Tooltip } from "@mui/material";

export default function ChatBot() {
  const [sessionId, setSessionId] = useState("");
  const [timestamp, setTimestamp] = useState("");
  useEffect(() => {
    localStorage.setItem("chat_bot_session_id", JSON.stringify(uuidv4()));
    setBenefitPoliciesClick(false);
    setAnalyze(false);
    setWebNavClick(true);
    // localStorage.setItem("chat_bot_timestamp", JSON.stringify(new Date()));
  }, []);

  // console.log(
  //   JSON.parse(localStorage.getItem("chat_bot_session_id")),
  //   JSON.parse(localStorage.getItem("chat_bot_timestamp")),
  // );
  const [assistantState, setassistantState] = useState(
    JSON.parse(localStorage.getItem("assistantStateForRefresh")) || false,
  );
  const [prompt, setPrompt] = useState("");
  const [topicClick, setTopicClick] = useState(false);
  const [chatClick, setChatClick] = useState(false);
  const [webNavClick, setWebNavClick] = useState(true);
  const [benefitPoliciesClick, setBenefitPoliciesClick] = useState(false);
  const [analyze, setAnalyze] = useState(false);
  const [analyzeFilter1, setAnalyzeFilter1] = useState([]);
  const [analyzeFilter2, setAnalyzeFilter2] = useState([]);
  const [analyzeFilter3, setAnalyzeFilter3] = useState([]);
  const [analyzeFilter4, setAnalyzeFilter4] = useState([]);
  const [benefitPoliciesData, setBenefitPoliciesData] = useState([]);
  const [analyzeData, setAnalyzeData] = useState([]);
  const [wait, setWait] = useState(true);
  const [benefitProgram, setBenefitProgram] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [country, setCountry] = useState([]);
  const [planName, setPlanName] = useState([]);
  const [commonPromptsWebNav, setCommonPromptsWebNav] = useState([]);
  const [commonPromptsBenefitPolicies, setCommonPromptsBenefitPolicies] =
    useState([]);
  const [commonPromptsAnalyze, setCommonPromptsAnalyze] = useState([]);
  const [chatQuestionAndAnswerArray, setChatQuestionAndAnswerArray] = useState(
    [],
  );
  const [chatBoxClick, setChatBoxClick] = useState(false);
  let benefitProgramList = [];
  let companyNameList = [];
  let benefitNameList = [];
  let countryList = [];
  let analyzeCompanyNameList = [];
  let analyzeReportTypeList = [];
  let analyzeReportFileNameList = [];
  let analyzeReportIdList = [];
  let duplicateAnalyzeCompanyNameList = [];
  let duplicateAnalyzeReportTypeList = [];
  let duplicateAnalyzeReportFileNameList = [];
  let duplicateAnalyzeReportIdList = [];
  let duplicatebenefitProgramList = [];
  let duplicatecompanyNameList = [];
  let duplicatebenefitNameList = [];
  let duplicatecountryList = [];

  const handleAssistantOpen = () => {
    setassistantState(true);
    localStorage.setItem("assistantStateForRefresh", JSON.stringify(true));
  };
  // console.log(JSON.parse(localStorage.getItem("assistantStateForRefresh")));

  const handleAssistantClose = () => {
    // setBenefitPoliciesClick(false);
    // setAnalyze(false);
    // setWebNavClick(true);
    setassistantState(false);
    localStorage.removeItem("chat_bot_session_id");
    localStorage.setItem("assistantStateForRefresh", JSON.stringify(false));
    // localStorage.removeItem("chat_bot_timestamp");
  };

  // console.log(JSON.parse(localStorage.getItem("assistantStateForRefresh")));
  const subscribe1 = async (question, timestamp) => {
    // console.log(JSON.parse(localStorage.getItem("chat_bot_session_id")));
    // let timestamp = new Date();
    // console.log("time", JSON.parse(timestamp));
    // console.log(
    //   "time1",
    //   JSON.parse(localStorage.getItem("chat_bot_timestamp")),
    // );
    let chat = {
      question: "",
      answer: "",
    };
    chat.question = question;
    setChatQuestionAndAnswerArray([...chatQuestionAndAnswerArray, chat]);
    // console.log("waiting for answer outside");
    const sub0Response = await API.graphql(
      graphqlOperation(subscriptions.onLLMResponseCreated, {
        session_id: JSON.parse(localStorage.getItem("chat_bot_session_id")),
        timestamp: JSON.parse(timestamp),
      }),
    ).subscribe({
      next: async ({ value }) => {
        // console.log(value);
        // console.log("waiting for answer");
        const llm_response = await value;
        // setTempAns(llm_response.data.onLLMResponseCreated.llm_response);
        setTempAns("success");
        chat.answer = await llm_response.data.onLLMResponseCreated.llm_response;
        setChatQuestionAndAnswerArray([...chatQuestionAndAnswerArray, chat]);
        // console.log("answer given");
      },
      error: (error) => console.warn(error),
    });
    // console.log("first sub done");
  };

  const subscribe2 = async (timestamp) => {
    const sub1Response = await API.graphql(
      graphqlOperation(subscriptions.onLLMResponseSourceCreated, {
        session_id: JSON.parse(localStorage.getItem("chat_bot_session_id")),
        timestamp: JSON.parse(timestamp),
      }),
    ).subscribe({
      next: ({ value }) => {
        const llm_response_from_2_subscription = value;
        console.log(llm_response_from_2_subscription);
      },
      error: (error) => console.warn(error),
    });

    console.log("second sub done");
  };

  const dbWriting = async (prompt, topic, timestamp) => {
    // console.log(topic);
    // console.log(prompt);
    // console.log(
    //   topic,
    //   analyzeFilter4,
    //   prompt,
    //   analyzeFilter2,
    //   analyzeFilter3,
    //   JSON.parse(timestamp),
    // );
    const sub2Response = await API.graphql({
      query: mutations.createAcropolisChat,
      variables: {
        input: {
          chat_filter: [
            {
              company_id: companyName,
              country: country,
              benefit_program: benefitProgram,
              benefit_plan: planName,
            },
          ],
          chat_topic: topic,
          file_url: analyzeReportFileNameList[0]?.fileUrl,
          llm_response: "",
          prompt_id: "1",
          prompt_text: prompt,
          question_id: "1",
          report_id: analyzeFilter2,
          session_id: JSON.parse(localStorage.getItem("chat_bot_session_id")),
          report_request_id: analyzeFilter3,
          timestamp: JSON.parse(timestamp),
          userid: localStorage.getItem("loggedInUser"),
          web_url: window.location.href,
        },

        // input: {
        //   chat_filter: [
        //     {
        //       company_id: companyName[0],
        //       country: country[0],
        //       benefit_program: benefitProgram[0],
        //       benefit_plan: planName[0],
        //     },
        //   ],
        //   chat_topic: topic,
        //   file_url: analyzeReportFileNameList[0]?.fileUrl,
        //   llm_response: "",
        //   prompt_id: "1",
        //   prompt_text: prompt,
        //   question_id: "1",
        //   report_id: analyzeFilter2[0],
        //   session_id: JSON.parse(localStorage.getItem("chat_bot_session_id")),
        //   report_request_id: analyzeFilter3[0],
        //   timestamp: JSON.parse(timestamp),
        //   userid: localStorage.getItem("loggedInUser"),
        //   web_url: window.location.href,
        // input: {
            //
        //   chat_filter: [],
        //   chat_topic: "analyze",
        //   file_url: [
        //     "run_report_output/standard_reports/rpt_zn.design_details_c7fe1e3c743840e694eecd561b9d9200.csv",
        //   ],
        //   llm_response: "",
        //   prompt_id: "1",
        //   prompt_text:
        //     "How many unique benefit plan names are there in this file??",
        //   question_id: "1",
        //   report_id: ["benefit_inventory"],
        //   session_id: JSON.parse(localStorage.getItem("chat_bot_session_id")),
        //   report_request_id: ["c7fe1e3c-7438-40e6-94ee-cd561b9d9200"],
        //   timestamp: JSON.parse(timestamp),
        //   userid: "todd",
        //   web_url: "",
        // },
        // input: {
        //   chat_filter: [],
        //   chat_topic: topic,
        //   file_url: [""],
        //   llm_response: "",
        //   prompt_id: "1",
        //   prompt_text: prompt,
        //   question_id: "1",
        //   report_id: [""],
        //   session_id: JSON.parse(localStorage.getItem("chat_bot_session_id")),
        //   report_request_id: [""],
        //   timestamp: JSON.parse(localStorage.getItem("chat_bot_timestamp")),
        //   userid: "datta",
        //   web_url: "",
        // },
      },
    });
    // console.log("DB Writing Done");
  };

  const [tempAns, setTempAns] = useState("");
  const [commonPromptsClick, setCommonPromptsClick] = useState(true);
  const [filterClick, setFilterClick] = useState(true);

  const handleCommonPromptsClick = async (cp) => {
    let timestamp = JSON.stringify(new Date());
    // console.log(tempAns);
    setChatBoxClick(true);
    setCommonPromptsClick(false);
    setFilterClick(false);
    // console.log(webNavClick, benefitPoliciesClick, analyze);
    // setAnalyze(false);
    // setBenefitPoliciesClick(false);
    // setWebNavClick(false);
    // console.log(cp);
    // let chat = {
    //   question: "",
    //   answer: "",
    // };
    // chat.question = cp;
    // setChatQuestionAndAnswerArray([...chatQuestionAndAnswerArray, chat]);
    subscribe1(cp, timestamp);
    subscribe2(timestamp);
    dbWriting(
      cp,
      webNavClick === true
        ? "web_navigation"
        : benefitPoliciesClick === true
        ? "design"
        : analyze === true
        ? "analyze"
        : "",
      timestamp,
    );

    setTimeout(async () => {
      // console.log("calling sub1");
      await subscribe1(cp, timestamp);
      // console.log("inside function", tempAns);
      // chat.answer = tempAns;
      // setChatQuestionAndAnswerArray([...chatQuestionAndAnswerArray, chat]);
    }, 10000);
  };

  // console.log("ans", tempAns);

  const handleUserTextClick = () => {
    let timestamp = JSON.stringify(new Date());
    setTempAns("");
    setChatBoxClick(true);
    setCommonPromptsClick(false);
    setFilterClick(false);
    // setAnalyze(false);
    // setBenefitPoliciesClick(false);
    // setWebNavClick(false);

    subscribe1(prompt, timestamp);
    subscribe2(timestamp);
    dbWriting(
      prompt,
      webNavClick === true
        ? "web_navigation"
        : benefitPoliciesClick === true
        ? "design"
        : analyze === true
        ? "analyze"
        : "",
      timestamp,
    );

    setTimeout(async () => {
      // console.log("calling sub1");
      await subscribe1(prompt, timestamp);
    }, 10000);
    // console.log("inside user", chatQuestionAndAnswerArray);
    setPrompt("");
  };
  // console.log(chatQuestionAndAnswerArray);

  const getTopicData = async () => {
    // setLoading(true);
    const allRows = await API.graphql({
      query: mutations.BenefitInventoryUserIdFilter,
      variables: { userid: localStorage.getItem("loggedInUser") },
    });

    const mTD = await API.graphql({
      query: mutations.ReportRunFilter,
      variables: { userid: localStorage.getItem("loggedInUser") },
    });

    const cpWN = await API.graphql({
      query: mutations.filterAcropolisChatPrompts,
      variables: { topic: "web_navigation" },
    });

    const cpBP = await API.graphql({
      query: mutations.filterAcropolisChatPrompts,
      variables: { topic: "design" },
    });

    const cpA = await API.graphql({
      query: mutations.filterAcropolisChatPrompts,
      variables: { topic: "analyze" },
    });

    // export const filterAcropolisChatPrompts = /* GraphQL */ `
    //   mutation FilterAcropolisChatPrompts($topic: String) {
    //     filterAcropolisChatPrompts(topic: $topic) {
    //       topic
    //       prompt_id
    //       prompt_text
    //     }
    //   }
    // `;

    setBenefitPoliciesData(allRows.data.BenefitInventoryUserIdFilter);
    setAnalyzeData(mTD.data.ReportRunFilter);
    setCommonPromptsWebNav(cpWN.data.filterAcropolisChatPrompts);
    setCommonPromptsBenefitPolicies(cpBP.data.filterAcropolisChatPrompts);
    setCommonPromptsAnalyze(cpA.data.filterAcropolisChatPrompts);
    setWait(false);
    // setLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("assistantStateForRefresh", JSON.stringify(false));
    getTopicData();
  }, []);

  // console.log("i", commonPromptsWebNav);
  // console.log("j", commonPromptsBenefitPolicies);
  // console.log("k", commonPromptsAnalyze);

  for (let cn of benefitPoliciesData) {
    let dict1 = {
      key: "",
      displayName: "",
    };
    dict1.key = cn.company_id;
    dict1.displayName = cn.company_name;
    duplicatecompanyNameList.push(dict1);
  }
  const uniqueMap2 = new Map();
  for (const obj of duplicatecompanyNameList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap2.has(objString)) {
      uniqueMap2.set(objString, true);
      companyNameList.push(obj);
    }
  }
  // console.log(companyNameList);
  // console.log(benefitPoliciesData);

  for (let c of benefitPoliciesData) {
    for (let cn of companyName) {
      if (c.company_id === cn) {
        let dict1 = {
          key: "",
          displayName: "",
          iso_code: "",
        };
        dict1.key = c.country;
        dict1.displayName = c.country_name;
        // dict1.iso_code = c.two_digit_iso_code;
        duplicatecountryList.push(dict1);

        // const uniqueArray = [];
        // const seenItems = new Set();
        //
        // for (const item of originalArray) {
        //     const identifier = `${item.key}-${item.displayName}-${item.iso_code}`;
        //     if (!seenItems.has(identifier)) {
        //         uniqueArray.push(item);
        //         seenItems.add(identifier);
        //     }
        // }
      }
    }
  }
  const uniqueMap1 = new Set();
  for (const obj of duplicatecountryList) {
    const identifier = `${obj.key}-${obj.displayName}-${obj.iso_code}`;
    if (!uniqueMap1.has(identifier)) {
      countryList.push(obj);
      uniqueMap1.add(identifier);
    }
  }
  // console.log(companyName, country);
  // console.log(benefitPoliciesData);

  for (let bpr of benefitPoliciesData) {
    for (let cn of companyName) {
      if (bpr.company_id === cn) {
        for (let c of country) {
          if (bpr.country === c) {
            let dict1 = {
              key: "",
              displayName: "",
            };
            dict1.key = bpr.benefit_id;
            dict1.displayName = bpr.benefit_program_display_name;
            duplicatebenefitProgramList.push(dict1);
          }
        }
      }
    }
    // if (bpr.company_id === companyName[0] && bpr.country === country[0]) {
    // }
  }
  const uniqueMap3 = new Map();
  for (const obj of duplicatebenefitProgramList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap3.has(objString)) {
      uniqueMap3.set(objString, true);
      benefitProgramList.push(obj);
    }
  }

  for (let bpl of benefitPoliciesData) {
    for (let cn of companyName) {
      if (bpl.company_id === cn) {
        for (let c of country) {
          if (bpl.country === c) {
            for (let bp of benefitProgram) {
              if (bpl.benefit_id === bp) {
                let dict1 = {
                  key: "",
                  displayName: "",
                };
                dict1.key = bpl.benefit_plan_id;
                dict1.displayName = bpl.benefit_plan_display_name;
                duplicatebenefitNameList.push(dict1);
              }
            }
          }
        }
      }
    }
    // if (
    //   bpl.company_id === companyName[0] &&
    //   bpl.country === country[0] &&
    //   bpl.benefit_id === benefitProgram[0]
    // ) {
    //   let dict1 = {
    //     key: "",
    //     displayName: "",
    //   };
    //   dict1.key = bpl.benefit_plan_id;
    //   dict1.displayName = bpl.benefit_plan_display_name;
    //   duplicatebenefitNameList.push(dict1);
    // }
  }
  const uniqueMap4 = new Map();
  for (const obj of duplicatebenefitNameList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap4.has(objString)) {
      uniqueMap4.set(objString, true);
      benefitNameList.push(obj);
    }
  }

  for (let acn of analyzeData) {
    let dict1 = {
      key: "",
      displayName: "",
    };
    dict1.key = acn.company_id;
    dict1.displayName = acn.company_name;
    duplicateAnalyzeCompanyNameList.push(dict1);
  }
  const uniqueMap5 = new Map();
  for (const obj of duplicateAnalyzeCompanyNameList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap5.has(objString)) {
      uniqueMap5.set(objString, true);
      analyzeCompanyNameList.push(obj);
    }
  }

  for (let art of analyzeData) {
    for (let cn of analyzeFilter1) {
      if (art.company_id === cn) {
        let dict1 = {
          key: "",
          displayName: "",
        };
        dict1.key = art.report_type;
        dict1.displayName = art.report_type_display_name;
        duplicateAnalyzeReportTypeList.push(dict1);
      }
    }
    // if (art.company_id === analyzeFilter1[0]) {
    // }
  }
  const uniqueMap6 = new Map();
  for (const obj of duplicateAnalyzeReportTypeList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap6.has(objString)) {
      uniqueMap6.set(objString, true);
      analyzeReportTypeList.push(obj);
    }
  }

  for (let arid of analyzeData) {
    for (let cn of analyzeFilter1) {
      if (arid.company_id === cn) {
        for (let rt of analyzeFilter2) {
          if (arid.report_type === rt) {
            let dict1 = {
              // key: "",
              // displayName: "",
              reportId: "",
            };
            // dict1.key = arid.report_type;
            // dict1.displayName = arid.company_name;
            dict1.reportId = arid.report_request_id;
            duplicateAnalyzeReportIdList.push(dict1);
          }
        }
      }
    }
    // if (
    //   arid.company_id === analyzeFilter1[0] &&
    //   arid.report_type === analyzeFilter2[0]
    // ) {
    // }
  }
  const uniqueMap7 = new Map();
  for (const obj of duplicateAnalyzeReportIdList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap7.has(objString)) {
      uniqueMap7.set(objString, true);
      analyzeReportIdList.push(obj);
    }
  }

  // console.log(analyzeData);
  for (let arfn of analyzeData) {
    for (let cn of analyzeFilter1) {
      if (arfn.company_id === cn) {
        for (let rt of analyzeFilter2) {
          if (arfn.report_type === rt) {
            for (let rri of analyzeFilter3) {
              if (arfn.report_request_id === rri) {
                let dict1 = {
                  // key: "",
                  // displayName: "",
                  fileSize: "",
                  fileUrl: "",
                };
                dict1.fileSize = arfn.file_size;
                dict1.fileUrl = arfn.file_url;
                duplicateAnalyzeReportFileNameList.push(dict1);
              }
            }
          }
        }
      }
    }
    // if (
    //   arfn.company_id === analyzeFilter1[0] &&
    //   arfn.report_type === analyzeFilter2[0] &&
    //   arfn.report_request_id === analyzeFilter3[0]
    // ) {
    // }
  }
  const uniqueMap8 = new Map();
  for (const obj of duplicateAnalyzeReportFileNameList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap8.has(objString)) {
      uniqueMap8.set(objString, true);
      analyzeReportFileNameList.push(obj);
    }
  }
  // console.log(
  //   analyzeCompanyNameList,
  //   analyzeReportTypeList,
  //   analyzeReportIdList,
  //   analyzeReportFileNameList,
  // );

  const handleBenefitProgramChange = (event) => {
    const {
      target: { value },
    } = event;

    setBenefitProgram(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;

    setCountry(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleCompanyNameChange = (event) => {
    const {
      target: { value },
    } = event;

    setCompanyName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handlePlanNameChange = (event) => {
    const {
      target: { value },
    } = event;

    setPlanName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleAnalyzeFilter1Change = (event) => {
    const {
      target: { value },
    } = event;

    setAnalyzeFilter1(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleAnalyzeFilter2Change = (event) => {
    const {
      target: { value },
    } = event;

    setAnalyzeFilter2(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
    setAnalyzeFilter2(event.target.value);
  };
  const handleAnalyzeFilter3Change = (event) => {
    const {
      target: { value },
    } = event;

    setAnalyzeFilter3(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleAnalyzeFilter4Change = (event) => {
    const {
      target: { value },
    } = event;

    setAnalyzeFilter4(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  // const useStyles = makeStyles((Theme) =>
  //   createStyles({
  //     accordionRoot: {
  //       height: "15px",
  //     },
  //   }),
  // );
  // const classes = useStyles();
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={wait}
      >
        <CircularProgress sx={{ color: "#DB2748" }} />{" "}
      </Backdrop>
      <div className="flex">
        <style>{`*:focus {outline: none;}`}</style>
        <div
          className={
            assistantState
              ? "flex w-[450px] h-[90vh] bg-white justify-between hover:cursor-pointer"
              : "hidden hover:cursor-pointer"
          }
        >
          <div className="bg-white w-full">
            <div className="flex justify-between items-center mx-3 my-4">
              <div className="hover:cursor-pointer">
                <img src={imgAC} alt="" className="h-[40px]" />
              </div>
              <div className="text-xl font-semibold">Acropolis Copilot</div>
              <div onClick={handleAssistantClose}>
                <AiOutlineClose size={20} />
              </div>
            </div>
            <hr />

            <div className="mt-6 mx-5 space-y-1">
              {chatBoxClick && (
                <Paper elevation={2}>
                  <div className="xl:h-[495px] 2xl:h-[655px] overflow-y-scroll">
                    {chatQuestionAndAnswerArray.map((chat, index) => (
                      <div key={index} className="flex flex-col space-y-4 mt-4">
                        <div className="bg-[#0000A3] text-white rounded-md py-[6px] px-3 text-lg ml-10 mr-1">
                          {chat.question}
                        </div>
                        <div
                          className={
                            chat.answer.length > 1
                              ? "bg-[#E6E6E6] rounded-md py-[6px] px-3 text-lg mr-10 ml-1"
                              : ""
                          }
                        >
                          {chat.answer.length > 1 ? (
                            chat.answer
                          ) : (
                            <TypingAnimation />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </Paper>
              )}

              <Paper elevation={2}>
                <div className={chatBoxClick ? "hidden" : ""}>
                  <div className="flex justify-center text-2xl py-2">Topic</div>
                  <div className="flex justify-between mx-4">
                    <div
                      className={
                        webNavClick
                          ? "text-sm uppercase text-[#0000A3] font-bold hover:cursor-pointer"
                          : "text-sm uppercase font-bold hover:cursor-pointer"
                      }
                      onClick={() => {
                        setWebNavClick(true);
                        setBenefitPoliciesClick(false);
                        setAnalyze(false);
                      }}
                    >
                      web nav
                      {webNavClick && (
                        <div className="h-[2px] bg-[#0000A3] w-full rounded my-1" />
                      )}
                    </div>
                    <div
                      className={
                        benefitPoliciesClick
                          ? "text-sm uppercase  text-[#0000A3] font-bold hover:cursor-pointer"
                          : "text-sm uppercase font-bold hover:cursor-pointer"
                      }
                      onClick={() => {
                        setWebNavClick(false);
                        setBenefitPoliciesClick(true);
                        setAnalyze(false);
                      }}
                    >
                      benefit policies
                      {benefitPoliciesClick && (
                        <div className="h-[2px] bg-[#0000A3] w-full rounded my-1" />
                      )}
                    </div>
                    <div
                      className={
                        analyze
                          ? "text-sm uppercase text-[#0000A3] font-bold hover:cursor-pointer"
                          : "text-sm uppercase font-bold hover:cursor-pointer"
                      }
                      onClick={() => {
                        setWebNavClick(false);
                        setBenefitPoliciesClick(false);
                        setAnalyze(true);
                      }}
                    >
                      analyze
                      {analyze && (
                        <div className="h-[2px] bg-[#0000A3] w-full rounded my-1" />
                      )}
                    </div>
                  </div>
                </div>
              </Paper>

              {benefitPoliciesClick === true && filterClick && (
                <div>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <div className="uppercase font-semibold">filters</div>
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className="grid grid-cols-2 gap-4 mx-2 mb-1">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={companyName.length > 0 ? false : true}
                      >
                        <InputLabel id="cn2">Company*</InputLabel>
                        <Select
                          labelId="cn3"
                          id="cn4"
                          value={companyName}
                          label="Company"
                          onChange={handleCompanyNameChange}
                          className={companyName.length > 0 ? "fill" : "empty"}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                          multiple
                        >
                          {companyNameList.map((name, key) => (
                            <MenuItem key={key} value={name.key}>
                              {name.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={country.length > 0 ? false : true}
                      >
                        <InputLabel id="c2">Country</InputLabel>
                        <Select
                          labelId="c3"
                          id="c4"
                          value={country}
                          label="country"
                          onChange={handleCountryChange}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                          multiple
                        >
                          {countryList.map((name, key) => (
                            <MenuItem key={key} value={name.key}>
                              {name.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={benefitProgram.length > 0 ? false : true}
                      >
                        <InputLabel id="bp2">Benefit Prg</InputLabel>
                        <Select
                          labelId="bp3"
                          id="bp4"
                          value={benefitProgram}
                          label="benefit Prg"
                          onChange={handleBenefitProgramChange}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                          multiple
                        >
                          {benefitProgramList.map((name, key) => (
                            <MenuItem key={key} value={name.key}>
                              {name.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        name="fullWidth5"
                        fullWidth
                        error={planName.length > 0 ? false : true}
                      >
                        <InputLabel id="bl2">Benefit Plan</InputLabel>
                        <Select
                          labelId="bl3"
                          id="bl4"
                          value={planName}
                          label="benefit plan"
                          onChange={handlePlanNameChange}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                          multiple
                        >
                          {benefitNameList.map((name, key) => (
                            <MenuItem key={key} value={name.key}>
                              {name.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}

              {analyze === true && filterClick && (
                <div className="">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <div className="uppercase font-semibold">filters</div>
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className="grid grid-cols-2 gap-4 mx-2 mb-1">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={analyzeFilter1.length > 0 ? false : true}
                      >
                        <InputLabel id="cn2">Company*</InputLabel>
                        <Select
                          labelId="cn3"
                          id="cn4"
                          value={analyzeFilter1}
                          label="Company"
                          onChange={handleAnalyzeFilter1Change}
                          className={
                            analyzeFilter1.length > 0 ? "fill" : "empty"
                          }
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                          multiple
                        >
                          {analyzeCompanyNameList.map((name, key) => (
                            <MenuItem key={key} value={name.key}>
                              {name.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={analyzeFilter2.length > 0 ? false : true}
                      >
                        <InputLabel id="c2">Report Type</InputLabel>
                        <Select
                          labelId="c3"
                          id="c4"
                          value={analyzeFilter2}
                          label="Report Type"
                          onChange={handleAnalyzeFilter2Change}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                          multiple
                        >
                          {analyzeReportTypeList.map((name, key) => (
                            <MenuItem key={key} value={name.key}>
                              {name.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <div className="col-start-1 col-end-3">
                        <FormControl
                          name="fullWidth1"
                          fullWidth
                          error={analyzeFilter3.length > 0 ? false : true}
                        >
                          <InputLabel id="bp2">Report Id</InputLabel>
                          <Select
                            labelId="bp3"
                            id="bp4"
                            value={analyzeFilter3}
                            label="report id"
                            onChange={handleAnalyzeFilter3Change}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 400 } },
                            }}
                            multiple
                          >
                            {analyzeReportIdList.map((name, key) => (
                              <MenuItem key={key} value={name.reportId}>
                                {name.reportId}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}

              {/*
                                    *
                                            <FormControl
                                              name="fullWidth5"
                                              fullWidth
                                              error={analyzeFilter4.length > 0 ? false : true}
                                            >
                                              <InputLabel id="bl2">File Url</InputLabel>
                                              <Select
                                                labelId="bl3"
                                                id="bl4"
                                                value={analyzeFilter4}
                                                label="file url"
                                                onChange={handleAnalyzeFilter4Change}
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                                multiple
                                              >
                                                {analyzeReportFileNameList.map((name, key) => (
                                                  <MenuItem key={key} value={name.fileUrl}>
                                                    {name.fileUrl}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                    * */}

              {webNavClick && commonPromptsClick && (
                <div className="">
                  <Accordion
                    defaultExpanded
                    // classes={{ root: classes.accordionRoot }}
                    // sx={{ height: '25%' }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <div className="uppercase font-semibold">
                          common prompts
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <div className="">
                      <div className="mx-4 space-y-1 h-[200px] overflow-y-scroll mb-2">
                        {commonPromptsWebNav.map((prompt, key) => (
                          <div
                            key={key}
                            className="bg-[#F2F2FA] text-[#0000A3] px-4 py-2 rounded-xl text-center"
                            onClick={() => {
                              handleCommonPromptsClick(prompt.prompt_text);
                              // console.log("web");
                            }}
                          >
                            {prompt.prompt_text}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Accordion>
                </div>
              )}

              {benefitPoliciesClick === true && commonPromptsClick && (
                <div className="">
                  <Accordion
                    defaultExpanded
                    // classes={{ root: classes.accordionRoot }}
                    // sx={{ height: '25%' }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <div className="uppercase font-semibold">
                          common prompts
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <div className="">
                      <div className="mx-4 space-y-1 max-h-[200px] overflow-y-scroll mb-2">
                        {commonPromptsBenefitPolicies.map((prompt, key) => (
                          <div
                            key={key}
                            className="bg-[#F2F2FA] text-[#0000A3] px-4 py-2 rounded-xl text-center"
                            onClick={() => {
                              handleCommonPromptsClick(prompt.prompt_text);
                            }}
                          >
                            {prompt.prompt_text}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Accordion>
                </div>
              )}

              {analyze === true && commonPromptsClick && (
                <div className="">
                  <Accordion
                    defaultExpanded
                    // classes={{ root: classes.accordionRoot }}
                    // sx={{ height: '25%' }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <div className="uppercase font-semibold">
                          common prompts
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <div className="">
                      <div className="mx-4 space-y-1 h-[200px] overflow-y-scroll mb-2">
                        {commonPromptsAnalyze.map((prompt, key) => (
                          <div
                            key={key}
                            className="bg-[#F2F2FA] text-[#0000A3] px-4 py-2 rounded-xl text-center"
                            onClick={() => {
                              handleCommonPromptsClick(prompt.prompt_text);
                            }}
                          >
                            {prompt.prompt_text}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Accordion>
                </div>
              )}

              <div className="absolute bottom-1 w-[361px] mb-2">
                <div className="border border-black rounded-sm flex items-center">
                  <input
                    // absolute right-0 w-[360.99px]
                    type="text"
                    className="w-full pl-4 py-3 text-sm"
                    placeholder="Message Acro..."
                    onChange={(e) => setPrompt(e.target.value)}
                    value={prompt}
                  />
                  {   (
                        // (companyName.length > 0 || country.length > 0 || benefitProgram.length > 0 || planName.length > 0) && companyName.length > 0 && country.length > 0 && benefitProgram.length > 0 && planName.length > 0 && prompt.length > 0 && benefitPoliciesClick
                        companyName.length > 0 && prompt.length > 0 && benefitPoliciesClick
                      ) || (
                        // (analyzeFilter1.length > 0 || analyzeFilter2.length > 0 || analyzeFilter3.length > 0) && analyzeFilter1.length > 0 && analyzeFilter2.length > 0 && analyzeFilter3.length > 0 && prompt.length > 0 && analyze
                        analyzeFilter1.length > 0  && prompt.length > 0 && analyze
                      )
                      ||
                      (prompt.length > 0 && webNavClick) 
                  
                      ? 
                    <div className= 'pr-8 text-black'
                      onClick={() => handleUserTextClick()}
                    >
                      <MdSend size={20} />
                    </div> : 
                    <div className= 'pr-8 text-grey-300'
                    >
                      {/* <MdCancelScheduleSend size={20} /> */}
                      <Tooltip title="Send disabled">
                      <MdSend
                        size={20}
                        style={{
                          opacity: 0.3, // Lower opacity to make it look disabled
                          pointerEvents: "none" , // Disable click actions
                          cursor: "not-allowed" , // Show appropriate cursor
                        }}
                      />
                      </Tooltip>
                    </div>
                  }   
                  
                </div>
                <div className="flex justify-between mt-3 items-center">
                  {chatBoxClick === true ? (
                    <div
                      className="uppercase bg-[#DB2748] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                      onClick={() => {
                        window.location.reload(false);
                        setassistantState(true);
                      }}
                    >
                      new topic
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <p className="text-sm capitalize">max 1000 characters</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="h-[90vh] w-[1px] bg-[#7A7A7A]" />
            <div>
              <img src={imgAC} alt="" className="h-[30px] mx-3 my-4" />
            </div>
          </div>
        </div>

        <div
          className={assistantState ? "hidden" : "flex hover:cursor-pointer"}
          onClick={handleAssistantOpen}
        >
          <div className="h-[90vh] w-[1px] bg-[#7A7A7A]" />
          <div>
            <img src={imgAC} alt="" className="h-[30px] mx-3 my-4" />
          </div>
        </div>
      </div>
    </>
  );
}

// <div className="text-3xl text-black">ChatBot</div>
//
// <div
//   className={
//     chatClick === true || topicClick === true
//       ? "hidden"
//       : "flex justify-evenly"
//   }
// >
//   <button
//     className="ml-5 bg-[#0000A3] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
//     onClick={() => {
//       setTopicClick(true);
//       setChatClick(false);
//     }}
//   >
//     Topic
//   </button>
//   <button
//     className="ml-5 bg-[#0000A3] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
//     onClick={() => {
//       setTopicClick(false);
//       setChatClick(true);
//     }}
//   >
//     Chat
//   </button>
// </div>
// {topicClick && (
//   <div>
//     <div>
//       <Paper elevation={2}>
//         <div
//           className="absolute"
//           onClick={() => {
//             setChatClick(false);
//             setTopicClick(false);
//           }}
//         >
//           rev
//         </div>
//         <div className="flex justify-center items-center">
//           Topic
//         </div>
//         <div className="flex justify-between mt-4">
//           <button>web nav</button>
//           <button>benefit policies</button>
//           <button>analyze</button>
//         </div>
//       </Paper>
//     </div>
//   </div>
// )}
//
// {chatClick && (
//   <div>
//     <div>
//       <div
//         className="absolute"
//         onClick={() => {
//           setChatClick(false);
//           setTopicClick(false);
//         }}
//       >
//         rev
//       </div>
//       <div className="flex justify-center items-center">Chat</div>
//     </div>
//   </div>
// )}
//
// <div>grid grid-cols-2 space-y-3</div>
