import { createContext, useContext, useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries.js";
import * as mutations from "../graphql/mutations";
import awsExports from "../aws-exports.js";
Amplify.configure(awsExports);
API.configure(awsExports);

const CompanyContext = createContext();

export function CompanyContextProvider({ children }) {
  const [industryAndSubIndustryList, setIndustryAndSubIndustryList] = useState(
    [],
  );
  const [countryAndState, setCountryAndState] = useState([]);
  const [fieldList, setFieldList] = useState([
    "field1",
    "field3",
    "field3",
    "field4",
  ]);
  const [dateformatList, setDateFormatList] = useState([
    "MM-DD-YYYY",
    "DD-MM-YYYY",
  ]);
  const [currencyList, setCurrencyList] = useState([]);
  const [headcount, setHeadcount] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [reconList, setReconList] = useState([]);

  const createCompany = async (
      company_country_hq,
      company_id_status,
      company_name,
      company_state_hq,
      industry_id,
      is_exclude_from_benchmarks,
      poc_email,
      poc_role,
      employer_size,
      organization_id,
      primary_poc,
      sub_industry_id,
      userid,
  ) => {
    const postCreateCompany = await API.graphql({
      query: mutations.createCompany,
      variables: {
      company_country_hq,
      company_id_status,
      company_name,
      company_state_hq,
      industry_id,
      is_exclude_from_benchmarks,
      poc_email,
      poc_role,
      employer_size,
      organization_id,
      primary_poc,
      sub_industry_id,
      userid,
      },
    });
    // await console.log("from Context", postCreateCompany);
  };

  useEffect(() => {
    const companyDropDownData = async () => {
      const readCompanyAttributes = await API.graphql({
        query: queries.readCompanyAttributes,
      });

      const updatedCurrencyList =
        await readCompanyAttributes.data.readCompanyAttributes.filter(
          (item) => {
            return item.attribute_key_display_name === "Currency";
          },
        );

      const updatedDateFormatPreference =
        await readCompanyAttributes.data.readCompanyAttributes.filter(
          (item) => {
            return item.attribute_key_display_name === "Date Format Preference";
          },
        );

      setCurrencyList(updatedCurrencyList);
      setDateFormatList(updatedDateFormatPreference);
    };

    const industryDropDown = async () => {
      const readCompanyAttributes = await API.graphql({
        query: queries.getcompanyindustryattributes,
      });

      setIndustryAndSubIndustryList(
        readCompanyAttributes.data.getcompanyindustryattributes,
      );
    };

    const countryDropDown = async () => {
      const readCompanyAttributes = await API.graphql({
        query: queries.readCountryStateCombo,
      });

      setCountryAndState(readCompanyAttributes.data.readCountryStateCombo);
    };

    const headcountDropDown = async () => {
      const readCompanyAttributes = await API.graphql({
        query: queries.getCommonValues,
      });

      setHeadcount(readCompanyAttributes.data.getCommonValues);
    };

    industryDropDown();
    headcountDropDown();
    countryDropDown();
    companyDropDownData();

    return () => {};
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        reconList,
        profiles,
        countryAndState,
        industryAndSubIndustryList,
        fieldList,
        dateformatList,
        currencyList,
        createCompany,
        headcount,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

export function CContext() {
  return useContext(CompanyContext);
}
