import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  let navigate = useNavigate();
  return (
    <>
      <div className="w-screen h-screen flex flex-col justify-center items-center text-6xl ">
        <p>
          You are not allowed here without login. Please Login First
        </p>

        <button type="button" onClick={() => navigate('/')} className="bg-[#0000a3] text-white py-6 px-8 mt-5">
          Login
        </button>
      </div>
    </>
  );
}
