import { React, useEffect, useState} from "react";
import LoginImage from "../assets/Acropolis Logo.png";
import { Auth, Hub } from "aws-amplify";
import { Authenticator,useAuthenticator,Heading,View,useTheme,Image,Text,Button} from "@aws-amplify/ui-react";
import { Link, useNavigate } from "react-router-dom";

export default function Login({ getUserDetails }) {

  let navigate = useNavigate();
  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.medium}>
          <Image
            alt="Amplify logo"
            // src="https://docs.amplify.aws/assets/logo-dark.svg"
            src = {LoginImage}
            width="50%"
            height="50%"
            border="solid 5px #0000a3"
            borderRadius="50%"
          />
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
  };
  
  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      password: {
        label: 'Password:',
        placeholder: 'Enter your Password:',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm Password:',
        order: 1,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
      },
    },
    forgotPassword: {
      username: {
        placeholder: 'Enter your email:',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        label: 'New Label',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please:',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };

  // const onChange = (e) => {
  //   setUserFiedls({ ...userFields, [e.target.name]: e.target.value });
  // };

  // const onClick = async (e) => {
  //   e.preventDefault();
  //     setWait(true)
  //   try {
  //     const user = await Auth.signIn(userFields.name, userFields.password);
  //     if (!user) {
  //       return;
  //     }
  //     localStorage.setItem("loggedInUser", user.username);
  //     localStorage.setItem("userState", true);
  //     getUserDetails(
  //       localStorage.getItem("loggedInUser"),
  //       localStorage.getItem("userState")
  //     );
  //     navigate("/company");
  //   } catch (error) {
  //     setWait(false)
  //     // alert("Incorrect username or password.")
  //     setSeverity("error");
  //     console.log("error signing in", error);
  //   }
  // };

  // const handleAuthStateChange = async(authState) => {
  //   if (authState === 'authenticated') {
  //     const user = await Auth.currentAuthenticatedUser();
  //     localStorage.setItem("loggedInUser", user.username);
  //     localStorage.setItem("userState", true);
  //     getUserDetails(
  //       localStorage.getItem("loggedInUser"),
  //       localStorage.getItem("userState")
  //     );
  //     navigate("/company");
  //   }
  // };

  useEffect(() => {
    // Listen to Hub events
    const listener = async(data) => {
      switch (data.payload.event) {
        case 'signIn':
          console.log('User signed in:', data.payload.data);
          // handleAuthStateChange('authenticated');
          const user = await Auth.currentAuthenticatedUser();
          localStorage.setItem("loggedInUser", user.username);
          localStorage.setItem("userState", true);
          getUserDetails(
            localStorage.getItem("loggedInUser"),
            localStorage.getItem("userState")
          );
          navigate("/company");
          break;
        case 'signOut':
          console.log('User signed out');
          // handleAuthStateChange('signedOut');
          break;
        case 'signIn_failure':
          console.error('Sign in failed:', data.payload.data);
          break;
        case 'sessionExpired':
          console.log('Session expired');
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);

    // Cleanup subscription on unmount
    return () => Hub.remove('auth', listener);
  }, []);
  

  return (
    
    <Authenticator initialState="signIn" hideSignUp={true} components={components}/>
  );
}
