import React from "react";
import Alert from "@mui/material/Alert";
import {RxCross2} from "react-icons/rx"

export default function Alerts({ severity, info }) {
  return (
    <>
      {severity === "error" ? (
        <div>
          <Alert  severity="error">{info}</Alert>
        </div>
      ) : severity === "success" ? (
        <div>
          <Alert  severity="success">{info}</Alert>
        </div>
      ) : severity === "warning" ? (
        <div>
          <Alert severity="warning">{info}</Alert>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

// personal alert donot remove
//
//            {saveAlert && (
//              <section className="bg-[#D6F5DB] flex items-center">
//                <AiOutlineCheckCircle style={alertStyles} />
//                <p className="px-4 py-2">
//                  <strong>Success! </strong> Your request has been processed
//                </p>
               // <RxCross2
               //   style={alertStyles}
               //   onClick={() => {
               //     setSaveAlert(false);
               //   }}
               // />
//              </section>
//            )}
