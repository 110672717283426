import { React, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Auth, API } from "aws-amplify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Container,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListSubheader
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import * as mutations from "../../graphql/mutations.js";
import { readDashboardList } from "../../graphql/queries.js";
import DashboardLoading from "../../assets/dashboardLoading.png";

export default function DashboardViewPage({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [iframeWait, setIframeWait] = useState(false);
  const [dashboardListResponse, setDashboardListResponse] = useState([]);
  const [embedUrl, setEmbedUrl] = useState("");
  const [dashboardLink, setDashboardLink] = useState("");
  const [dashboardID, setDashboardID] = useState("");
  const dashboardBasePath = "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards";

  // AWS Support Solution

  useEffect(() => {

    async function getDashboardList(){
      const response = await API.graphql({ query: readDashboardList });
      const data = response.data || {};
      if(data.readDashboardList) setDashboardListResponse(data.readDashboardList);
      setWait(false);
    }

    getDashboardList().catch(error => {
      setWait(false);
      console.error(error);
    });

  }, []);

  const dashboardList = dashboardListResponse.filter(listItem => {
    const _dashboardLink = listItem.dashboard_link || [];
    return  _dashboardLink.length;
  }).sort((a, b) => a.attribute_list_value_sort_order - b.attribute_list_value_sort_order);

  async function roleAssumption(d_id) {
    setWait(true);
    setIframeWait(true);
    setDashboardLink(d_id);
    // Get the Cognito user session
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const session = cognitoUser.getSignInUserSession();
    let user_data = {
      user_name: cognitoUser.username,
      user_email: cognitoUser.attributes.email,
      dashboard_id: d_id,
    };

    const getDashboardURL = await API.graphql({
      query: mutations.getDasboardEmbedURL,
      variables: {
        user_data: {
          user_name: cognitoUser.username,
          user_email: cognitoUser.attributes.email,
          dashboard_id: d_id,
        },
      },
    }).then((data) => {
      if (
        data.data.getDasboardEmbedURL !== undefined ||
        data.data.getDasboardEmbedURL !== ""
      )
        setEmbedUrl(data.data.getDasboardEmbedURL);
        setWait(false);
        setTimeout(() => {
          setIframeWait(false);
        }, 2000);
    });
  }

  return (
    <>
      {/* <Navbar user={user} signOut={signOut} />
      <div style={{ display: "flex", height: "90%" }}>
        <Sidebar /> */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={wait}
        >
          <CircularProgress sx={{ color: "#DB2748" }} />{" "}
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={iframeWait}
        >
          <CircularProgress sx={{ color: "#DB2748" }} />{" "}
        </Backdrop>

        <Container maxWidth={false} disableGutters sx={{ height: "100%", margin: "2px 2px 0 0", borderRadius: 1, boxShadow: theme => theme.shadows[2] }}>
            <div style={{ height: "7%" }}>
              {!wait && (
                <>
                  <div style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingLeft: "8px",
                      flexBasis: "15%"
                    }}>
                        <FormControl
                          name="fullWidth1"
                          fullWidth
                          size="small"
                        >
                          <InputLabel>Dashboard Select</InputLabel>
                          <Select label="Dashboard Select" value={dashboardID}>
                          {
                            dashboardList.map((dashboardItem) => {
                              const _category_name = dashboardItem.category_name || '';
                              const _category_display_name = dashboardItem.category_display_name || '';
                              const _dashboard_display_names = dashboardItem.dashboard_display_names || [];
                              const _dashboard_ids = dashboardItem.dashboard_ids || [];
                              const _dashboard_link = dashboardItem.dashboard_link || [];

                              const _menuItems = _dashboard_link.reduce((menuItems, dashboardLink, linkIndex) => {
                                menuItems.push(
                                  <MenuItem
                                    key={_dashboard_ids[linkIndex]}
                                    value={_dashboard_ids[linkIndex]}
                                    onClick={() => {
                                      setDashboardID(_dashboard_ids[linkIndex]);
                                      roleAssumption(dashboardLink).catch(error => console.error(error));
                                    }}
                                    sx={{ marginLeft: "15px", fontSize: "14px" }}
                                  >
                                    {_dashboard_display_names[linkIndex]}
                                  </MenuItem>
                                );

                                return menuItems;
                              }, []);

                              return [<ListSubheader key={_category_name} sx={{ fontSize: "16px" }}>{_category_display_name}</ListSubheader>].concat(_menuItems);
                            })
                          }
                          </Select>
                        </FormControl>
                    </div>

                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      flexBasis: "70%",
                      fontWeight: 700,
                      fontSize: "1.5rem"
                    }}>
                      DASHBOARD
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      justifyContent: "center",
                      paddingRight: "8px",
                      flexBasis: "15%"
                    }}>
                      {
                        dashboardLink ?
                          <a href={`${dashboardBasePath}/${dashboardLink}`} rel="noreferrer" target="_blank">
                            <OpenInNewIcon/> Open in new tab
                          </a>
                        : null
                      }
                    </div>
                  </div>
                </>
              )}
            </div>

          <Container maxWidth={false} disableGutters sx={{ height: "93%" }}>
            {
              embedUrl.length > 1 ?
                  <iframe
                      height="100%"
                      width="100%"
                      src={embedUrl}
                  ></iframe>
                  :
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                  }}>
                    <img src={DashboardLoading} alt="Dashboard"/>
                  </div>
            }
            </Container>
        </Container>
      {/* </div> */}
    </>
  );
}