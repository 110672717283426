/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onLLMResponseCreated = /* GraphQL */ `
  subscription OnLLMResponseCreated($session_id: String!, $timestamp: String!) {
    onLLMResponseCreated(session_id: $session_id, timestamp: $timestamp) {
      session_id
      timestamp
      llm_response
      __typename
    }
  }
`;
export const onLLMResponseSourceCreated = /* GraphQL */ `
  subscription OnLLMResponseSourceCreated(
    $session_id: String!
    $timestamp: String!
  ) {
    onLLMResponseSourceCreated(session_id: $session_id, timestamp: $timestamp) {
      session_id
      timestamp
      source_input {
        source_name
        file_url
        source_url
        source_publish_date
        __typename
      }
      __typename
    }
  }
`;
