import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { PiPlus } from "react-icons/pi";
import * as mutations from "../../src/graphql/mutations.js";
import { API } from "aws-amplify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

export default function PolicyRiders({
  filteredList,
  getPolicyRidersMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };

  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);
  const [masterJson, setMasterJson] = useState([]);
  const [wait, setWait] = useState(true);
  const [date, setDate] = useState("");
  const [value0, setValue0] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const [value5, setValue5] = useState("");
  const [value6, setValue6] = useState("");
  const [value7, setValue7] = useState("");
  const [value8, setValue8] = useState("");
  const [value9, setValue9] = useState("");
  const [value10, setValue10] = useState("");
  const [value11, setValue11] = useState("");
  const [value12, setValue12] = useState("");
  const [value13, setValue13] = useState("");
  const [value14, setValue14] = useState("");
  const [value15, setValue15] = useState("");
  const [value16, setValue16] = useState("");
  const [value17, setValue17] = useState("");
  const [value18, setValue18] = useState("");
  const [value19, setValue19] = useState("");
  const [value20, setValue20] = useState("");
  const [value21, setValue21] = useState("");
  const [value22, setValue22] = useState("");
  const [value23, setValue23] = useState("");
  const [value24, setValue24] = useState("");
  const [value25, setValue25] = useState("");
  const [value26, setValue26] = useState("");
  const [value27, setValue27] = useState("");
  const [value28, setValue28] = useState("");
  const [value29, setValue29] = useState("");
  const [value30, setValue30] = useState("");
  const [value31, setValue31] = useState("");
  const [value32, setValue32] = useState("");
  const [value33, setValue33] = useState("");
  const [value34, setValue34] = useState("");
  const [value35, setValue35] = useState("");
  const [value36, setValue36] = useState("");

  const getBenefitPlansAndPrograms = async (benefitProgram, benefitCounty) => {
    const filteredValues = await API.graphql({
      query: mutations.PolicyRidersfilterBenefitPlanAndCountry,
      variables: {
        benefit_id: benefitProgram,
        country: benefitCounty,
      },
    });
    setMasterJson(filteredValues.data.PolicyRidersfilterBenefitPlanAndCountry);
    setWait(false);
  };

  // const handleSave = () => {};

  useEffect(() => {
    const benefitProgram = JSON.parse(
      localStorage.getItem("benefitProgramFromBenefitPage1"),
    );
    const benefitCounty = JSON.parse(
      localStorage.getItem("contryFromBenefitPage1"),
    );

    getBenefitPlansAndPrograms(benefitProgram, benefitCounty);
  }, []);

  let test = [];
  for (let r of masterJson) {
    test.push(r.attribute_key_name_parent);
  }

  let uniqueArray = [];
  const test1 = new Map();
  for (const obj of test) {
    const objString = JSON.stringify(obj);
    if (!test1.has(objString)) {
      test1.set(objString, true);
      uniqueArray.push(obj);
    }
  }
  // console.log(uniqueArray);

  const handleValue0Change = (event) => {
    setValue0(event.target.value);
  };
  const handleValue1Change = (event) => {
    setValue1(event.target.value);
  };
  const handleValue2Change = (event) => {
    setValue2(event.target.value);
  };
  const handleValue3Change = (event) => {
    setValue3(event.target.value);
  };
  const handleValue4Change = (event) => {
    setValue4(event.target.value);
  };
  const handleValue5Change = (event) => {
    setValue5(event.target.value);
  };
  const handleValue6Change = (event) => {
    setValue6(event.target.value);
  };
  const handleValue7Change = (event) => {
    setValue7(event.target.value);
  };
  const handleValue8Change = (event) => {
    setValue8(event.target.value);
  };
  const handleValue9Change = (event) => {
    setValue9(event.target.value);
  };
  const handleValue10Change = (event) => {
    setValue10(event.target.value);
  };
  const handleValue11Change = (event) => {
    setValue11(event.target.value);
  };
  const handleValue12Change = (event) => {
    setValue12(event.target.value);
  };
  const handleValue13Change = (event) => {
    setValue13(event.target.value);
  };
  const handleValue14Change = (event) => {
    setValue14(event.target.value);
  };
  const handleValue15Change = (event) => {
    setValue15(event.target.value);
  };
  const handleValue16Change = (event) => {
    setValue16(event.target.value);
  };
  const handleValue17Change = (event) => {
    setValue17(event.target.value);
  };
  const handleValue18Change = (event) => {
    setValue18(event.target.value);
  };
  const handleValue19Change = (event) => {
    setValue19(event.target.value);
  };
  const handleValue20Change = (event) => {
    setValue20(event.target.value);
  };
  const handleValue21Change = (event) => {
    setValue21(event.target.value);
  };
  const handleValue22Change = (event) => {
    setValue22(event.target.value);
  };
  const handleValue23Change = (event) => {
    setValue23(event.target.value);
  };
  const handleValue24Change = (event) => {
    setValue24(event.target.value);
  };
  const handleValue25Change = (event) => {
    setValue25(event.target.value);
  };
  const handleValue26Change = (event) => {
    setValue26(event.target.value);
  };
  const handleValue27Change = (event) => {
    setValue27(event.target.value);
  };
  const handleValue28Change = (event) => {
    setValue28(event.target.value);
  };
  const handleValue29Change = (event) => {
    setValue29(event.target.value);
  };
  const handleValue30Change = (event) => {
    setValue30(event.target.value);
  };
  const handleValue31Change = (event) => {
    setValue31(event.target.value);
  };
  const handleValue32Change = (event) => {
    setValue32(event.target.value);
  };
  const handleValue33Change = (event) => {
    setValue33(event.target.value);
  };
  const handleValue34Change = (event) => {
    setValue34(event.target.value);
  };
  const handleValue35Change = (event) => {
    setValue35(event.target.value);
  };
  const handleValue36Change = (event) => {
    setValue36(event.target.value);
  };

  let lifeCoverageAmountSalaryMultiple = [];
  let addCoverAmountSalaryMultiple = [];
  let stdDurationMonthDurations = [];
  let stdDisabilityEliminationPeriod = [];
  let ltdDisabilityEliminationPeriod = [];

  if (uniqueArray.includes("life_coverage_amt_salary_multiple")) {
    for (let lcasm of masterJson) {
      if (
        lcasm.attribute_key_name_parent === "life_coverage_amt_salary_multiple"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = lcasm.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          lcasm.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = lcasm.attribute_list_value_sort_order_child;
        lifeCoverageAmountSalaryMultiple.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("add_coverage_amt_salary_multiple")) {
    for (let acasm of masterJson) {
      if (
        acasm.attribute_key_name_parent === "add_coverage_amt_salary_multiple"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = acasm.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          acasm.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = acasm.attribute_list_value_sort_order_child;
        addCoverAmountSalaryMultiple.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("std_disability_elimination_period")) {
    for (let sdep of masterJson) {
      if (
        sdep.attribute_key_name_parent === "std_disability_elimination_period"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = sdep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          sdep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = sdep.attribute_list_value_sort_order_child;
        stdDisabilityEliminationPeriod.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("std_duraton_month_durations")) {
    for (let sdmd of masterJson) {
      if (sdmd.attribute_key_name_parent === "std_duraton_month_durations") {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = sdmd.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          sdmd.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = sdmd.attribute_list_value_sort_order_child;
        stdDurationMonthDurations.push(dict1);
      }
    }
  }

  if (uniqueArray.includes("ltd_disability_elimination_period")) {
    for (let idep of masterJson) {
      if (
        idep.attribute_key_name_parent === "ltd_disability_elimination_period"
      ) {
        let dict1 = {
          listValueKey: "",
          listValueDisplayName: "",
          listValueSortOrder: "",
        };

        dict1.listValueKey = idep.attribute_list_valid_value_child;
        dict1.listValueDisplayName =
          idep.attribute_list_value_display_name_child;
        dict1.listValueSortOrder = idep.attribute_list_value_sort_order_child;
        ltdDisabilityEliminationPeriod.push(dict1);
      }
    }
  }

  // eligibilityRules.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);
  lifeCoverageAmountSalaryMultiple.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  addCoverAmountSalaryMultiple.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  stdDisabilityEliminationPeriod.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  stdDurationMonthDurations.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  ltdDisabilityEliminationPeriod.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );

  const duplicateFinalList = [
    ["add_coverage_amt", value0],
    ["add_coverage_amt_salary_multiple", value1],
    ["cii_coverage_amt", value2],
    ["dental_in_network_major_coinsurance", value3],
    ["dental_in_network_major_copay", value4],
    ["dental_in_network_minor_coinsurance", value5],
    ["dental_in_network_orthodontia_coinsurance", value6],
    ["dental_in_network_orthodontia_copay", value7],
    ["dental_in_network_preventative_coinsurance", value8],
    ["dental_in_network_preventative_copay", value9],
    ["dental_life_time_cap_orthodontia", value10],
    ["dental_maximum_allowable_benefit", value11],
    ["dental_out_of_network_major_coinsurance", value12],
    ["dental_out_of_network_major_copay", value13],
    ["dental_out_of_network_minor_coinsurance", value14],
    ["dental_out_of_network_minor_copay", value15],
    ["dental_out_of_network_orthodontia_coinsurance", value16],
    ["dental_out_of_network_orthodontia_copay", value17],
    ["dental_out_of_network_preventative_coinsurance", value18],
    ["dental_out_of_network_preventative_copay", value19],
    ["in_network_eye_exam_copay", value20],
    ["in_network_frame_contact_copay", value21],
    ["in_network_maximium_allowable_benefit", value22],
    ["life_coverage_amt", value23],
    ["life_coverage_amt_salary_multiple", value24],
    ["ltd_disability_elimination_period", value25],
    ["ltd_income_replacement", value26],
    ["ltd_payment_cap", value27],
    ["out_of_network_eye_exam_copay", value28],
    ["out_of_network_frame_contact_copay", value29],
    ["out_of_network_maximium_allowable_benefit", value30],
    ["pai_coverage_amt", value31],
    ["std_disability_elimination_period", value32],
    ["std_duraton_month_durations", value33],
    ["std_payment_cap", value34],
    ["vision_maximum_allowable_benefit", value35],
    ["dental_in_network_minor_copay", value36],
  ];

  let finalList = [];

  for (var i of duplicateFinalList) {
    if (i[1].length > 0) {
      finalList.push(i);
    }
  }

  let finalDict = {
    date: `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
      date.$D > 9 ? date.$D : `0${date.$D}`
    }`,
  };

  for (var er of finalList) {
    finalDict[er[0]] = er[1];
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getPolicyRidersMappedValues(
        finalDict,
        `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
          date.$D > 9 ? date.$D : `0${date.$D}`
        }`,
      );
    }
  };

  // console.log(finalList);

  return (
    <>
      {wait ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={wait}
        >
          <CircularProgress sx={{ color: "#DB2748" }} />{" "}
        </Backdrop>
      ) : (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Policy Riders</Typography>
          </AccordionSummary>

          <AccordionDetails className="mx-5 mb-3 flex justify-between">
            <div>
              {alert === "success" && alertStatus ? (
                <Alert onClose={() => setAlertStatus(false)} severity="success">
                  Success! The details provided have been stored
                </Alert>
              ) : alert === "error" && alertStatus ? (
                <Alert onClose={() => setAlertStatus(false)} severity="error">
                  Please, Fill all the fields
                </Alert>
              ) : (
                ""
              )}
            </div>
          </AccordionDetails>

          <AccordionDetails className="grid grid-cols-2 gap-8 mx-5 mb-4">
            <FormControl fullWidth>
              <DatePicker
                label="As Of Date"
                name="date"
                value={date}
                onChange={(date) => setDate(date)}
                format="YYYY-DD-MM"
              />
            </FormControl>

            <FormControl fullWidth></FormControl>

            {uniqueArray.includes("add_coverage_amt") && (
              <>
                {" "}
                <FormControl fullWidth>
                  <Tooltip title={"AD&D - Fixed Cover Amt"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"AD&D - Fixed Cover Amt"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR12348"
                    value={value0}
                    label={"Amount"}
                    onChange={handleValue0Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("add_coverage_amt_salary_multiple") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"AD&D Covered Multiple of Salary"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"AD&D Covered Multiple of Salary"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="PR1234142">{"Value"}</InputLabel>
                  <Select
                    labelId="PR1234154"
                    id="PR1234166"
                    value={value1}
                    label={"Value"}
                    onChange={handleValue1Change}
                  >
                    {addCoverAmountSalaryMultiple.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("cii_coverage_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Critical Illness Coverage"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Critical Illness Coverage"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123439"
                    value={value2}
                    label={"Amount"}
                    onChange={handleValue2Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_major_coinsurance") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance -In-Network Major Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance -In-Network Major Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR1234340"
                    value={value3}
                    label={"Percent"}
                    onChange={handleValue3Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_major_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Major Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Major Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123418"
                    value={value4}
                    label={"Amount"}
                    onChange={handleValue4Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_minor_coinsurance") && (
              <>
                {" "}
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Minor Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Minor Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123418"
                    value={value5}
                    label={"Percent"}
                    onChange={handleValue5Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_minor_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Minor Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Minor Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123470"
                    value={value36}
                    label={"Amount"}
                    onChange={handleValue36Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_in_network_orthodontia_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Orthodontia"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Orthodontia"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123418"
                    value={value6}
                    label={"Percent"}
                    onChange={handleValue6Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_orthodontia_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-network Orthodontia"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-network Orthodontia"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR1234181"
                    value={value7}
                    label={"Amount"}
                    onChange={handleValue7Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_in_network_preventative_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - In-Network Preventative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - In-Network Preventative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123432"
                    value={value8}
                    label={"Percent"}
                    onChange={handleValue8Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_in_network_preventative_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Preventative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Preventative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123433"
                    value={value9}
                    label={"Amount"}
                    onChange={handleValue9Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_life_time_cap_orthodontia") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Life-time Orthodontia Cap"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Life-time Orthodontia Cap"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123433"
                    value={value10}
                    label={"Amount"}
                    onChange={handleValue10Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_maximum_allowable_benefit") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Dental Max Benefit"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Dental Max Benefit"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123433"
                    value={value11}
                    label={"Amount"}
                    onChange={handleValue11Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_major_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Coinsurance - Out-of-Network Major Restorative"}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Out-of-Network Major Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123440"
                    value={value12}
                    label={"Percent"}
                    onChange={handleValue12Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_out_of_network_major_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Out-of-Network Major Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Out-of-Network Major Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123441"
                    value={value13}
                    label={"Amount"}
                    onChange={handleValue13Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_minor_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip
                    title={"Coinsurance: Out-of-Network Minor Restorative"}
                  >
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance: Out-of-Network Minor Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123442"
                    value={value14}
                    label={"Percent"}
                    onChange={handleValue14Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("dental_out_of_network_minor_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Out-of-Network Minor Restorative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Out-of-Network Minor Restorative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123442"
                    value={value15}
                    label={"Amount"}
                    onChange={handleValue15Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_orthodontia_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance - Out-of-Network Orthodontia"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance - Out-of-Network Orthodontia"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123442"
                    value={value16}
                    label={"Percent"}
                    onChange={handleValue16Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_orthodontia_copay",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-Pay: Out-of-Network Orthodontia"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-Pay: Out-of-Network Orthodontia"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123449"
                    value={value17}
                    label={"Amount"}
                    onChange={handleValue17Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_preventative_coinsurance",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Coinsurance: Out-of-Network Preventative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Coinsurance: Out-of-Network Preventative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123450"
                    value={value18}
                    label={"Percent"}
                    onChange={handleValue18Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "dental_out_of_network_preventative_copay",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay: Out-of-Network Preventative"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay: Out-of-Network Preventative"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123451"
                    value={value19}
                    label={"Amount"}
                    onChange={handleValue19Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_eye_exam_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-Network Eye-Exams"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-Network Eye-Exams"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123451"
                    value={value20}
                    label={"Amount"}
                    onChange={handleValue20Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_frame_contact_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - In-network Glasses/Contacts"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - In-network Glasses/Contacts"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123455"
                    value={value21}
                    label={"Amount"}
                    onChange={handleValue21Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("in_network_maximium_allowable_benefit") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Maximium Benefit - In-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Maximium Benefit - In-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123456"
                    value={value22}
                    label={"Amount"}
                    onChange={handleValue22Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("life_coverage_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Life Insurance - Fixed Cover Amt"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Life Insurance - Fixed Cover Amt"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123457"
                    value={value23}
                    label={"Number"}
                    onChange={handleValue23Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("life_coverage_amt_salary_multiple") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Life Insurance Covered Multiple of Salary"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Life Insurance Covered Multiple of Salary"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="PR1234178">{"Value"}</InputLabel>
                  <Select
                    labelId="PR1234190"
                    id="PR1234202"
                    value={value24}
                    label={"Value"}
                    onChange={handleValue24Change}
                  >
                    {lifeCoverageAmountSalaryMultiple.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("ltd_disability_elimination_period") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"LTD Waiting Period"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"LTD Waiting Period"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="PR1234178">{"Value"}</InputLabel>
                  <Select
                    labelId="PR1234190"
                    id="PR1234202"
                    value={value25}
                    label={"Value"}
                    onChange={handleValue25Change}
                  >
                    {ltdDisabilityEliminationPeriod.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("ltd_income_replacement") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"LTD Income Replacement Pct"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"LTD Income Replacement Pct"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123460"
                    value={value26}
                    label={"Percent"}
                    onChange={handleValue26Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("ltd_payment_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"LTD Max Payment Amt"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"LTD Max Payment Amt"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123460"
                    value={value27}
                    label={"Amount"}
                    onChange={handleValue27Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("out_of_network_eye_exam_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Co-pay - Out-of-Network Eye-Exam"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Co-pay - Out-of-Network Eye-Exam"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123464"
                    value={value28}
                    label={"Amount"}
                    onChange={handleValue28Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("out_of_network_frame_contact_copay") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Copay - Out-of-Network Glasses/Contacts"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Copay - Out-of-Network Glasses/Contacts"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123465"
                    value={value29}
                    label={"Amount"}
                    onChange={handleValue29Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes(
              "out_of_network_maximium_allowable_benefit",
            ) && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Maximium Benefit - Out-of-Network"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Maximium Benefit - Out-of-Network"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123466"
                    value={value30}
                    label={"Amount"}
                    onChange={handleValue30Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("pai_coverage_amt") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Personal Accident Coverage"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Personal Accident Coverage"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123467"
                    value={value31}
                    label={"Amount"}
                    onChange={handleValue31Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("std_disability_elimination_period") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"STD Waiting Period"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"STD Waiting Period"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="PR1234188">{"Number"}</InputLabel>
                  <Select
                    labelId="PR1234200"
                    id="PR1234212"
                    value={value32}
                    label={"Number"}
                    onChange={handleValue32Change}
                  >
                    {stdDisabilityEliminationPeriod.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("std_duraton_month_durations") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"STD Duration"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"STD Duration"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="PR1234188">{"Value"}</InputLabel>
                  <Select
                    labelId="PR1234200"
                    id="PR1234212"
                    value={value33}
                    label={"Value"}
                    onChange={handleValue33Change}
                  >
                    {stdDurationMonthDurations.map((item, key) => (
                      <MenuItem value={item.listValueKey} key={key}>
                        {item.listValueDisplayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {uniqueArray.includes("std_payment_cap") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Short-Term Disability payment cap amount"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Short-Term Disability payment cap amount"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123470"
                    value={value34}
                    label={"Amount"}
                    onChange={handleValue34Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            {uniqueArray.includes("vision_maximum_allowable_benefit") && (
              <>
                <FormControl fullWidth>
                  <Tooltip title={"Vision Max Benefit"}>
                    <TextField
                      label="Coverage Rule"
                      id="outlined-basic"
                      value={"Vision Max Benefit"}
                      variant="outlined"
                      inputProps={{ readOnly: true }}
                    />
                  </Tooltip>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="PR123470"
                    value={value35}
                    label={"Amount"}
                    onChange={handleValue35Change}
                    variant="outlined"
                  />
                </FormControl>
              </>
            )}

            <FormControl fullWidth></FormControl>
            <div className="mt-5 w-full flex justify-end items-center">
              <button
                type="submit"
                className="ml-5 bg-[#0000A3] py-[6px]   shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg    px-6  text-white"
                onClick={(e) => {
                  handleSave(e);
                }}
              >
                Save
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

          // <div className="pt-5">
          //   {" "}
          //   {finalList.map((item, key) => (
          //     <div key={key}>
          //       {item[0]} : {item[1]}
          //     </div>
          //   ))}
          // </div>
// let duplicateEligibilityRules = [];
// for (let rules of filteredList) {
//   if (rules.child_operator_flag === "child") {
//     let dict1 = {
//       listValueKey: "",
//       listValueDisplayName: "",
//       listValueSortOrder: "",
//       listValueRightDisplayValue: "",
//     };
//
//     dict1.listValueKey = rules.attribute_key_name_parent;
//     dict1.listValueDisplayName = rules.attribute_key_display_name_parent;
//     dict1.listValueSortOrder = rules.attribute_list_value_sort_order_parent;
//     dict1.listValueRightDisplayValue = rules.attribute_key_display_name_child;
//     duplicateEligibilityRules.push(dict1);
//   }
// }
//
// const uniqueMapCid = new Map();
// for (const obj of duplicateEligibilityRules) {
//   const objString = JSON.stringify(obj);
//   if (!uniqueMapCid.has(objString)) {
//     uniqueMapCid.set(objString, true);
//     eligibilityRules.push(obj);
//   }
// }
