import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { PiPlus } from "react-icons/pi";
import Alert from "@mui/material/Alert";


export default function MonthlyHealthSavingsAccountContributions({
  filteredList,
  getMonthlyHealthSavingsAccountContributionsMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };

  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);
  const [date, setDate] = useState("");
  const [value0, setValue0] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");

  const handleValue0Change = (event) => {
    setValue0(event.target.value);
  };
  const handleValue1Change = (event) => {
    setValue1(event.target.value);
  };
  const handleValue2Change = (event) => {
    setValue2(event.target.value);
  };
  const handleValue3Change = (event) => {
    setValue3(event.target.value);
  };
  const handleValue4Change = (event) => {
    setValue4(event.target.value);
  };

  let eligibilityRules = [];

  for (let rules of filteredList) {
    if (rules.child_operator_flag === "child") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
        listValueRightDisplayValue: "",
      };

      dict1.listValueKey = rules.attribute_key_name_parent;
      dict1.listValueDisplayName = rules.attribute_key_display_name_parent;
      dict1.listValueSortOrder = rules.attribute_list_value_sort_order_parent;
      dict1.listValueRightDisplayValue = rules.attribute_key_display_name_child;
      eligibilityRules.push(dict1);
    }
  }
  eligibilityRules.sort((a, b) => a.listValueSortOrder - b.listValueSortOrder);

  const finalList = [
    [eligibilityRules[0].listValueKey, value0],
    [eligibilityRules[1].listValueKey, value1],
    [eligibilityRules[2].listValueKey, value2],
    [eligibilityRules[3].listValueKey, value3],
    [eligibilityRules[4].listValueKey, value4],
  ];

  let finalDict = {"date": `${date.$y}-${(date.$M + 1) > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${(date.$D) > 9 ? date.$D : `0${date.$D}`}`,};

  for (var er of finalList) {
    finalDict[er[0]] = er[1];
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getMonthlyHealthSavingsAccountContributionsMappedValues(finalDict, `${date.$y}-${(date.$M + 1) > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${(date.$D) > 9 ? date.$D : `0${date.$D}`}`);
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Monthly Health Savings Account Contributions</Typography>
      </AccordionSummary>

      <AccordionDetails className="mx-5 mb-3 flex justify-between">
        <div>
          {alert === "success" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="success">
              Success! The details provided have been stored
            </Alert>
          ) : alert === "error" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="error">
              Please, Fill all the fields
            </Alert>
          ) : (
            ""
          )}
        </div>
      </AccordionDetails>

      <AccordionDetails className="grid grid-cols-2 gap-8 mx-5 mb-4">
        <FormControl fullWidth>
          <DatePicker
            label="As Of Date"
            name="date"
            value={date}
            onChange={(date) => setDate(date)}
            format="YYYY-DD-MM"
          />
        </FormControl>

        <FormControl fullWidth></FormControl>

        <FormControl fullWidth>
          <Tooltip title={eligibilityRules[4].listValueDisplayName}>
            <TextField
              label="HSA Tier"
              id="outlined-basic"
              value={eligibilityRules[4].listValueDisplayName}
              variant="outlined"
              inputProps={{ readOnly: true }}
            />
          </Tooltip>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="AnnualHealthReimbursementAccounts"
            value={value4}
            label={eligibilityRules[4].listValueRightDisplayValue}
            onChange={handleValue4Change}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth>
          <Tooltip title={eligibilityRules[0].listValueDisplayName}>
            <TextField
              label="HSA Tier"
              id="outlined-basic"
              value={eligibilityRules[0].listValueDisplayName}
              variant="outlined"
              inputProps={{ readOnly: true }}
            />
          </Tooltip>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="AnnualHealthReimbursementAccounts"
            value={value0}
            label={eligibilityRules[0].listValueRightDisplayValue}
            onChange={handleValue0Change}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth>
          <Tooltip title={eligibilityRules[1].listValueDisplayName}>
            <TextField
              label="HSA Tier"
              id="outlined-basic"
              value={eligibilityRules[1].listValueDisplayName}
              variant="outlined"
              inputProps={{ readOnly: true }}
            />
          </Tooltip>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="AnnualHealthReimbursementAccounts"
            value={value1}
            label={eligibilityRules[1].listValueRightDisplayValue}
            onChange={handleValue1Change}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth>
          <Tooltip title={eligibilityRules[3].listValueDisplayName}>
            <TextField
              label="HSA Tier"
              id="outlined-basic"
              value={eligibilityRules[3].listValueDisplayName}
              variant="outlined"
              inputProps={{ readOnly: true }}
            />
          </Tooltip>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="AnnualHealthReimbursementAccounts"
            value={value3}
            label={eligibilityRules[3].listValueRightDisplayValue}
            onChange={handleValue3Change}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth>
          <Tooltip title={eligibilityRules[2].listValueDisplayName}>
            <TextField
              label="HSA Tier"
              id="outlined-basic"
              value={eligibilityRules[2].listValueDisplayName}
              variant="outlined"
              inputProps={{ readOnly: true }}
            />
          </Tooltip>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="AnnualHealthReimbursementAccounts"
            value={value2}
            label={eligibilityRules[2].listValueRightDisplayValue}
            onChange={handleValue2Change}
            variant="outlined"
          />
        </FormControl>


        <FormControl fullWidth></FormControl>
        <div className="mt-5 w-full flex justify-end items-center">
          <button
            type="submit"
            className="ml-5 bg-[#0000A3] py-[6px]   shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg    px-6  text-white"
            onClick={(e) => {
              handleSave(e);
            }}
          >
            Save
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

      // <div className="pt-5">
      //   {" "}
      //   {JSON.stringify(
      //     [
      //       [date.$y, date.$M + 1, date.$D],
      //       [eligibilityRules[0].listValueDisplayName, value0],
      //       [eligibilityRules[1].listValueDisplayName, value1],
      //       [eligibilityRules[2].listValueDisplayName, value2],
      //       [eligibilityRules[3].listValueDisplayName, value3],
      //       [eligibilityRules[4].listValueDisplayName, value4],
      //     ],
      //     null,
      //     "    "
      //   )}
      // </div>
