import { React, useState, useEffect } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { PiPlus } from "react-icons/pi";
import Alert from "@mui/material/Alert";

export default function InsuredBenefitPlanOverview({
  filteredList,
  getInsuredBenefitPlanOverviewMappedValues,
}) {
  let arrowStyles = { fontSize: "20px" };
  let plusStyles = { fontSize: "20px" };

  const [date, setDate] = useState("");
  const [alert, setAlert] = useState("");
  const [alertStatus, setAlertStatus] = useState(true);
  const [vendorName, setVendorName] = useState("");
  const [invoiceFrequency, setInvoiceFrequency] = useState("");
  const [captiveNetwork, setCaptiveNetwork] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [eligibleMembers, setEligibleMembers] = useState([]);
  const [brokerOrConsultantName, setBrokerOrConsultantName] = useState("");
  const finalList = {
    insuredBenefitPlanOverview: {
      date: `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
        date.$D > 9 ? date.$D : `0${date.$D}`
      }`,
      vendor_name: vendorName,
      invoice_freqyency: invoiceFrequency,
      captive_network: captiveNetwork,
      renewal_date: `${renewalDate.$y}-${
        renewalDate.$M + 1 > 9 ? renewalDate.$M + 1 : `0${renewalDate.$M + 1}`
      }-${renewalDate.$D > 9 ? renewalDate.$D : `0${renewalDate.$D}`}`,
      eligible_members: eligibleMembers.join("+"),
      broker_or_consultant_name: brokerOrConsultantName,
    },
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (date.$y === undefined || date.$D === undefined) {
      setAlert("error");
      setAlertStatus(true);
    } else {
      setAlertStatus(true);
      setAlert("success");
      getInsuredBenefitPlanOverviewMappedValues(
        finalList,
        `${date.$y}-${date.$M + 1 > 9 ? date.$M + 1 : `0${date.$M + 1}`}-${
          date.$D > 9 ? date.$D : `0${date.$D}`
        }`,
      );
    }
  };

  const handleVendorNameChange = (event) => {
    setVendorName(event.target.value);
  };
  const handleInvoiceFrequencyChange = (event) => {
    setInvoiceFrequency(event.target.value);
  };
  const handleCaptiveNetworkChange = (event) => {
    setCaptiveNetwork(event.target.value);
  };
  const handleEligibleMembersChange = (event) => {
    const {
      target: { value },
    } = event;
    setEligibleMembers(typeof value === "string" ? value.split(",") : value);
  };
  const handleBrokerOrConsultantNameChange = (event) => {
    setBrokerOrConsultantName(event.target.value);
  };

  let invoiceFrequencyList = [];
  let captiveNetworkList = [];
  let eligiblemembersList = [];

  for (let item1 of filteredList) {
    if (item1.attribute_key_name_parent === "invoice_frequency") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = item1.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        item1.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = item1.attribute_list_value_sort_order_child;
      invoiceFrequencyList.push(dict1);
    }
  }

  for (let item2 of filteredList) {
    if (item2.attribute_key_name_parent === "captive_network") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = item2.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        item2.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = item2.attribute_list_value_sort_order_child;

      captiveNetworkList.push(dict1);
    }
  }

  for (let item3 of filteredList) {
    if (item3.attribute_key_name_parent === "target_record_type") {
      let dict1 = {
        listValueKey: "",
        listValueDisplayName: "",
        listValueSortOrder: "",
      };
      dict1.listValueKey = item3.attribute_list_valid_value_child;
      dict1.listValueDisplayName =
        item3.attribute_list_value_display_name_child;
      dict1.listValueSortOrder = item3.attribute_list_value_sort_order_child;

      eligiblemembersList.push(dict1);
    }
  }

  invoiceFrequencyList.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  captiveNetworkList.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );
  eligiblemembersList.sort(
    (a, b) => a.listValueSortOrder - b.listValueSortOrder,
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Benefit Plan Overview</Typography>
      </AccordionSummary>

      <AccordionDetails className="mx-5 mb-3 flex justify-between">
        <div>
          {alert === "success" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="success">
              Success! The details provided have been stored
            </Alert>
          ) : alert === "error" && alertStatus ? (
            <Alert onClose={() => setAlertStatus(false)} severity="error">
              Please, Fill all the fields
            </Alert>
          ) : (
            ""
          )}
        </div>
      </AccordionDetails>

      <AccordionDetails className="grid grid-cols-2 gap-8 mx-5 mb-4">
        <FormControl fullWidth>
          <DatePicker
            label="As Of Date"
            name="date"
            value={date}
            onChange={(date) => setDate(date)}
            format="YYYY-DD-MM"
          />
        </FormControl>

        <FormControl fullWidth></FormControl>

        <FormControl fullWidth>
          <TextField
            id="InsuredBenefitsVendorName"
            value={vendorName}
            label="Vendor Name"
            onChange={handleVendorNameChange}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="InsuredBenefitsInvoiceFrequency2">
            Invoice Frequency
          </InputLabel>
          <Select
            labelId="InsuredBenefitsInvoiceFrequency3"
            id="InsuredBenefitsInvoiceFrequency4"
            value={invoiceFrequency}
            label="Invoice Frequency"
            onChange={handleInvoiceFrequencyChange}
          >
            {invoiceFrequencyList.map((item, key) => (
              <MenuItem value={item.listValueKey} key={key}>
                {item.listValueDisplayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="InsuredBenefitsCPNetwork2">
            Captive/Pooling Network
          </InputLabel>
          <Select
            labelId="InsuredBenefitsCPNetwork3"
            id="InsuredBenefitsCPNetwork4"
            value={captiveNetwork}
            label="Captive/Pooling Network"
            onChange={handleCaptiveNetworkChange}
          >
            {captiveNetworkList.map((item, key) => (
              <MenuItem value={item.listValueKey} key={key}>
                {item.listValueDisplayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <DatePicker
            label="Renewal Date"
            name="date1"
            value={renewalDate}
            onChange={(renewalDate) => setRenewalDate(renewalDate)}
            format="YYYY-DD-MM"
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="InsuredBenefitsEligibleMembers2">
            Eligible Members
          </InputLabel>
          <Select
            labelId="InsuredBenefitsEligibleMembers3"
            id="InsuredBenefitsEligibleMembers4"
            value={eligibleMembers}
            multiple
            label="Eligible Members"
            onChange={handleEligibleMembersChange}
          >
            {eligiblemembersList.map((item, key) => (
              <MenuItem value={item.listValueKey} key={key}>
                {item.listValueDisplayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <TextField
            id="InsuredBenefitsBOrCName"
            value={brokerOrConsultantName}
            label="Broker/Consultant Name"
            onChange={handleBrokerOrConsultantNameChange}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth></FormControl>
        <div className=" mt-4 w-full flex justify-end items-center">
          <button
            type="submit"
            className="ml-5 bg-[#0000a3] py-[6px] shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg px-6  text-white"
            onClick={(e) => {
              handleSave(e);
            }}
          >
            Save
          </button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
 // shadow-black shadow-[0_1px_3px_rgb(220,220,220)] rounded-lg 

      // <div className="pt-5">
      //   {" "}
      //   {JSON.stringify(
      //     [
      //       [date.$y, date.$M + 1, date.$D],
      //       vendorName,
      //       invoiceFrequency,
      //       captiveNetwork,
      //       [renewalDate.$y, renewalDate.$M + 1, renewalDate.$D],
      //       eligibleMembers,
      //       brokerOrConsultantName,
      //     ],
      //     null,
      //     "    ",
      //   )}
      // </div>

// import { Link, useNavigate } from "react-router-dom";
// import Navbar from "../../components/Navbar.jsx";
// import Sidebar from "../../components/Sidebar.jsx";
// import { API } from "aws-amplify";
// import * as queries from "../../graphql/queries.js";
// import Alerts from "../../components/Alerts.jsx";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
//
